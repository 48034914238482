export default ({ Factory }) => ({

  getIFCGridsByProjectUuid: (projectUuid) => Factory.grids[projectUuid].get(),

  //getIFCGridsByModelRevisionUuid: (revisionUuid) => Factory.grids.revision[revisionUuid].get(),
  getIFCTreeByProjectUuid: (projectUuid) => Factory.grids.models[projectUuid].get(),

  changeIFCGridsVisibility: (revisionUuid) => Factory.grids.button[revisionUuid].patch(),

  getLabelSize: (projectUuid) => Factory.grids.labelSize[projectUuid].get()

})