import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { AlertService } from '@/assets/app/AlertService'
import i18n from '@/plugins/i18n'
import { FacePin } from '../../distanceMeasurement/hoveringPins/facePin'

const MOUSE_CANVAS_CLICK_TOLERANCE = 5
const ORIGIN_FACE_COLOR = [1.0, 0.35, 0.35]
const TARGET_FACE_COLOR = [1.0, 0.65, 0.65]

/*eslint-disable no-dupe-class-members*/
export class PlaneToPlaneRegime {
  static #originPickedFaceEdges = [] // Массив отрисованных ребер origin грани

  static #targetPickedFacePin = null
  static #originPickedFacePin = null

  static #facePin = null

  static #mouseHoverListenerId = null
  static #mouseUpListenerId = null
  static #mouseDownListenerId = null
  static #keyUpListenerId = null

  static #mouseDownCanvasX
  static #mouseDownCanvasY
  static #mouseDownLeft = false
  static #mouseDownRight = false

  /**
   * Включить режим измерения между плоскостями.
   */
  static activate() {
    this.#activateInputListeners()
    this.#activateHoverListener()
  }
  
  /**
   * Отключить режим измерения между плоскостями.
   */
  static deactivate() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    const input = XeokitMediator.viewer.scene.input

    cameraControl.off(this.#mouseHoverListenerId)
    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)
    input.off(this.#keyUpListenerId)
    
    this.#originPickedFaceEdges = []

    this.#facePin?.destroy()
    this.#facePin = null

    this.#originPickedFacePin?.destroy()
    this.#originPickedFacePin = null

    this.#targetPickedFacePin?.destroy()
    this.#targetPickedFacePin = null
  }

  static #pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedFaceTriangles = pickResult.trianglesPositions
    const pickedFaceEdges = pickResult.faceEdges

    if (pickResult.isSectionControl) return
    if (pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')) return

    if (this.#originPickedFaceEdges.length == 0) {
      this.#originPickedFaceEdges = pickedFaceEdges

      this.#originPickedFacePin = new FacePin( XeokitMediator.viewer.scene, {
        trianglesPositions: pickedFaceTriangles,
        color: ORIGIN_FACE_COLOR,
        edges: true
      })
    } 
    else {
      this.#targetPickedFacePin?.destroy()
      this.#targetPickedFacePin = new FacePin( XeokitMediator.viewer.scene, {
        trianglesPositions: pickedFaceTriangles,
        color: TARGET_FACE_COLOR,
        edges: true
      })

      const shortestSegment = geometry.planeToPlane.findPerpendicularSegmentBetweenTwoPlanes(this.#originPickedFaceEdges, pickedFaceEdges)
      XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
        originWorldPos: shortestSegment[0],
        targetWorldPos: shortestSegment[1],
        billboard: geometry.utils.isPointsEqual(shortestSegment[0], shortestSegment[1]) ? 'spherical' : 'none'
      })
    }
  }

  static #activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)

    this.#mouseDownListenerId = input.on('mousedown', (coords) => {
      this.#mouseDownCanvasX = coords[0]
      this.#mouseDownCanvasY = coords[1]
      this.#mouseDownLeft = input.mouseDownLeft
      this.#mouseDownRight = input.mouseDownRight
    })

    this.#mouseUpListenerId = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.#mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.#mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.#mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.#mouseDownLeft = false
        this.#mouseDownRight = false
        return
      }

      if (this.#mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
          needPickedFace: true,
        })

        if (pickResult) {
          if (pickResult.entity.model.isCollisionIntersection) {
            AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
            return
          }
          this.#pick(pickResult)
        }
      }

      this.#mouseDownLeft = false
      this.#mouseDownRight = false
    })

    this.#keyUpListenerId = input.on("keyup", (code) => {
      if (code != 78) return

      this.deactivate()
      this.activate()
    })
  }

  static #activateHoverListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl

    this.#mouseHoverListenerId = cameraControl.on('hover', (event) => {
      if (this.#mouseDownLeft || this.#mouseDownRight) return

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
        needPickedFace: true,
      })

      if (!pickResult) return
      if (pickResult.isSectionControl) return
      if (pickResult.entity.model.isCollisionIntersection) {
        AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
        cameraControl.off(this.#mouseHoverListenerId)
        return
      }
      if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) return

      const trianglesPositions = pickResult.trianglesPositions

      this.#facePin?.destroy()
      this.#facePin = new FacePin( XeokitMediator.viewer.scene, {
        trianglesPositions: trianglesPositions,
        edges: false
      })
    })
  }
}
