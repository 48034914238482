import { defineStore } from 'pinia'
import { api } from '@/api'
import { projectService } from '@/_services'

import { useSelectedElementsStore } from '@/pinia'
import { useViewerColorsStore, ColorCodingType } from '@/pinia'
import { useViewerToolsStore, ViewerTool } from '@/pinia'
import vuexStore from '@/store'
import { AxisUtils } from '@/assets/model/axis'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { useSearchElementsStore } from '@/pinia/searchElements.module'

import { CustomAxis, ProjectAxisForm } from '@models/axis'

export const useViewerGroupsStore = defineStore('viewer.groups', {
  state: () => {
    return {
      allGroups: [],
      visible: [],
      selectedGroupUuid: null,
      groupElements: {},
      isRestOfModelVisible: true,
      ifcGlobalAttrId: '',
    }
  },

  getters: {
    hasUngroupedElements: () => useSelectedElementsStore().selectedElements.length > 0,
    isAllVisible (state) {
      return state.allGroups.length == this.visible.length
    },
    colorTable () {
      let table = {}
      let colorStore = useViewerColorsStore()
      colorStore.all.forEach(cc => {
        if (cc.type.value == ColorCodingType.GROUPS) {
          let color = colorStore.selected.includes(cc.uuid) ? cc.color : 'white'
          cc.items.forEach(uuid => {
            table[uuid] = color
          })
        }
      })
      return table
    },
  },

  actions: {

    showAllObjects () {
      useSearchElementsStore().hideElementsSelection() // Убирает галочки в таблице поиска элементов
      XeokitMediator.ElementsSelection.dropAll()

      vuexStore.dispatch('axis/tree/deselectAll')
      vuexStore.dispatch('axis/tree/fetchElementByGlobalId', null)
      
      vuexStore.dispatch('collision/table/selectCollisions', [])
      vuexStore.dispatch('collision/search/deselectCollision')
      
      XeokitMediator.destroyUnnecessaryModels()
      
      let topUnit = vuexStore.state.project.topUnit
      if (topUnit == 'elementTree') {
        vuexStore.dispatch('axis/tree/deselectAllTreeEndpoints')
      }
    },

    setRestOfModelVisibility (value) {
      if (this.isRestOfModelVisible == value) return

      if (value) this.showRestOfModel()
      else this.hideRestOfModel()
    },

    showRestOfModel() {
      this.isRestOfModelVisible = true
      this.updateVisible(this.visible)
    },
    
    hideRestOfModel() {
      this.isRestOfModelVisible = false
      this.updateVisible(this.visible)
    },


    
    resetAllElements () {
      // Перенести сюда все поведение из тулбара
      // Добавить сброс подсветки выбранной группы
    },

    showAll () {
      let uuids = this.allGroups.map(item => item.uuid)
      this.updateVisible(uuids)
    },
    
    hideAll () {
      let uuids = []
      this.updateVisible(uuids)
    },

    updateVisible(uuids) {
      this.visible = uuids

      // console.log('🆑 scene', XeokitMediator.viewer.scene.objectIds)

      let allItems = XeokitMediator.viewer.scene.objectIds
      XeokitMediator.ElementsSettings.setElementsVisible(allItems, this.isRestOfModelVisible)

      for (const group of this.allGroups) {
        if (this.visible.includes(group.uuid) == false) {
          let items = this.groupElements[group.uuid] || []
          XeokitMediator.ElementsSettings.setElementsVisible(items, false)
        }
      }

      for (const uuid of this.visible) {
        let items = this.groupElements[uuid] || []
        XeokitMediator.ElementsSettings.setElementsVisible(items, true)
      }
    },

    async prepare () {
      vuexStore.dispatch('project/loadConditionEnum')

      let projectUuid = vuexStore.getters['project/projectUuid']
      let hashProject = vuexStore.getters['project/hashProject']
      let data = await projectService.loadParamForCondition(projectUuid, 'IDPROP', '', hashProject)
      console.log('🆑 IDPROP', data);

      for (let indexOf = 0; indexOf < data.length; indexOf++) {
        const element = data[indexOf];
        if (element.attr === 'ifcGlobalId') {
          this.ifcGlobalAttrId = element.uuid
          break
        }
      }

      this.allGroups = await this.fetchGroups()
      this.visible = this.allGroups.map(group => group.uuid)
      this.allGroups.forEach(group => {
        this.fetchGroupElements(group.uuid)
      })
    },
    
    async refetchGroups () {
      this.allGroups = await this.fetchGroups()
      this.allGroups.forEach(group => {
        this.fetchGroupElements(group.uuid)
      })
    },

    async fetchGroups () {
      let projectUuid = vuexStore.getters['project/projectUuid']
      let hashProject = vuexStore.getters['project/hashProject']
      let groups = await api.axes.fetchCustomAxisGroup(projectUuid, hashProject)
      groups.sort((a, b) => a.title.localeCompare(b.title))
      // return groups.map(g => makeItem(g.uuid, g.title))
      return groups
    },
    
    async fetchGroupElements (groupUuid) {
      let projectUuid = vuexStore.getters['project/projectUuid']
      let hashProject = vuexStore.getters['project/hashProject']
      let tree = await api.axes.fetchElementTree(projectUuid, groupUuid, true, hashProject)
      let onlyEndpoints = tree.reduce(AxisUtils.onlyEndpointsReducer, [])
      this.groupElements[groupUuid] = onlyEndpoints.map(item => item.uuid)
    },

    toggleSelection (group) {
      if (this.selectedGroupUuid != group.uuid) {
        this.selectGroup(group)  
      }
      else {
        this.deselectGroup(group)  
      }
    },

    selectGroup (group) {
      if (group) {
        if (this.visible.includes(group.uuid) == false) {
          this.visible.push(group.uuid)
          this.updateVisible(this.visible)
        }

        this.selectedGroupUuid = group.uuid
        let selectElementUuids = this.groupElements[group.uuid] || []
        XeokitMediator.ElementsSelection.selectElements(selectElementUuids)
      }
      else {
        this.selectedGroupUuid = null
      }
    },

    deselectGroup (group) {
      this.selectedGroupUuid = null
      
      if (group) {
        let selectElementUuids = this.groupElements[group.uuid] || []
        XeokitMediator.ElementsSelection.unselectElements(selectElementUuids)
      }
    },

    createGroup () {
      let uuids = Array.from(useSelectedElementsStore().selectedElements)

      if (!uuids?.length) {
        return Promise.resolve(null)
      }

      let projectUuid = vuexStore.getters['project/projectUuid']
      return api.axes.fetchIfcGUIDs(projectUuid, uuids).then(data => {
        console.log('🆑 Result ', data);

        let axisData = new ProjectAxisForm(null)
        let itemValue = data.join(',')

        this.fillCopyData(axisData, itemValue)

        return axisData
      })
    },

    fillCopyData(data, itemValue) {
      let cond = data.logicGroup.condition[0]

      console.log('🆑 Сделать название короче')
      cond.title = `IFC guids in [${itemValue}]`
      cond.value = itemValue

      let conditionOperator = vuexStore.getters['project/conditionOperator']
      let axisEnum = vuexStore.getters['project/axisEnum']

      cond.operator = conditionOperator.find(e => e.name == 'IN')
      cond.operand = axisEnum.find(e => e.name == 'IDPROP')
      cond.operandUuid = this.ifcGlobalAttrId
    },

    // MARK: - контекстное меню

    editGroup (group) {
      console.log('🆑 Получение данных группы', group)
      console.log('🆑 Открытие окна редактирования AxisGroupingDialog')
    },

    resaveGroup (group) {
      let uuids = Array.from(useSelectedElementsStore().selectedElements)

      let projectUuid = vuexStore.getters['project/projectUuid']
      return api.axes.fetchIfcGUIDs(projectUuid, uuids).then(data => {
        let axisData = new ProjectAxisForm(null)
        let itemValue = data.join(',')

        this.fillCopyData(axisData, itemValue)

        axisData.title = group.title
        axisData.uuid = group.uuid
        axisData.axis = group.axis
        axisData.color = group.color
        axisData.colorize = group.colorize
        axisData.profile = group.profile
        axisData.project = group.project
        axisData.shared = group.shared
        axisData.showchild = group.showchild

        return axisData
      })
    },

    fitGroupToScreen (group) {
      let selectElementUuids = this.groupElements[group.uuid] || []
      XeokitMediator.CameraFlight.flyToElements(selectElementUuids)
    },

    goToGroupTree (group) {
      useViewerToolsStore().activeTool = ViewerTool.NONE

      let axisGroup = CustomAxis(group)
      let topUnit = vuexStore.state.project.topUnit

      if (topUnit == 'elementTree') {
        let axisTab = vuexStore.getters['axis/fixedAxisGroup'][4]
        vuexStore.dispatch('axis/selectAxis', axisTab)
        vuexStore.dispatch('axis/selectAxisGroup', axisGroup)
      }
      else {
        vuexStore.commit('axis/setPresetAxisGroup', axisGroup)
        vuexStore.commit('project/setTopUnit', 'elementTree')
      }
    },

    removeGroup (group) {
      api.axes.delete(group.uuid).then(() => {
        this.allGroups = this.allGroups.filter(({ uuid }) => uuid !== group.uuid)
        console.log('🆑 Обновление видимости элементов, с учетом удаленной группы')
        this.updateVisible(this.visible)
      })
    },
  }
})

// function makeItem (uuid, title) {
//   return { uuid, title }
// }