import { $_app_server, $_app_server_noAuth, $_notification_server, $_notification_server_noAuth } from '@/_helpers'
import { SourcePath } from '@/assets/app/SourcePath'
import { App } from '@app/App'
//import { api, RequestConfig } from '@/utils'


let cloudPrefix = '/cloud'

export const projectService = {
  listProjects,
  myProjects,
  loadProject,
  loadProjectCloud,

  addProject,
  editProject,
  delProject,

  uploadRfiles,
  
  revisionsByModel,
  addRevision,
  regeometryRevision,
  generateConvexHull,
  addRevisionAnnotation,
  removeRevisionAnnotation,
  downloadRevisionIFC,
  fixRevision,
  unfixRevision,
  deleteRevision,
  getSuspectElements,

  allProcesses,
  allProcessesWithRequiredUUIDs,
  getProcess,
  getProcessByCloudProject,

  
  getNotifications,

  readNotificationsWithUUIDs,
  readNotificationsWithType,
  
  readNotification,
  readAllNotificationWithProject,
  readAllNotification,
  
  deleteNotification,
  deleteAllNotificationsWithProject,
  deleteAllNotifications,

  stopActiveProcess,
  //applyNotificationsWithUUIDs,

  addModel,
  updateModel,
  deleteModel,
  archiveModel,
  fromArchiveModel,
  reorderModels,

  resetModelOffset,
  editModelOffset,
  lockOffsetModel,
  unlockOffsetModel,

  updateWorkspace,
  updateSettings,
  updateModelSwitch,
  updateModelRevision,
  updateTaskColumns,
  updateTaskSections,
  updateProjectStructure,
  updateArchivedProjectStructure,
  updateLightSettings,
  updateMaintenanceTaskSettings,
  getMaintenanceTaskSettings,

  stageDict,
  qualityDict,
  accessibleDict,

  setStage,
  setQualtiy,
  setAccessible,
  setRoot,

  searchProfile,
  allColleagueProfiles,
  profilesByModel,
  profilesByProject,

  addMember,
  updateTeammateRoles,
  removeTeammate,

  loadElementTree,
  loadElementGroup,
  loadElementByGlobal,

  loadGroupAxis,
  loadProjectAxis, 
  saveAxis,
  myAttr,
  exportToXls,
  getBuildingForRevision,
  getFloorForRevision,

  saveCondition,
  loadConditionOperator,
  loadCalcOperator,
  loadLogicOperator,
  loadParamForCondition,
  loadAttrForClass, 
  loadBClassEnumElements, 

  makeFloor,

  setOpenedRoles,

  addPointCloud,
  getElementsByFilter,
  getElementsCount,
  getElementsTypes,

  silenceReGeometry,
  
  updateBimAnnotationsVisibleMode,

  getDrawingOnModelListByUuids,
  getDrawingsOnModelShortByProject,
  saveDrawingOnModel,
  deleteDrawingOnModel,
  
  downloadAllRevisions,

  checkUniqAlias,
}

function listProjects (filter) {
  return $_app_server.post('/projects/list', filter).then(response => response.data)
}

function myProjects (filter) {
  return $_app_server.get(`/projects/my/page/${filter.page}/perpage/${filter.perPage}`).then(response => response.data)
}

function loadProject (projectuid) {
  return $_app_server.get(`/projects/${projectuid}`).then(response => response.data)
}

function loadProjectCloud (projectuid, hashProject) {
  return $_app_server_noAuth.get(cloudPrefix + `/projects/${projectuid}/${hashProject}`).then(response => response.data)
}

function addProject (project) {
  return $_app_server.post('/projects/add', project).then(response => response.data)
}

function editProject (project) {
  return $_app_server.put('/projects/edit', project).then(response => response.data)
}



function delProject (projectuid) {
  return $_app_server.delete(`/projects/${projectuid}`).then(response => response.data)
}

function uploadRfiles (formData) {
  return $_app_server.post('rfiles', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => response.data)
}

function addModel (obj) {
  return $_app_server.post('/projects/model', obj).then(response => response.data)
}

function updateModel (modelUUID, obj) {
  return $_app_server.patch(`/projects/model/${modelUUID}`, obj).then(response => response.data)
}

function deleteModel (modelUuid) {
  return $_app_server.delete(`/projects/model/${modelUuid}`).then(response => response.data)
}

function archiveModel (modelUuid) {
  return $_app_server.patch(`/projects/model/${modelUuid}/toarchive`).then(response => response.data)
}

function fromArchiveModel (modelUuid) {
  return $_app_server.patch(`/projects/model/${modelUuid}/fromarchive`).then(response => response.data)
}

function reorderModels (param) {
  //return $_app_server.post('/projects/model/order', ids).then(response => response.data)
  return $_app_server.patch(`/projects/model/${param.id}/order/${param.order}/parent/${param.parent}`).then(response => response.data)
}

function resetModelOffset (modelUuid) {
  return $_app_server.patch(`/projects/model/${modelUuid}/offset/reset`).then(response => response.data)
}

function editModelOffset (modelUuid, modelPosition) {
  return $_app_server.put(`/projects/model/${modelUuid}/offset`, modelPosition).then(response => response.data)
}

function lockOffsetModel (modelUuid) {
  return $_app_server.patch(`/projects/model/${modelUuid}/lockoffset`).then(response => response.data)
}

function unlockOffsetModel (modelUuid) {
  return $_app_server.patch(`/projects/model/${modelUuid}/unlockoffset`).then(response => response.data)
}

function revisionsByModel (modelUuid) {
  return $_app_server.get(`/projects/model/${modelUuid}/revisions`).then(response => response.data)
}

function addRevision (obj, onUploadProgress) {
  let formData = new FormData();
  formData.append("modelUuid", obj.modelUuid)
  formData.append("annotation", obj.annotation)
  formData.append("offsetX", obj.offsetX)
  formData.append("offsetY", obj.offsetY) 
  formData.append("offsetZ", obj.offsetZ)
  formData.append("eulerX", obj.eulerX)
  formData.append("eulerY", obj.eulerY) 
  formData.append("eulerZ", obj.eulerZ)
  formData.append("file", obj.file)
  formData.append("link", obj.link)
  formData.append("expparser", obj.expparser)

  // return $_app_server.post('/projects/addrevision', formData, { 
  //   headers: {'Content-Type': 'multipart/form-data '}, 
  //   onUploadProgress 
  // }).then(response => response.data)

  return $_app_server.post('/revisions', formData, { 
    headers: {'Content-Type': 'multipart/form-data '}, 
    onUploadProgress 
  }).then(response => response.data)
}

function regeometryRevision(revisionUuid, expparser = false){
  return $_app_server.patch(`/revisions/${revisionUuid}/regeometry/${expparser}`).then(response => response.data)
}

function generateConvexHull(revisionUuid) {
  return $_app_server.patch(`/revisions/${revisionUuid}/convexhull`).then(response => response.data)
}

function addRevisionAnnotation (revisionUID, annotation) {
  if (!annotation) {
    return removeRevisionAnnotation(revisionUID)
  }
  return $_app_server.patch(`/revisions/${revisionUID}/annotation`, annotation, {responseType: 'text', 'headers': {'Content-Type': 'text/plain'}}).then(response => response.data)
}

function removeRevisionAnnotation(revisionUuid) {
  return $_app_server.patch(`/revisions/${revisionUuid}/clear-annotation`).then(response => response.data)
}

function downloadRevisionIFC (revisionUID) {
  return $_app_server.get(`/revisions/${revisionUID}/download`).then(response => response.data)
}

function fixRevision (revisionUuid) {
  return $_app_server.patch(`revisions/${revisionUuid}/fix`).then(response => response.data)
}

function unfixRevision (revisionUuid) {
  return $_app_server.patch(`revisions/${revisionUuid}/unfix`).then(response => response.data)
}

function deleteRevision (revisionUuid) {
  return $_app_server.delete(`revisions/${revisionUuid}`).then(response => response.data)
}

function getSuspectElements(projectUuid, revisionsUuid) {
  return $_app_server.post(`revisions/suspects/${projectUuid}`, revisionsUuid).then(response => response.data)
}

// Processes

function allProcesses () {
  return $_notification_server.get('/process/', null, { withCredentials: true }).then(response => response.data)
}

function allProcessesWithRequiredUUIDs (UUIDs, filter = null) {
  UUIDs 
  filter
  // if (filter) {
  //   return $_notification_server.post(`/process/?notificationLimit=${filter.limit}&notificationPage=${filter.page}`, UUIDs, { withCredentials: true })
  //   .then(response => response.data)
  //   .catch(err => {
  //     throw err;
  //   }); 
  // } else {
  //   return $_notification_server.post('/process/', UUIDs, { withCredentials: true }).then(response => response.data).catch(err => {
  //     throw err;
  //   }); 
  // }
}

function getProcess (processUid) {
  return $_notification_server.get(`/process/${processUid}`).then(response => response.data).catch(err => {
    throw err;
  }); 
}

function getProcessByCloudProject (projectUid) {
  return $_notification_server_noAuth.get(cloudPrefix + `/process/${projectUid}`).then(response => response.data)
}

function stopActiveProcess(processUuid) {
  console.log(processUuid)
  return $_notification_server.delete(`/process/${processUuid}`)
}

// Notifications

function readNotificationsWithUUIDs (UUIDs) {
  UUIDs
  // return $_notification_server.patch('/notification/read', UUIDs).then(response => response.data)
}

function readNotificationsWithType (notificationType) {
  notificationType
  // return $_notification_server.patch('/notification/readType/', notificationType).then(response => response.data)
}

function getNotifications(notification) {
  return $_notification_server.post(`/notification/filter?notificationLimit=${notification.limit}&notificationPage=${notification.page}`, JSON.stringify(notification) )
}

function readNotification (listReadNotification) {
  return $_notification_server.patch('/notification/read', listReadNotification ).then(response => response.data)
}

function readAllNotificationWithProject (projectUuid) {
  return $_notification_server.patch(`/notification/${projectUuid}/readAll`).then(response => response.data)
}

function readAllNotification () {
  return $_notification_server.patch(`/notification/readAll`).then(response => response.data)
}



function deleteNotification(notificationUuid) {
  return $_notification_server.delete(`/notification/${notificationUuid}/delete`).then(response => response.data)
}

function deleteAllNotificationsWithProject(projectUuid) {
  return $_notification_server.delete(`/notification/${projectUuid}/deleteAll`).then(response => response.data)
}

function deleteAllNotifications() {
  return $_notification_server.delete(`/notification/deleteAll`).then(response => response.data)
}

//function applyNotificationsWithUUIDs (UUIDs) {
//  return $_notification_server.patch('/notification/apply', UUIDs).then(response => response.data)
//}


// Model states

function stageDict () {
  return $_app_server.get('/projects/type/stage').then(response => response.data)
}

function qualityDict () {
  return $_app_server.get('/projects/type/quality').then(response => response.data)
}

function accessibleDict () {
  return $_app_server.get('/projects/type/accessible').then(response => response.data)
}

function setStage (modelUuid, stage) {
  return $_app_server.patch(`/projects/model/${modelUuid}/stage`, stage).then(response => response.data)
}

function setQualtiy (modelUuid, quality) {
  return $_app_server.patch(`/projects/model/${modelUuid}/quality`, quality).then(response => response.data)
}

function setAccessible (modelUuid, accessible) {
  return $_app_server.patch(`/projects/model/${modelUuid}/accessible`, accessible).then(response => response.data)
}

function setRoot (modelUuid, root) {
  return $_app_server.patch(`/projects/model/${modelUuid}/root`, root).then(response => response.data)
}

// Team

function searchProfile (text, modelUuid) {
  return $_app_server.get(`/profiles/model/${modelUuid}/search/${text}`).then(response => response.data)
}

/**
 * 
 * @deprecated
 */
function allColleagueProfiles () {
  // return $_app_server.get('/projects/myteam').then(response => response.data)
  // return $_app_server.get('/teammates').then(response => response.data)
  return $_app_server.get('/members').then(response => response.data)
}

function profilesByModel (modelUuid) {
  return $_app_server.get(`/profiles/bymodel/${modelUuid}`).then(response => response.data)
}

function profilesByProject (projectUuid) {
  return $_app_server.get(`/profiles/byproject/${projectUuid}`).then(response => response.data)
}

function addMember (modelUuid, profileUuid, roles) {
  // return $_app_server.post(`/projects/addMember/model/${modelUuid}/profile/${profileUuid}`, roles).then(response => response.data)
  // return $_app_server.post(`teammates/model/${modelUuid}/profile/${profileUuid}`, roles).then(response => response.data)
  return $_app_server.post(`members/model/${modelUuid}/profile/${profileUuid}`, roles).then(response => response.data)
}

function updateTeammateRoles (teammateUuid, roles) {
  // return $_app_server.patch(`/projects/editteammate/${teammateUuid}/role`, roles).then(response => response.data)
  // return $_app_server.patch(`teammates/${teammateUuid}/role`, roles).then(response => response.data)
  return $_app_server.patch(`members/${teammateUuid}/role`, roles).then(response => response.data)
}

function removeTeammate (memberUuid, modelUuid) {
  // return $_app_server.delete(`/projects/delteammate/${teammateUuid}`).then(response => response.data)
  // return $_app_server.delete(`teammates/${teammateUuid}`).then(response => response.data)
  return $_app_server.delete(`members/${memberUuid}/model/${modelUuid}`).then(response => response.data)
}

// ! Delete
function loadElementTree (projectUuid, fixGroupId) {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix + `/projects/elementtree/${projectUuid}/${fixGroupId}`).then(response => response.data)
  return $_app_server.get(`/projects/elementtreecache/${projectUuid}/${fixGroupId}`).then(response => response.data)
}

function loadElementByGlobal (elementGlobalUuid, project, hashProject = null) {
  if (App.version.isCloud) {
    if (hashProject) {
      return $_app_server_noAuth.get(cloudPrefix + `/projects/elementbyguid/${elementGlobalUuid}/${project}/${hashProject}`).then(response => response.data)
    }
    return $_app_server_noAuth.get(cloudPrefix + `/projects/elementbyguid/${elementGlobalUuid}/${project}`).then(response => response.data)
  }
  return $_app_server.get(`/projects/elementbyguid/${elementGlobalUuid}/${project}`).then(response => response.data)
}

// Settings

function updateWorkspace(projectUuid, workSpace) {
  return $_app_server.post(`/projects/project/${projectUuid}/updateworkspace`, workSpace).then(response => response.data)
}

function updateSettings(projectSettings) {
  return $_app_server.post(`/projects/updatesettings`, projectSettings).then(response => response.data)
}

function updateModelRevision(settings) {
  return $_app_server.patch(`/projects/updatesettings/revision`, settings).then(response => response.data)
}

function updateModelSwitch(settings) {
  return $_app_server.patch(`/projects/updatesettings/switch`, settings).then(response => response.data)
}

function updateTaskColumns(projectUuid, taskColumns){
  return $_app_server.post(`/projects/project/${projectUuid}/taskcolumns`, taskColumns).then(response => response.data)
}

function updateProjectStructure(projectUuid, structureSetting) {
  return $_app_server.post(`/projects/project/${projectUuid}/structure`, structureSetting).then(response => response.data)
}

function updateArchivedProjectStructure(projectUuid, archivedStructureSetting) {
  return $_app_server.post(`/projects/project/${projectUuid}/structure/archived`, archivedStructureSetting).then(response => response.data)
}

function updateTaskSections(projectUuid, taskSections){
  return $_app_server.post(`/projects/project/${projectUuid}/tasksections`, taskSections)
}

function updateLightSettings(projectUuid, lightSettings){
  return $_app_server.post(`/projects/project/${projectUuid}/light`, lightSettings)
}

function updateMaintenanceTaskSettings(projectUuid, maintenanceTaskSettings) {
  return $_app_server.post(`/projects/project/${projectUuid}/maintenancetasksettings`, maintenanceTaskSettings)
}

function getMaintenanceTaskSettings(projectUuid) {
  return $_app_server.get(`/projects/project/${projectUuid}/maintenancetasksettings`).then(response => response.data)
}

// Axis

function myAttr () {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix + `/projects/myattr`).then(response => response.data)
  return $_app_server.get(`/projects/myattr`).then(response => response.data)
}

function loadGroupAxis () {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix + `/projects/groupaxis`).then(response => response.data)
  return $_app_server.get(`/projects/groupaxis`).then(response => response.data)
}

function loadProjectAxis (projectUuid) {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix + `/projects/axis/${projectUuid}`).then(response => response.data)
  return $_app_server.get(`/projects/axis/${projectUuid}`).then(response => response.data)
}

function saveAxis (axis) {
  if (App.version.isCloud) return $_app_server_noAuth.post(cloudPrefix + `/projects/axis`, axis).then(response => response.data)
  return $_app_server.post(`/projects/axis`, axis).then(response => response.data)
}

function getBuildingForRevision (projectId, revUuids) {
  if (App.version.isCloud) return $_app_server_noAuth.post(cloudPrefix + `/projects/${projectId}/building/list`, revUuids).then(response => response.data)
  return $_app_server.post(`/projects/${projectId}/building/list`, revUuids).then(response => response.data)
}

function getFloorForRevision (projectId, revUuids) {
  if (App.version.isCloud) return $_app_server_noAuth.post(cloudPrefix + `/projects/${projectId}/floor/list`, revUuids).then(response => response.data)
  return $_app_server.post(`/projects/${projectId}/floor/list`, revUuids).then(response => response.data)
}

// ! Delete
function loadElementGroup (projectId, groupId) {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix + `/projects/elementgroup/${projectId}/${groupId}`).then(response => response.data)
  return $_app_server.get(`/projects/elementgroup/${projectId}/${groupId}`).then(response => response.data)
}


// Condition

function loadParamForCondition (projectId, param, delimiter, hashProject = null) {
  if (App.version.isCloud) {
    if (hashProject) {
      if (delimiter)
        return $_app_server_noAuth.get(cloudPrefix + `/projects/conditionparam/${projectId}/${param}/${delimiter}/${hashProject}`).then(response => response.data)
      return $_app_server_noAuth.get(cloudPrefix + `/projects/conditionparam/${projectId}/${param}/${hashProject}`).then(response => response.data)
    }
    return $_app_server_noAuth.get(cloudPrefix + `/projects/conditionparam/${projectId}/${param}/${delimiter}`).then(response => response.data)
  }
  if (delimiter){
    return $_app_server.get(`/projects/conditionparamcache/${projectId}/${param}/${delimiter}`).then(response => response.data)
  }
  return $_app_server.get(`/projects/conditionparamcache/${projectId}/${param}`).then(response => response.data)
}

function loadAttrForClass (classId, type) {
  return $_app_server.get(`/projects/classattr/${classId}/${type}`).then(response => response.data)
}


function loadConditionOperator () {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix + `/projects/conditionoperator`).then(response => response.data)
  return $_app_server.get(`/projects/conditionoperator`).then(response => response.data)
}

function loadCalcOperator () {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix +`/projects/calcoperator`).then(response => response.data)
  return $_app_server.get(`/projects/calcoperator`).then(response => response.data)
}

function loadLogicOperator () {
  if (App.version.isCloud) return $_app_server_noAuth.get(cloudPrefix +`/projects/logicoperator`).then(response => response.data)
  return $_app_server.get(`/projects/logicoperator`).then(response => response.data)
}

function saveCondition (condition) {
  return $_app_server.post(`/projects/condition`, condition).then(response => response.data)
}

function loadBClassEnumElements (id) {
  return $_app_server.get(`/projects/bclass/enumelements/${id}`).then(response => response.data)
}

function exportToXls() {
  return $_app_server.get(`/projects/export/group/test.xls`).then(response => response.data)
}

//- TODO delete
// function loadCimLogicGroup(model) {
//   return $_app_server.get(`/projects/cim/${model}`).then(response => response.data)
// }
//- TODO delete
// function saveCimLogicGroup(model, logicGroup) {
//   return $_app_server.post(`/projects/cim/${model}`, logicGroup).then(response => response.data)
// }

function makeFloor(project) {
  return $_app_server.get(`/projects/${project}/makefloor`).then(response => response.data)
}

function setOpenedRoles(projectUuid,roles) {
  return $_app_server.patch(`/projects/${projectUuid}/openedroles`, roles).then(response => response.data)
}
  
  
function addPointCloud(pointCloud) {
  return $_app_server.post(`/pointCloud`, pointCloud)
}

function getElementsByFilter(searchElementFilter) {
  return $_app_server.post('/elements', searchElementFilter).then(response => response.data)
}

function getElementsCount(searchElementFilter) {
  return $_app_server.post('/elements/uuids', searchElementFilter).then(response => response.data)
}

function getElementsTypes(searchElementFilter) {
  return $_app_server.post('/elements/classes', searchElementFilter).then(response => response.data)
}

function silenceReGeometry(projectUuid) {
  return $_app_server.patch(`revisions/recalc/${projectUuid}`).then(response => response.data)
}

function updateBimAnnotationsVisibleMode(projectUuid, bimAnnotationsVisibleMode) {
  return $_app_server.patch('/projects/updatesettings/bimannotations/' + projectUuid, bimAnnotationsVisibleMode)
}

function getDrawingOnModelListByUuids(uuid) {
  return $_app_server.get(`/projects/drawingOnModel/${uuid}`).then(response => response.data)
}

function getDrawingsOnModelShortByProject(projectUuid) {
  return $_app_server.get(`/projects/drawingOnModel/${projectUuid}/titles`).then(response => response.data)
}

function saveDrawingOnModel(drawingOnModel) {
  return $_app_server.post(`/projects/drawingOnModel`, drawingOnModel)
}

function deleteDrawingOnModel(drawingOnModelUuid) {
  return $_app_server.delete(`/projects/drawingOnModel/${drawingOnModelUuid}`)
}

function downloadAllRevisions(projectUuid) {
  return $_app_server.get(SourcePath.makePath(`revisions/downloadAllRevision/${projectUuid}`), {
    responseType: 'blob'
  })
  .then(response => response.data)
}

function checkUniqAlias (alias) {
  return $_app_server.patch(`/projects/check/alias`, {alias: alias}).then(response => response.data)
}