import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"

class DefaultColorize {
  constructor(id, colorize) {
    this.colorize = [colorize[0], colorize[1], colorize[2]]
    this.id = id
  }
}
let visConfigurator = function (el, opacity, color = null) {
  let scene = XeokitMediator.viewer?.scene
  if(color && scene)
    XeokitMediator.ElementsSettings.setElementsColorized(el, color)
  if(scene)
    XeokitMediator.ElementsSettings.setElementsOpacity(el, opacity)
}

/**
 * 
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * ПЕРЕЕХАЛО В XeokitMediator.ElementsSelection и VTF.SelectElements
 * 
 */
const getDefaultState = () => {
  return {
    // selectedElements: [],
    // pickedElement: null,
    
    inJob: [],
    ready: [],
    new: [],
    lateness: [],
    expandElementDetails: false,

    defaultColorize: []
  }
}
const state = getDefaultState()

export default {

  namespaced: true,

  state,

  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },
    
    // setSelectedElements: (state, els) => {
    //   state.selectedElements = els
    // },
    // setPickedElement: (state, el) => {
    //   if (state.pickedElement) state.pickedElement.selected = false
    //   state.pickedElement = el
    //   if (state.pickedElement != null) state.pickedElement.selected = true
    // },

    setElementsInJobState: (state, els) => {
      state.inJob = els
    },
    setElementsReadyState: (state, els) => {
      state.ready = els
    },
    setElementsNewState: (state, els) => {
      state.new = els
    },
    setElementsLatenessState: (state, els) => {
      state.lateness = els
    },


    pushToDefaultColorize: (state, el) => {
      // if(state.defaultColorize.findIndex(elm => elm.id == el.id) == -1)
      state.defaultColorize.push(el)
    },
    setDefaultColorize: (state, el) => {
      state.defaultColorize = el
    },

    setExpandElementDetails: (state, val) => {
      state.expandElementDetails = val
    }

  },
  
  actions: {
    // setSelectedElements: ({ commit }, els) => {
    //   commit('setSelectedElements', els)
    //   //dispatch('axis/tree/fetchElementByGlobalId', els[0], { root: true })
    //   // let ids = viewer.scene.visibleObjectIds
    //   // this.viewer.scene.setObjectsHighlighted(this.sceneObjectsIds, false)
    //   // this.viewer.scene.setObjectsHighlighted(this.selectedElements, true)
    // },
    
    // setPickedElement: ({ commit, rootState, dispatch }, el) => {
    //   commit('setPickedElement', el)
    //   if(el) {
    //     if(rootState.task.taskDisplay == "diagram") {
    //       dispatch('task/findTaskByElementGlobalId', el.id, {root: true})
    //     } else {
    //       dispatch('axis/tree/fetchElementByGlobalId', el.id, { root: true })
    //     }
    //   } else {
    //     if(rootState.task.taskDisplay != "diagram") {
    //       dispatch('axis/tree/fetchElementByGlobalId', null, { root: true })
    //     }
    //   }
    // },

    setElementsInJobState: ({ state, commit }, els) => {
      commit('setElementsInJobState', els)
      // dispatch('saveDefaultColorize', els)
      visConfigurator(state.inJob, 0.8, [0.33, 0.33, 0.33])
    },
    setElementsReadyState: ({ state, commit }, els) => {
      commit('setElementsReadyState', els)
      // dispatch('saveDefaultColorize', els)
      visConfigurator(state.ready, 1)
    },
    setElementsNewState: ({ state, commit }, els) => {
      commit('setElementsNewState', els)
      // dispatch('saveDefaultColorize', els)
      visConfigurator(state.new, 0.3, [1, 1, 1])
    },

    setElementsLatenessState: ({ state, commit }, els) => {
      commit('setElementsLatenessState', els)
      // dispatch('saveDefaultColorize', els)
      visConfigurator(state.lateness, 0.5, [1, 0, 0])
    },  

    projectSkeletonization: () => {
      // TODO Переделать под новое XKT API
      const scene = XeokitMediator.viewer.scene
      const visibleObjects = scene.objectIds.filter(objId => scene.objects[objId].opacity > 0)
      
      visConfigurator(visibleObjects, 0.3)
    },

    dropSmetaHighlights({ state }, out) {
      console.log(out)
      let els = []

      els = els.concat(state.inJob)
      els = els.concat(state.new)
      els = els.concat(state.ready)
      els = els.concat(state.lateness)

      visConfigurator(els, 0.3)
      state.defaultColorize.forEach(item => {
        XeokitMediator.viewer.scene.objects[item.id].colorize = item?.colorize
      })
    },

    dropColorized({ state }) {
      // state.defaultColorize.forEach(item => {
      //   XeokitMediator.viewer.scene.objects[item.id].colorize = item?.colorize
      // })
      if (XeokitMediator.viewer?.scene) XeokitMediator.clearColorizeModel()
      state.defaultColorize = []
    },

    dropAllSmetaHighlights( {state, commit} ) {
      if (!XeokitMediator.viewer || !XeokitMediator.viewer.scene) {
        commit("setDefaultColorize", [])
        return 
      }

      const step = 20000
      let start = 0
      let end = step
      let objects = XeokitMediator.viewer.scene.objectIds
      
      const macroTasks = () => {
        visConfigurator(objects.slice(start, end), 1)
        start += step
        end += step
        if(end < objects.length) {
          setTimeout(macroTasks)
        }
        else  {
          setTimeout(() => {
            visConfigurator(objects.slice(start, end), 1)
          })
        }
      }

      macroTasks()

      // visConfigurator(XeokitMediator.viewer.scene.objectIds, 1)
      state.defaultColorize.forEach(item => {
        if(XeokitMediator.viewer.scene.objects[item.id]) {
          XeokitMediator.viewer.scene.objects[item.id].colorize = item?.colorize
        }
      })
    },

    saveDefaultColorize({commit, state}, els) {
      if (els) {
        XeokitMediator.viewer?.scene.withObjects(els, object => {
          if(state.defaultColorize.findIndex(el => el.id == object.id) == -1) {
            commit('pushToDefaultColorize', new DefaultColorize(object.id, object.colorize))
          }
        }) 
      }
    }
  }
}
