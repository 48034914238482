<template lang="pug">
div( ref="floatingPanelContainer" :style="style" )
  floating-panel-header( :title="title" :height="headerHeight" 
                         :backgroundColor="headerColor" 
                         :openedPanel="openedPanel" 
                         :menu="menu"
                         :titleColor="headerTitleColor" 
                         :titleFont="headerTitleFont"
                         :borderSize="headerBorderSize" 
                         :borderColor="headerBorderColor"
                         :borderRadius="headerBorderRadius"
                         :dropDownIcon="headerDropDownIcon"
                         :closeable="closeable"
                         test-id="floating-panel-header"
                         @openBody="openBody()" dropDownIcon="/src/assets/dropdown.svg")
    template( v-slot:headerButton )
      slot(name="headerButton")
    template( v-slot:forwardHeaderButton )
      slot(name="forwardHeaderButton")

  floating-panel-body( v-if="openedPanel" :resizable="isResizable" :overflow="overflowBody"
                       :minHeight="bodyMinHeight" :backgroundColor="bodyColor" :headerBorderSize="headerBorderSize"
                       @createElementBody="createElementBody" @destroyElementBody="destroyElementBody"
                       :startingHeight="bodyStartingHeight" test-id="floating-panel-body" )
    slot
</template>

<script>
import FloatingPanelHeader from "../project/common/floatingPanels/FloatingPanelHeader"
import FloatingPanelBody from "../project/common/floatingPanels/FloatingPanelBody"

export default {
  name: "FloatingPanel",

  components: {
    FloatingPanelHeader,
    FloatingPanelBody
  },

  inject:['floatingPanels'],

  props: {
    menu: Array,

    closeable: { type: Boolean, default: true },

    title: { type: String, default: "" },

    minHeight: { type: [String, Number], default: 100 },

    headerHeight: { type: [String, Number], default: 35 },

    headerColor: { type: String,  default: "var(--v-surface-lighten1)" },

    headerTitleColor: { type: String, default: "var(--v-primary-darken2)" },

    headerTitleFont: { type: String, default: "14px 'Roboto' bold" },

    headerBorderSize: { type: [String, Number], default: 1 },

    headerBorderColor: { type: [String, Number], default: "#C4C4C4" },

    headerBorderRadius: { type: [String, Number], default: "3" },

    headerDropDownIcon: { type: [String, Number], default: "" },

    bodyMinHeight: { type: [String, Number], default: 25 },

    bodyColor: { type: [String], default: "#ffffff" },

    marginBottom: { type: [String, Number], default: 3 },

    overflowBody: { type: String, default: "hidden" },

    panelOpen: { type: Boolean, default: true },

    bodyStartingHeight: { type: [Number] },

    startHeight: { type: [Number, String], default: 25 },

    settings: null
  },

  data() {
    return {
      openedPanel: false,
    }
  },

  mounted() {
    this.registerPanel()
  },

  beforeDestroy() {
    this.unregisterPanel()
  },

  computed: {
    style() {
      return {
        'margin-bottom': this.marginBottom + "px",
      }  
    },

    isResizable() { 
      return (this.floatingPanels.slice(this.floatingPanels.findIndex(panel => panel.element == this.$refs.floatingPanelContainer) + 1)).find(panel => panel.openedPanel == true) ? true : false
    },
  },

  methods: {
    registerPanel() {
      let panel = {
        element: this.$refs.floatingPanelContainer,
        openedPanel:  this.settings.opened == undefined ? true : this.settings.opened,
        elementBody: null,
        elementResizer: null,
        bodyMinHeight: Number(this.bodyMinHeight),
        headerHeight: Number(this.headerHeight),
        headerBorderSize: Number(this.headerBorderSize),
        marginBottom: Number(this.marginBottom),
        panelResizerFunc: null,
        lastHeight: Number(this.bodyMinHeight),
        startHeight: this.settings.height,
        key: this.settings.floatingPanel
      }
      this.$parent.$emit('registerPanel', panel)

      if(this.settings.opened) this.openBody()
    },

    createElementBody(element, elementResizer) {
      this.$parent.$emit('openPanel', this.$refs.floatingPanelContainer, element, elementResizer)
    },

    destroyElementBody() {
      this.$parent.$emit('closePanel', this.$refs.floatingPanelContainer)
    },

    unregisterPanel() {
      this.$parent.$emit('unregisterPanel', this.$refs.floatingPanelContainer)
    },

    openBody() {
      this.openedPanel = !this.openedPanel;
      this.$parent.$emit('openBody', this.$refs.floatingPanelContainer, this.openedPanel)
      this.openedPanel && this.$emit('open')
    },

    add() {
      this.$emit('add')
    }

  }
}
</script>