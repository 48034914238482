import { themes } from '@/store/theme.module'
import { api } from '@/api'
import { App } from '@app/App'
import { authUserService } from "@/_services";
import { AlertService } from '@app/AlertService'
import i18n from '@/plugins/i18n'
import project from './project.module'

let isStorageProfile = localStorage.getItem("profile") && localStorage.getItem("profile")!=='undefined'
let isMouseNav = localStorage.getItem("mouseNav") && localStorage.getItem("mouseNav")!==undefined

export let type = {
  THEME: 0, 
  ATTR: 1, 
  COLLTABLE: 2, 
  MOUSENAV: 3, 
  TASK_FILTER: 4,

  TIME_ZONE: 8,

  NOTIFY_EMAIL: 5, 
  NOTIFY_TELEGRAM: 6,
  NOTIFY_INTERVAL: 9,
  NOTIFY_REVISION: 10,
  NOTIFY_NEW_TASK: 11,
  NOTIFY_EDIT_TASK: 12,
  NOTIFY_COLLISION: 13,
  NOTIFY_DRAWING: 14,
  NOTIFY_NIGHT: 15,
  NOTIFY_WEEKEND: 16,
  NOTIFY_PLUGIN_ACTION: 20,
  NOTIFY_COLLISION_ONLY_MY_MODELS: 21,
  NOTIFY_WEB_PUSH: 22,

  MODEL_BACKGROUND: 17, // Цвет фона модели
  MODEL_QUALITY: 18, // Качество модели
  MODEL_LIGHTS: 19, // Освещенность модели

  LOCALE: 7
}

let timeZones = [
  {title:"UTC+1", value:"GMT+1", hour: 1}, 
  {title:"UTC+2", value:"GMT+2", hour: 2}, 
  {title:"UTC+3", value:"GMT+3", hour: 3}, 
  {title:"UTC+4", value:"GMT+4", hour: 4}, 
  {title:"UTC+5", value:"GMT+5", hour: 5},
  {title:"UTC+6", value:"GMT+6", hour: 6}, 
  {title:"UTC+7", value:"GMT+7", hour: 7}, 
  {title:"UTC+8", value:"GMT+8", hour: 8}, 
  {title:"UTC+9", value:"GMT+9", hour: 9},
  {title:"UTC+10", value:"GMT+10", hour: 10}, 
  {title:"UTC+11", value:"GMT+11", hour: 11}, 
  {title:"UTC+12", value:"GMT+12", hour: 12}, 
]


let hoursToMilliseconds = function (hours) {
  return hours*3600000
}

let intervals = [ 
  { title: "profile.action.items.notifyPanel.notifyPeriod.atOnce", value: 0 }, 
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceAnHour", value: hoursToMilliseconds(1) }, 
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceAnTwoHour", value: hoursToMilliseconds(2) }, 
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceAnThreeHour", value: hoursToMilliseconds(3) }, 
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceAnFourHour", value: hoursToMilliseconds(4) }, 
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceAnSixHour", value: hoursToMilliseconds(6) }, 
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceAnEightHour", value: hoursToMilliseconds(8) },
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceADay", value: hoursToMilliseconds(24) }, 
  { title: "profile.action.items.notifyPanel.notifyPeriod.onceAWeek", value: hoursToMilliseconds(168) }
]

let defaultNotify = Object.freeze({
  switcher: false,
  value: null
})

let defaultQuality = { 
  edges: true, 
  sao: true, 
  antialias: true, 
  armature: true, 
  speedRender: true 
}

function getWhatUserCanUseByFuncTypes(state, types) {
  let members = project.state.project && project.state.project.membership ? project.state.project.membership : []
  if (members.length == 0) return false
  let userMembers = members.filter(m => m.profile && m.profile.uuid == state.user.uuid)

  members.forEach(el => {
    if (el.orgGroup) {
      let user = el.orgGroup.employees?.find(m => m.profile && m.profile.uuid == state.user.uuid)
      if (user) {
        user.memberReference = el.memberReference
        userMembers.push(user)
      }
    }
  })

  let list = userMembers.some(e =>  e.owner
                                || e.memberReference.some(mr => mr.roles.some(mrr => mrr.rolefunction.some(rolefunc => rolefunc.functionType && types.includes(rolefunc.functionType.name) )))
                                || e.memberReference.some(mr => mr.orgRoles.some(mrr => mrr.rolefunction.some(rolefunc => rolefunc.functionType && types.includes(rolefunc.functionType.name) )))

                                )


  return list
}

export default {
  namespaced: true,

  state: {
    user: isStorageProfile ? JSON.parse(localStorage.getItem("profile")) : {name: null},
    mouseNav: isMouseNav ? localStorage.getItem("mouseNav") : "user"
  },

  getters: {
    fullname: state => {
      if (state.user && state.user.name)  
        return state.user.name
      return localStorage.getItem("LoginName")
    },

    myUUID: ({ user }) => user?.uuid,
    adminOrgs: ({ user }) => (user && user.adminOrgs) ? user.adminOrgs : [],
    cloudOrg: ({ user }) => (user && user.cloudOrg) ? user.cloudOrg : null,

    orgById: (state, {adminOrgs}) => id => {
      let org = adminOrgs.find(o => o.uuid == id)
      if (org !== undefined) {
        return org
      } else {
        // AlertService.error( {data: { error_description: 'Нет доступа к организации' } } )
        return null
      }
    },

    
    notifyEmail: ({user}) => user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_EMAIL) || Object.assign({}, defaultNotify),
    notifyTelegram: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_TELEGRAM) || Object.assign({}, defaultNotify),

    timeZone: ({user}) =>  {
      let timezone = user.profileSettings?.find(setting => setting.type.value === type.TIME_ZONE) || Object.assign({}, defaultNotify)
      return timeZones?.find(tZone => tZone.value === timezone.value) || timeZones[3]
    },

    notifyInterval: ({user}) => { 
      let interval = user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_INTERVAL) || Object.assign({}, defaultNotify)
      return intervals?.find(element => element.value.toString() === interval.value) || intervals[0]
    },
    
    notifyRevision: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_REVISION) || Object.assign({}, defaultNotify),
    notifyNewTask: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_NEW_TASK) || Object.assign({}, defaultNotify),
    notifyEditTask: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_EDIT_TASK) || Object.assign({}, defaultNotify),
    notifyCollision: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_COLLISION) || Object.assign({}, defaultNotify),
    notifyCollisionOnlyMyModels: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_COLLISION_ONLY_MY_MODELS) || Object.assign({}, defaultNotify),
    notifyDrawing: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_DRAWING) || Object.assign({}, defaultNotify),
    notifyNight: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_NIGHT) || Object.assign({}, defaultNotify),
    notifyWeekend: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_WEEKEND) || Object.assign({}, defaultNotify),
    notifyPlugin: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_PLUGIN_ACTION) || Object.assign({}, defaultNotify),
    notifyWebPush: ({user}) => user.profileSettings?.find(setting => setting.type.value === type.NOTIFY_WEB_PUSH) || Object.assign({}, defaultNotify),

    intervalList: () => {return intervals},
    timeZoneList: () => {return timeZones},

    toLocalTimeZone: ({state}) => (date) =>{
      state

      let dateTime = new Date(date.replace(" ", "T"))

      // Московский часовой пояс -3
      let changeHours =  -( new Date().getTimezoneOffset() / 60 ) - 3; 
      let changeMinutes = ( changeHours % 1 ) * 60
      changeHours = Math.trunc( changeHours )

      dateTime.setHours( dateTime.getHours() + changeHours )
      dateTime.setMinutes( dateTime.getMinutes() + changeMinutes )

      let year = dateTime.getFullYear().toString().substring(2)
      let month = ( dateTime.getMonth() + 1 ) < 10 ? "0" + ( dateTime.getMonth() + 1 ) : dateTime.getMonth() + 1
      let day = dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate()
      let hour = dateTime.getHours() < 10 ? "0" + dateTime.getHours() : dateTime.getHours()
      let minutes = dateTime.getMinutes() < 10 ? "0" + dateTime.getMinutes() : dateTime.getMinutes()

    
      return `${day}.${month}.${year} ${hour}:${minutes}` //+" ("+getters.timeZone.title+")"
    },

    getFloatingPanels: ({user}) => {
      return user.settings?.floatingPanels
    },

    notifySettings: ({user}) => {
      return user.settings?.notificationSettings
    },

    getRulerSettings: ({user}) => {
      return user.settings?.rulerSettings
    },

    isProjectOwner: ({user}, getters, rootState) => {
       return  rootState?.project?.project?.membership?.find(member => member.owner && user.uuid == member.profile.uuid)
    },

    modelBackground: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.MODEL_BACKGROUND) || null,
    
    modelQuality: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.MODEL_QUALITY) || null,
    modelQualityJSON: ( state, {modelQuality}) => {
      if (modelQuality && modelQuality.value) {
        return JSON.parse(modelQuality.value)
      }
      return defaultQuality
    },

    modelLights: ({user}) =>  user.profileSettings?.find(setting => setting.type.value === type.MODEL_LIGHTS) || null,
    
    getMouseNav: state => {
      return state.mouseNav
    },

    canViewClassificator: (state) => {
      return getWhatUserCanUseByFuncTypes(state, ['SMETA_CLASSIFICATOR_VIEW', 'SMETA_CLASSIFICATOR'] )
    },

    canWorkWithClassificator: (state) => {
        return getWhatUserCanUseByFuncTypes(state, ['SMETA_CLASSIFICATOR'] )
    },

    canViewWorm: (state) => {
      return getWhatUserCanUseByFuncTypes(state, ['SMETA_WORM_VIEW', 'SMETA_WORM'] )
    },

    canWorkWithWorm: (state) => {
      return getWhatUserCanUseByFuncTypes(state, ['SMETA_WORM'] )
    },

    canViewMaintenance: (state) => {
      return getWhatUserCanUseByFuncTypes(state, ['MAINTENANCE_EQUIPMENT'] )
    },

    canViewWorkSpaces: (state) => {
      return getWhatUserCanUseByFuncTypes(state, ['PROJECT_WORKSPACES'], true )
    },

    cloudVisibilitySettings: ({cloudVisibilitySettings}) => cloudVisibilitySettings,
  },

  mutations: {
    setUser: (state, user) => {
      if (user && user!==undefined) {
        localStorage.setItem('profile', JSON.stringify(user))
        state.user = user
      }
    },

    setMouseNav: (state, mode) => {
      state.mouseNav = mode
      localStorage.setItem('mouseNav', mode)
    },

    setFloatingPanels: (state, floatingPanels) => {
      state.user.settings.floatingPanels = floatingPanels
      let user = JSON.parse(localStorage.getItem('profile'))
      user.settings.floatingPanels = state.user.settings.floatingPanels
      localStorage.setItem('profile', JSON.stringify(user))
    },

    setNotificationSetting: (state, notifySettings) => {
      state.user.settings.notificationSettings = notifySettings
      let user = JSON.parse(localStorage.getItem('profile'))
      user.settings.notificationSettings = state.user.settings.notificationSettings
      localStorage.setItem('profile', JSON.stringify(user))
    },

    setRulerSettings: (state, rulerSettings) => {
      state.user.settings.rulerSettings = rulerSettings
      let user = JSON.parse(localStorage.getItem('profile'))
      user.settings.rulerSettings = state.user.settings.rulerSettings
      localStorage.setItem('profile', JSON.stringify(user))
    },

    setCloudVisibilitySettings: (state, cloudVisibilitySettings) => { state.cloudVisibilitySettings = cloudVisibilitySettings },
  },

  actions: {
    LOAD_CURRENT_USER: ({ commit, dispatch }) => {
      return api.users.authorized().then(user => {
        api.corp.orgsByAdmin(user.uuid).then(orgs =>{
          user.adminOrgs = orgs;
          user.cloudOrg = null;
          commit('setUser', user)
        }).then(()=>{
        api.corp.orgForUser(user.uuid).then(org =>{
          user.cloudOrg = org
          commit('setUser', user)
        })});

        api.users.getSettings().then(settings => {
          dispatch('setFloatingPanel', settings.floatingPanels)
          commit('setNotificationSetting', settings.notificationSettings)
        })

        if (user.profileSettings!==undefined) {
          let locale = user.profileSettings.find(item=> item.type.value===type.LOCALE)
          if (!locale) dispatch('setProfileSettingLocale', localStorage.getItem("locale"))
          else { 
            localStorage.setItem("locale", locale.value)
            i18n.locale = locale.value;
          }

          let nav = user.profileSettings.find(item => item.type && item.type.value === type.MOUSENAV)
          if (nav) {
            var mouseNav = user.profileSettings.filter(item => item.type && item.type.value === type.MOUSENAV)[0].value
            commit("setMouseNav", mouseNav)
          }
        } else {
          dispatch('setProfileSettingLocale', localStorage.getItem("locale"))
        }

        let themeProfile = themes.DARK.name;
        if (user.profileSettings.filter(item => item.type && item.type.value === type.THEME).length > 0) {
          themeProfile = user.profileSettings.filter(item => item.type && item.type.value === type.THEME)[0].value
        }

        if (themeProfile !== undefined) {
          Object.keys(themes).map(k => {
            if (themes[k].name === themeProfile)  {
              dispatch('theme/SET_THEME', themes[k], { root: true })
              themeProfile = themes[k]
            }
          })
        }

        return themeProfile
      })
      
    },

    saveUser: ({ commit }, user) => {
      api.users.editWithAvatar(user).then(data => {
        if (data.newemail) {
          AlertService.info({info:  i18n.t('success.changeEmail', {username: data.email} ) })
          authUserService.logout();
        } else
          commit('setUser', data)
      })
      // api.users.edit(user).then(data => {
      //   if (data.newemail) {
      //     AlertService.info({info:  i18n.t('success.changeEmail', {username: data.email} ) })
      //     authUserService.logout();
      //   } else
      //     commit('setUser', data)
      // })
    },

    setProfileSettingTheme: ({ state, dispatch }, selectedTheme) => {
      let profileSettings = state.user.profileSettings?.filter(item => item.value === type.THEME)[0]

      if (profileSettings !== undefined) profileSettings.value = selectedTheme.name
      else profileSettings = {type: {value: type.THEME}, value: selectedTheme.name}

      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingModeMouseNav: ({ dispatch }, mouseNav) => {
      if (!App.version.isCloud) {
        var profileSettings = {type: {value: type.MOUSENAV}, value: mouseNav}
        dispatch('setProfileSetting', profileSettings)
      }
    },

    setProfileSettingEmail: ({ dispatch }, email) => {
      let profileSettings = {type: {value: type.NOTIFY_EMAIL}, value: email.value, switcher: email.switcher}
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingTelegram: ({ dispatch }, telegram) => {
      let profileSettings = {type: {value: type.NOTIFY_TELEGRAM}, value: telegram.value, switcher: telegram.switcher}
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingTimeZone: ({ dispatch }, timeZone) => {
      let profileSettings = {type: {value: type.TIME_ZONE}, value: timeZone, switcher: false }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingInterval: ({ dispatch }, interval) => {
      let profileSettings = {type: {value: type.NOTIFY_INTERVAL}, value: interval, switcher: false }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingRevision: ({ dispatch }, revision) => {
      let profileSettings = {type: {value: type.NOTIFY_REVISION}, value: '', switcher: revision }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingNewTask: ({ dispatch }, newTask) => {
      let profileSettings = {type: {value: type.NOTIFY_NEW_TASK}, value: '', switcher: newTask }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingEditTask: ({ dispatch }, editTask) => {
      let profileSettings = {type: {value: type.NOTIFY_EDIT_TASK}, value: '', switcher: editTask }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingCollision: ({ dispatch }, collision) => {
      let profileSettings = {type: {value: type.NOTIFY_COLLISION}, value: '', switcher: collision }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingCollisionOnlyMyModels: ({ dispatch }, collision) => {
      let profileSettings = {type: {value: type.NOTIFY_COLLISION_ONLY_MY_MODELS}, value: '', switcher: collision }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingPlugins: ({ dispatch }, plugins) => {
      let profileSettings = {type: {value: type.NOTIFY_PLUGIN_ACTION}, value: '', switcher: plugins }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingLocale: ({ dispatch }, locale) => {
      let profileSettings = {type: {value: type.LOCALE}, value: locale}
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSetting: ({ dispatch }, profileSettings) => {
      api.users.setSettings(profileSettings).then(() => {
        dispatch('LOAD_CURRENT_USER')
      })
    },

    setProfileSettingList({ dispatch }, profileSettingList) {
      api.users.setSettingList(profileSettingList).then(() => {
        dispatch('LOAD_CURRENT_USER')
      })
    },

    setProfileSettingDrawing: ({ dispatch }, notifyDrawing) => {
      let profileSettings = {type: {value: type.NOTIFY_DRAWING}, value: '', switcher: notifyDrawing }
      dispatch('setProfileSetting', profileSettings)
    },
    
    setProfileSettingNight: ({ dispatch }, notifyNight) => {
      let profileSettings = {type: {value: type.NOTIFY_NIGHT}, value: '', switcher: notifyNight }
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingWeekend: ({ dispatch }, notifyWeekend) => {
      let profileSettings = {type: {value: type.NOTIFY_WEEKEND}, value: '', switcher: notifyWeekend }
      dispatch('setProfileSetting', profileSettings)
    },


    getCloudVisibilitySettings({ commit, rootGetters }) {
      let projectUuid = rootGetters['project/projectUuid']
      return api.modelVisualSetting.getCloudVisibilitySettings(projectUuid).then(data => {
        commit('setCloudVisibilitySettings', data)
        return data  
      })
    },
    
    postCloudVisibilitySettings({ rootGetters }, cloudConf) {
      let projectUuid = rootGetters['project/projectUuid']
      api.modelVisualSetting.postCloudVisibilitySettings(projectUuid, cloudConf).then()
    },


    updateUser: ({ commit }, user) => {
      commit('setUser', user)
    },

    setFloatingPanel: ({ state, commit }, floatingPanels) => {
      if (state.user.settings != null) {
        commit('setFloatingPanels', floatingPanels)
      }
    },

    setNotificationSettings: ({ dispatch }, {notifySettings, profileSettings}) => {
      api.users.setSettingList(profileSettings).then(() => {
        api.users.setNotifySettings(notifySettings).then(() => {
          dispatch('LOAD_CURRENT_USER')
        })
      })
    },

    setRulerSetting: ({ state, dispatch, commit }, rulerSettings) => {
      if(state.user.settings!=null){
        commit('setRulerSettings', rulerSettings)
      } else {
        dispatch('LOAD_CURRENT_USER')
      }
    },

    setProfileSettingModelBackground: ({ state, dispatch }, colorModel) => {
      let profileSettings = state.user.profileSettings.filter(item => item.value === type.MODEL_BACKGROUND)[0]
      profileSettings = {type: {value: type.MODEL_BACKGROUND}, value: colorModel}
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingModelQuality: ({ state, dispatch }, quality) => {
      let profileSettings = state.user.profileSettings.filter(item => item.value === type.MODEL_BACKGROUND)[0]
      profileSettings = {type: {value: type.MODEL_QUALITY}, value: quality}
      dispatch('setProfileSetting', profileSettings)
    },

    setProfileSettingModelLights: ({ state, dispatch }, lights) => {
      let profileSettings = state.user.profileSettings.filter(item => item.value === type.MODEL_BACKGROUND)[0]
      profileSettings = {type: {value: type.MODEL_LIGHTS}, value: lights}

      dispatch('setProfileSetting', profileSettings)
    },
    
    updateUserOrg: ( {state, commit}, org) => {
      let user = state.user
      user?.adminOrgs?.map(o => {
        if ( o.uuid === org.uuid) o.admins = org.admins
        return o
      })
      if (user) commit('setUser', user)
    },
  }

}