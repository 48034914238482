<template>
  <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 7.78878L14 0.577244L26.5 7.78878V22.2112L14 29.4228L1.5 22.2112V7.78878Z" stroke="#B5B5B5"/>
    <path d="M1.49987 8.00019L14.0002 14.5" stroke="#303030"/>
    <path d="M26 8L14 14.5L14 29" stroke="#B5B5B5"/>
    <rect x="12.5" y="13.5" width="3" height="3" fill="white" stroke="#303030"/>
    <rect x="0.5" y="6.5" width="3" height="3" fill="white" stroke="#303030"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>