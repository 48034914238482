import { assert } from "@/utils"
import { AxisNode, AxisTree, AxisUtils } from "."

export class AxisTreeStateCache {
  constructor () {
    this.nodesDataMap = new WeakMap(),
    this.disclosedNodes = new WeakSet(),

    this.numberOfDisclosedNodes = 0
    this.numberOfSelectedEndpoints = 0

    this.tree = new AxisTree

    this.selectedEndpointExtraIDs = new Set()
  }

  get totalNodes () {
    return this.tree.onlyNodes.length
  }
  
  get totalEndpoints () {
    return this.tree.onlyEndpoints.length
  }

  get selectedEndpointGlobalIDs () {
    let ids = []
    this.tree.flatlist.filter(node => this.selectedEndpointExtraIDs.has(node.extraId)).forEach(node => {
        ids.push(node.globalId)
        if(node.parent) this.addParentElementId(node.parent, ids)
    })

    return new Set(ids)
    //return new Set(this.tree.flatlist.filter(node => this.selectedEndpointExtraIDs.has(node.extraId)).map(node => node.globalId))
  }

  addParentElementId(node, ids) {
    if(node.globalId != null && !node.globalId.startsWith("S")) ids.push(node.globalId)
    if(node.parent) this.addParentElementId(node.parent, ids)
  }

  saveTree (tree, selectedFromServer = false) {
    this.tree = new AxisTree(tree)
    this.disclosedNodes = new WeakSet(),
    this.numberOfDisclosedNodes = 0

    if(selectedFromServer){
      this.updateSelectedNodesFromServer()
    } else {
      let extraIdSet = new Set()
      for (const extraId of this.tree.onlyEndpoints.map(node => node.extraId)) {
        if (this.selectedEndpointExtraIDs.has(extraId)) {
          extraIdSet.add(extraId)
          let node = this.tree.extraMap.get(extraId)
          this.#_updateAffectedParentStackAtNode(node, 1)
        }
      }
      this.selectedEndpointExtraIDs = extraIdSet
      this.numberOfSelectedEndpoints = extraIdSet.size
    }
  }

  updateSelectedNodesFromServer () {
    this.selectedEndpointExtraIDs = new Set()

    this.nodesDataMap = new WeakMap()

    for (const node of this.tree.selectedNodes) {
      this.selectedEndpointExtraIDs.add(node.extraId)
      this.#_updateAffectedParentStackAtNode(node, 1)
    }
    this.numberOfSelectedEndpoints = this.selectedEndpointExtraIDs.size
  }

  selectAll () {
    for (const node of this.tree.onlyNodes) {
      this.#_updateDataAtNode(node, { newValue: node.numberOfEndpoints })
    }
    this.selectedEndpointExtraIDs = new Set(this.tree.onlyEndpoints.map(node => node.extraId))
    this.numberOfSelectedEndpoints = this.totalEndpoints
  }
  
  deselectAll () {
    for (const node of this.tree.onlyNodes) {
      this.#_updateDataAtNode(node, { newValue: 0 })
    }
    this.selectedEndpointExtraIDs = new Set()
    this.numberOfSelectedEndpoints = 0
  }

  selectEndpointNode (node) {
    assert.is(node, AxisNode)

    if (node.isEndpoint) {
      let delta = this.selectedEndpointExtraIDs.has(node.extraId) ? 0 : 1
      this.numberOfSelectedEndpoints += delta
      this.#_updateEndpointSelection(node, false)
    }
  }

  toggleNodeSelection (node) {
    assert.is(node, AxisNode)

    let { delta, wasSelected } = this.#_precalculateData(node)
    this.numberOfSelectedEndpoints += delta
    
    this.#_updateAffectedParentStackAtNode(node, delta)
    return this.#_updateAffectedChildrenStackAtNode(node, wasSelected)
  }

  #_precalculateData = function (node) {
    let numberOfSelected = this.#_countSelectedEndpoints(node)
    let max = node.isNode ? node.numberOfEndpoints : 1
    let wasSelected = max == numberOfSelected
    let delta = wasSelected ? -max : max - numberOfSelected
    return { delta, wasSelected }
  }

  #_countSelectedEndpoints = function (node) {
    if (node.isEndpoint) {
      return this.selectedEndpointExtraIDs.has(node.extraId) ? 1 : 0
    }
    return this.nodesDataMap.get(node)?.numberOfSelected ?? 0
  }

  #_updateAffectedParentStackAtNode = function(node, delta) {
    for (const parent of node.nodePath) {
      this.#_updateDataAtNode(parent, { delta })
    }
  }

  #_updateAffectedChildrenStackAtNode = function(node, wasSelected) {
    this.#_toggleNodeSelection(node, wasSelected)
    
    let children = AxisUtils.flatlist(node.children)
    for (const child of children) {
      this.#_toggleNodeSelection(child, wasSelected)
    }
    
    return {
      highlighted: wasSelected ? false : true,
      endpoints: node.isEndpoint ? [node] : children.filter(child => child.isEndpoint)
    }
  }

  #_toggleNodeSelection = function (node, wasSelected) {
    if (node.isEndpoint) {
      this.#_updateEndpointSelection(node, wasSelected)
    }
    else {
      let newValue = wasSelected ? 0 : node.numberOfEndpoints
      this.#_updateDataAtNode(node, { newValue })
    }
  }
  
  #_updateEndpointSelection = function (node, wasSelected) {
    if (wasSelected) 
      this.selectedEndpointExtraIDs.delete(node.extraId)
    else 
      this.selectedEndpointExtraIDs.add(node.extraId)
  }
  
  #_updateDataAtNode = function (node, { delta, newValue }) {
    let nodeData = this.nodesDataMap.get(node)
    if (!nodeData) {
      nodeData = { numberOfSelected: 0 }
      this.nodesDataMap.set(node, nodeData)
    }
    if (newValue !== undefined) nodeData.numberOfSelected = newValue
    if (delta !== undefined) nodeData.numberOfSelected += delta
  }
}