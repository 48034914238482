<template> 
<!-- TODO: обновить иконку, когда будет закончена -->
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.499817 0.5H23.4998V23.5H0.499817V0.5Z" fill="white" stroke="#F0F0F0"/>
    <path d="M13.4998 13.5938V4.03125L21.5311 10.4062V19.9688L13.4998 13.5938Z" stroke="#FF0000"/>
    <path d="M2.53125 13.4375V3.53125H10.5V13.4375H2.53125Z" stroke="#FF0000"/>
    <path d="M16.5 14.0317V9.03174" stroke="#303030"/>
    <path d="M6.51563 11.0315L6.51562 6.03174" stroke="#303030"/>
    <path d="M16.8123 11.5317L6.5 8.5" stroke="#303030"/>
  </svg>

</template>

<script>
export default {};
</script>

<style>
</style>