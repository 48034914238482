import { Component } from '@xeokit/xeokit-sdk';
import { Node } from '@xeokit/xeokit-sdk';
import { Mesh } from "@xeokit/xeokit-sdk";
import { PhongMaterial } from "@xeokit/xeokit-sdk";
import { buildPlaneGeometry } from "@xeokit/xeokit-sdk";
import { Texture } from "@xeokit/xeokit-sdk";
import { buildGridGeometry } from "@xeokit/xeokit-sdk";
import { ReadableGeometry } from "@xeokit/xeokit-sdk";
import { math } from "@xeokit/xeokit-sdk";

const tempVec3 = math.vec3();
const tempVec3b = math.vec3();
// const tempVec3c = math.vec3();
const zeroVec = math.vec3([0, -1, 0]);
const tempQuat = math.vec4([0, 0, 0, 1]);

 class ImagePlane extends Component {

    /**
     * @constructor
     * @param {Component} [owner]  Owner component. When destroyed, the owner will destroy this ````ImagePlane```` as well.
     * @param {*} [cfg]  ````ImagePlane```` configuration
     * @param {String} [cfg.id] Optional ID, unique among all components in the parent {@link Scene}, generated automatically when omitted.
     * @param {Boolean} [cfg.visible=true] Indicates whether or not this ````ImagePlane```` is visible.
     * @param {Boolean} [cfg.gridVisible=true] Indicates whether or not the grid is visible.  Grid is only visible when ````ImagePlane```` is also visible.
     * @param {Number[]} [cfg.position=[0,0,0]] World-space position of the ````ImagePlane````.
     * @param {Number[]} [cfg.size=1] World-space size of the longest edge of the ````ImagePlane````. Note that
     * ````ImagePlane```` sets its aspect ratio to match its image. If we set a value of ````1000````, and the image
     * has size ````400x300````, then the ````ImagePlane```` will then have size ````1000 x 750````.
     * @param {Number[]} [cfg.rotation=[0,0,0]] Local rotation of the ````ImagePlane````, as Euler angles given in degrees, for each of the X, Y and Z axis.
     * @param {Number[]} [cfg.matrix=[1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1]] Modelling transform matrix for the ````ImagePlane````. Overrides the ````position````, ````size```, ````rotation```` and ````dir```` parameters.
     * @param {Boolean} [cfg.collidable=true] Indicates if the ````ImagePlane```` is initially included in boundary calculations.
     * @param {Boolean} [cfg.clippable=true] Indicates if the ````ImagePlane```` is initially clippable.
     * @param {Boolean} [cfg.pickable=true] Indicates if the ````ImagePlane```` is initially pickable.
     * @param {Number} [cfg.opacity=1.0] ````ImagePlane````'s initial opacity factor, multiplies by the rendered fragment alpha.
     * @param {String} [cfg.src] URL of image. Accepted file types are PNG and JPEG.
     * @param {HTMLImageElement} [cfg.image] An ````HTMLImageElement```` to source the image from. Overrides ````src````.
     */
    constructor(owner, cfg = {}) {

        super(owner, cfg);

        this._src = null;
        this._image = null;
        this._pos = math.vec3();
        this._origin = math.vec3();
        this._rtcPos = math.vec3();
        this._dir = math.vec3();
        this._size = 1.0;
        this._imageSize = math.vec2();

        this._texture = new Texture(this);

        this._plane = new Mesh(this, {

            geometry: new ReadableGeometry(this, buildPlaneGeometry({
                center: [0, 0, 0],
                xSize: 1,
                zSize: 1,
                xSegments: 1,
                zSegments: 1
            })),

            material: new PhongMaterial(this, {
                diffuse: [0, 0, 0],
                ambient: [0, 0, 0],
                specular: [0, 0, 0],
                diffuseMap: this._texture,
                emissiveMap: this._texture,
                backfaces: true,
            }),
            clippable: cfg.clippable
        });

        this._grid = new Mesh(this, {
            geometry: new ReadableGeometry(this, buildGridGeometry({
                size: 1,
                divisions: 10
            })),
            material: new PhongMaterial(this, {
                diffuse: [0.0, 0.0, 0.0],
                ambient: [0.0, 0.0, 0.0],
                emissive: [0.2, 0.8, 0.2]
            }),
            position: [0, 0.001, 0.0],
            clippable: cfg.clippable
        });

        this._node = new Node(this, {
            rotation: [0, 0, 0],
            position: [0, 0, 0],
            scale: [1, 1, 1],
            clippable: false,
            children: [
                this._plane,
                this._grid
            ]
        });

        this._gridVisible = false;

        this.visible = true;
        this.gridVisible = cfg.gridVisible;
        this.position = cfg.position;
        this.rotation = cfg.rotation;
        this.dir = cfg.dir;
        this.size = cfg.size;
        this.collidable = cfg.collidable;
        this.clippable = cfg.clippable;
        this.pickable = cfg.pickable;
        this.opacity = cfg.opacity;

        if (cfg.image) {
            this.image = cfg.image;
        } else {
            this.src = cfg.src;
        }
    }

    /**
     * Sets if this ````ImagePlane```` is visible or not.
     *
     * Default value is ````true````.
     *
     * @param {Boolean} visible Set ````true```` to make this ````ImagePlane```` visible.
     */
    set visible(visible) {
        this._plane.visible = visible;
        this._grid.visible = (this._gridVisible && visible);
    }

    /**
     * Gets if this ````ImagePlane```` is visible or not.
     *
     * Default value is ````true````.
     *
     * @returns {Boolean} Returns ````true```` if visible.
     */
    get visible() {
        return this._plane.visible;
    }

    /**
     * Sets if this ````ImagePlane````'s grid  is visible or not.
     *
     * Default value is ````false````.
     *
     * Grid is only visible when ````ImagePlane```` is also visible.
     *
     * @param {Boolean} visible Set ````true```` to make this ````ImagePlane````'s grid visible.
     */
    set gridVisible(visible) {
        visible = (visible !== false);
        this._gridVisible = visible;
        this._grid.visible = (this._gridVisible && this.visible);
    }

    /**
     * Gets if this ````ImagePlane````'s grid is visible or not.
     *
     * Default value is ````false````.
     *
     * @returns {Boolean} Returns ````true```` if visible.
     */
    get gridVisible() {
        return this._gridVisible;
    }

    /**
     * Sets an ````HTMLImageElement```` to source the image from.
     *
     * Sets {@link Texture#src} null.
     *
     * @type {HTMLImageElement}
     */
    set image(image) {
        this._image = image;
        if (this._image) {
            this._imageSize[0] = image.width;
            this._imageSize[1] = image.height;
            this._updatePlaneSizeFromImage();
            this._src = null;
            this._texture.image = this._image;
        }
    }

    /**
     * Gets the ````HTMLImageElement```` the ````ImagePlane````'s image is sourced from, if set.
     *
     * Returns null if not set.
     *
     * @type {HTMLImageElement}
     */
    get image() {
        return this._image;
    }

    /**
     * Sets an image file path that the ````ImagePlane````'s image is sourced from.
     *
     * Accepted file types are PNG and JPEG.
     *
     * Sets {@link Texture#image} null.
     *
     * @type {String}
     */
    set src(src) {
        this._src = src;
        if (this._src) {
            this._image = null;
            const image = new Image();
            image.onload = () => {
                this._texture.image = image;
                this._imageSize[0] = image.width;
                this._imageSize[1] = image.height;
                this._updatePlaneSizeFromImage();
            };
            image.src = this._src;
        }
    }

    /**
     * Gets the image file path that the ````ImagePlane````'s image is sourced from, if set.
     *
     * Returns null if not set.
     *
     * @type {String}
     */
    get src() {
        return this._src;
    }

    /**
     * Sets the World-space position of this ````ImagePlane````.
     *
     * Default value is ````[0, 0, 0]````.
     *
     * @param {Number[]} value New position.
     */
    set position(value) {
        this._pos.set(value || [0, 0, 0]);
        this.worldToRTCPos(this._pos, this._origin, this._rtcPos);
        this._node.origin = this._origin;
        this._node.position = this._rtcPos;
    }

    /**
     * Gets the World-space position of this ````ImagePlane````.
     *
     * Default value is ````[0, 0, 0]````.
     *
     * @returns {Number[]} Current position.
     */
    get position() {
        return this._pos;
    }

    /**
     * Sets the direction of this ````ImagePlane```` using Euler angles.
     *
     * Default value is ````[0, 0, 0]````.
     *
     * @param {Number[]} value Euler angles for ````X````, ````Y```` and ````Z```` axis rotations.
     */
    set rotation(value) {
        this._node.rotation = value;
    }

    /**
     * Gets the direction of this ````ImagePlane```` as Euler angles.
     *
     * @returns {Number[]} Euler angles for ````X````, ````Y```` and ````Z```` axis rotations.
     */
    get rotation() {
        return this._node.rotation;
    }

    /**
     * Sets the World-space size of the longest edge of the ````ImagePlane````.
     *
     * Note that ````ImagePlane```` sets its aspect ratio to match its image. If we set a value of ````1000````, and
     * the image has size ````400x300````, then the ````ImagePlane```` will then have size ````1000 x 750````.
     *
     * Default value is ````1.0````.
     *
     * @param {Number} size New World-space size of the ````ImagePlane````.
     */
    set size(size) {
        this._size = (size === undefined || size === null) ? 1.0 : size;
        if (this._image) {
            this._updatePlaneSizeFromImage();
        }
    }

    /**
     * Gets the World-space size of the longest edge of the ````ImagePlane````.
     *
     * Returns {Number} World-space size of the ````ImagePlane````.
     */
    get size() {
        return this._size;
    }

    /**
     * Sets the direction of this ````ImagePlane```` as a direction vector.
     *
     * Default value is ````[0, 0, -1]````.
     *
     * @param {Number[]} dir New direction vector.
     */
    set dir(dir) {

        this._dir.set(dir || [0, 0, -1]);

        if (dir) {

            const origin = this.scene.center;
            const negDir = [-this._dir[0], -this._dir[1], -this._dir[2]];

            math.subVec3(origin, this.position, tempVec3);

            const dist = -math.dotVec3(negDir, tempVec3);

            math.normalizeVec3(negDir);
            math.mulVec3Scalar(negDir, dist, tempVec3b);
            math.vec3PairToQuaternion(zeroVec, dir, tempQuat);

            this._node.quaternion = tempQuat;
        }
    }

    /**
     * Gets the direction of this ````ImagePlane```` as a direction vector.
     *
     * @returns {Number[]} value Current direction.
     */
    get dir() {
        return this._dir;
    }

    /**
     * Sets if this ````ImagePlane```` is included in boundary calculations.
     *
     * Default is ````true````.
     *
     * @type {Boolean}
     */
    set collidable(value) {
        this._node.collidable = (value !== false);
    }

    /**
     * Gets if this ````ImagePlane```` is included in boundary calculations.
     *
     * Default is ````true````.
     *
     * @type {Boolean}
     */
    get collidable() {
        return this._node.collidable;
    }

    /**
     * Sets if this ````ImagePlane```` is clippable.
     *
     * Clipping is done by the {@link SectionPlane}s in {@link Scene#sectionPlanes}.
     *
     * Default is ````true````.
     *
     * @type {Boolean}
     */
    set clippable(value) {
        this._node.clippable = (value !== false);
    }

    /**
     * Gets if this ````ImagePlane````  is clippable.
     *
     * Clipping is done by the {@link SectionPlane}s in {@link Scene#sectionPlanes}.
     *
     * Default is ````true````.
     *
     * @type {Boolean}
     */
    get clippable() {
        return this._node.clippable;
    }

    /**
     * Sets if this ````ImagePlane```` is pickable.
     *
     * Default is ````true````.
     *
     * @type {Boolean}
     */
    set pickable(value) {
        this._node.pickable = (value !== false);
    }

    /**
     * Gets if this ````ImagePlane````  is pickable.
     *
     * Default is ````true````.
     *
     * @type {Boolean}
     */
    get pickable() {
        return this._node.pickable;
    }

    /**
     * Sets the opacity factor for this ````ImagePlane````.
     *
     * This is a factor in range ````[0..1]```` which multiplies by the rendered fragment alphas.
     *
     * @type {Number}
     */
    set opacity(opacity) {
        this._node.opacity = opacity;
    }

    /**
     * Gets this ````ImagePlane````'s opacity factor.
     *
     * This is a factor in range ````[0..1]```` which multiplies by the rendered fragment alphas.
     *
     * @type {Number}
     */
    get opacity() {
        return this._node.opacity;
    }

    /**
     * @destroy
     */
    destroy() {
        super.destroy();
    }

    _updatePlaneSizeFromImage() {
        const size = this._size;
        const width = this._imageSize[0];
        const height = this._imageSize[1];
        if (width > height) {
            const aspect = height / width;
            this._node.scale = [size, 1.0, size * aspect];
        } else {
            const aspect = width / height;
            this._node.scale = [size * aspect, 1.0, size];
        }
    }

    worldToRTCPos(worldPos, rtcCenter, rtcPos) {

        const xHigh = Float32Array.from([worldPos[0]])[0];
        const xLow = worldPos[0] - xHigh;
    
        const yHigh = Float32Array.from([worldPos[1]])[0];
        const yLow = worldPos[1] - yHigh;
    
        const zHigh = Float32Array.from([worldPos[2]])[0];
        const zLow = worldPos[2] - zHigh;
    
        rtcCenter[0] = xHigh;
        rtcCenter[1] = yHigh;
        rtcCenter[2] = zHigh;
    
        rtcPos[0] = xLow;
        rtcPos[1] = yLow;
        rtcPos[2] = zLow;
    }
}

export {ImagePlane};
