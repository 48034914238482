<template lang="pug">
  .app-header.app-header-text
    div( v-if="$slots.menu || menu" test-id="panel-header-menu")
      slot( name="menu" )
        app-menu( :menu="menu" basic color="var(--v-primary-darken2)" ) 
    .app-header-title.text-truncate.text-uppercase( :class="$slots.menu || menu ? 'mx-2':''" ) {{ title }}

    .d-flex.align-center
      slot
      template(v-if="showClose")
        app-icon-button( icon="mdi-close" @click="handleClose" )
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  props: {
    title: String,
    menu: Array,
    showClose: Boolean,
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapActions('project', ['postPanelsStatus']),

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    },
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  height: 32px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding-bottom: 5px;
  color: var(--v-primary-darken2);
  &-icon {
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
    flex: 0 1 auto;
  }

  &-text {
    font: normal 12px/24px $roboto;
    vertical-align: top;
    flex: 1 1 auto;
  }
}

.app-header-title {
  color: var(--v-primary-darken2);
  flex: 1 1 auto;
}
</style>