<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" fill="transparent" />
    <path d="M20 17.8V7L28 13.5V24.3L20 17.8Z" stroke="currentColor" />
    <path d="M2 17.6V2L14 12.4V28L2 17.6Z" stroke="currentColor" />
    <path d="M8 16L16.5 16L24 16" stroke="currentColor" />
    <path d="M24 19V13" stroke="currentColor" />
    <path d="M8 19V13" stroke="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>