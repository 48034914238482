export const SmetaLinkType = Object.freeze({
  COMPOUND: "Compound", // Сторонний классификатор
  TASK: "Task", // Задача
  WORM_NODE: "WormNode" // ВОРМ
})

export function getPanelUnitByType(type) { 
  switch (type) {
    case SmetaLinkType.COMPOUND:
      return 'smeta'
    case SmetaLinkType.TASK:
      return 'task'
    case SmetaLinkType.WORM_NODE:
      return 'wormpanel'
    default:
      return 'empty'
  }
}

export class SmetaShareInfo { 
  constructor(json, classificatorTitle) {
    this.uuid = json?.uuid ?? null
    this.classificatorTitle = classificatorTitle
    this.title = json?.title ?? null
    this.projects = json?.projects ?? []
    this.isEmpty = !json
  }
}

export class SmetaLinkInfo { 
  constructor(json) {
    this.archived = json?.archived ?? false
    this.parent = json?.parent ?? null
    this.title = json?.title ?? null
    this.type = json?.type ?? null
    this.uuid = json?.uuid ?? null
    this.project = this.#_findProject()
  }

  #_findProject() {
    let parent = this.parent
    let project = null
    while (parent) {
      if (parent.type == "Project") project = parent
      parent = parent.parent
    }
    return project
  }

  get typeName() {
    if (this.type == "Task") return "Задача"
    if (this.type == "WormNode") return "ВОРМ"
    if (this.type == "Compound") return "Классификатор"
    return this.type
  }
}

export class SmetaLinkItem {
  constructor(json, smetaTreeItem) { 
    this.source = json?.source ?? null // UUID
    this.smetaTreeItem = smetaTreeItem ?? null
    this.links = json?.target?.map(link => new SmetaLinkInfo(link))
  }

  get isRule() {
    return this.smetaTreeItem.nodeType.name == "RULE"
  }
}

export class SmetaConnections { 
  constructor(json, classificatorTitle) {
    this.organization = new SmetaShareInfo(json?.shareInfo?.organization, classificatorTitle)
    this.links = json?.linkingInfo?.map(item => new SmetaLinkItem(item)) ?? []
  }
}