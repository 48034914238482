import { MeasurementLabel } from "./measurementLabel"
import { MeasurementSegment } from "./measurementSegment"
import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry"
import { math, Component } from "@xeokit/xeokit-sdk"
import { XeokitNode } from "@/plugins/xeokit/XeokitNode/XeokitNode"
import { MeasurementAxes } from "./measurementAxes"

const BASE_VEC = [0, 1, 0]
const AXES = [
  { type: "x", vec: [1, 0, 0] },
  { type: "y", vec: [0, 1, 0] },
  { type: "z", vec: [0, 0, 1] }
]

/*eslint-disable no-dupe-class-members*/
export class Measurement extends Component { 

  #createDate; #scene;
  #needAxes; #isAutoGenerated;
  #originWorldPos; #targetWorldPos; #vec;
  #color; #position; #quaternion; #scale; #matrix; #origin;
  #measurementNode; #measurementLabel; #measurementSegment; #measurementAxes;
  #numberSettings; #mode; #visible; #billboard;
  #length;

  /**
   * Отображаемое на сцене измерение в виде отрезка с величиной.
   * 
   * @param {MeasurementsPlugin} plugin Плагин измерений {@link MeasurementsPlugin}
   * @param {Object} cfg Конфиг.
   * @param {String} cfg.id Идентификатор измерения.
   * @param {Scene} cfg.scene Сцена.
   * @param {Boolean} cfg.needAxes Нужно ли отрисовать координатные оси (актуально для рулетки).
   * @param {Boolean} cfg.isAutoGenerated Сгенерирован ли автоматически (через шифт).
   * @param {Number[]} cfg.originWorldPos Начальная точка отрезка измерения. Формат [x, y, z].
   * @param {Number[]} cfg.targetWorldPos Конечная точка отрезка измерения. Формат [x, y, z].
   * @param {Object} cfg.numberSettings Единицы измерения и количество знаков после запятой.
   * @param {Date} cfg.createDate Момент создания измерения.
   * @param {String} cfg.mode Режим линейки.
   * @param {Boolean} cfg.visible Отобразить / скрыть при создании.
   * @param {String} cfg.billboard Режим отображения текста - "spherical" или "none".
   */
  constructor(plugin, cfg = {}) {
    super(plugin.viewer.scene, cfg);

    this.plugin = plugin;
    this.id = cfg.id;

    this.#scene = cfg.scene

    this.#measurementNode = null
    this.#measurementLabel = null
    this.#measurementSegment = null
    this.#measurementAxes = null

    this.#needAxes = cfg.needAxes ?? false
    this.#isAutoGenerated = cfg.isAutoGenerated
    
    this.#originWorldPos = new Float64Array(cfg.originWorldPos)
    this.#targetWorldPos = new Float64Array(cfg.targetWorldPos)

    this.#vec = null

    this.#color = null
    this.#position = null
    this.#quaternion = null
    this.#scale = null
    this.#origin = null
    this.#matrix = math.mat4()

    this.#numberSettings = cfg.numberSettings
    this.#createDate = cfg.createDate
    this.#mode = cfg.mode
    this.#visible = cfg.visible ?? true
    this.#billboard = cfg.billboard ?? 'none'

    this.#length = 0
    
    this.#initStates()
    this.#createMeasurement()
  }

  set length(value) { this.#length = value }
  set visible(value) { this.#visible = value }

  get mode() { return this.#mode }
  get createDate() { return this.#createDate }
  get isAutoGenerated() { return this.#isAutoGenerated }

  get length() { return this.#length }
  get xLength() { return Math.abs(this.#targetWorldPos[0] - this.#originWorldPos[0]) }
  get yLength() { return Math.abs(this.#targetWorldPos[1] - this.#originWorldPos[1]) }
  get zLength() { return Math.abs(this.#targetWorldPos[2] - this.#originWorldPos[2]) }

  get originWorldPos() { return this.#originWorldPos }
  get targetWorldPos() { return this.#targetWorldPos }
  get visible() { return this.#visible }

  /**
   * @private Создать {@link XeokitNode} измерения и задать первичные параметры трансформации в пространстве.
   */
  #initStates() {
    const p0 = this.originWorldPos
    const p1 = this.targetWorldPos
    this.#origin = p0

    this.#measurementNode = new XeokitNode(this.#scene, {
      isNode: true,
      selected: this.#billboard == 'none' ? true : false,
      receivesShadow: false,
      castsShadow: false,
      clippable: false,
      collidable: false,
      pickable: false,
      origin: this.#origin
    })
    // 1, 1, 0
    // 1, 0, 1
    // 0, 1, 1
    this.#color = [1.0, 0.0, 1.0]
    
    const distance = geometry.math.distance(p0, p1)
    this.#length = distance

    const denormMeasurementVec = geometry.math.subVec3(p1, p0)
    const measurementVec = geometry.math.normalizeVec3(denormMeasurementVec)
    const halfDistanceVec = geometry.math.mulVec3Scalar(measurementVec, distance / 2)

    this.#vec = measurementVec
    this.#position = halfDistanceVec
    this.#quaternion = geometry.math.quaternionByTwoVectors(BASE_VEC, measurementVec)
    this.#scale = [distance, distance, distance]

    if (this.#billboard == 'spherical') {
      this.#scale = [10, 10, 10]
      this.#position = p0
      this.#quaternion = [0, 0, 0, 1]
    }

    const transform = {
      position: this.#position,
      quaternion: this.#quaternion,
      scale: this.#scale
    }
    this.setTransform(transform)
  }

  /**
   * @private Создать {@link MeasurementLabel} и {@link MeasurementSegment} как дочерние объекты узла this.#measurementNode
   */
  #createMeasurement() {
    if (this.#billboard == 'none') this.#measurementSegment = new MeasurementSegment(this.#measurementNode, {
      visible: this.#visible,
      color: this.#color,
    })

    this.#measurementLabel = new MeasurementLabel(this.#measurementNode, {
      length: this.#length,
      units: this.#numberSettings.units.title,
      decimalPlaces: this.#numberSettings.decimalPlaces,
      color: this.#color,
      billboard: this.#billboard,
    })

    if (this.#needAxes) {
      this.#measurementAxes = new MeasurementAxes(this.#scene, {
        decimalPlaces: this.#numberSettings.decimalPlaces,
        units: this.#numberSettings.units.title,
        axesLengths: [this.xLength, this.yLength, this.zLength],
        origin: this.#origin
      })

      for (const axis of AXES) {
        if (geometry.math.areVecsCollinear(this.#vec, axis.vec)) this.#measurementAxes.setAxisVisible(axis.type, false)
      }
    }
  }

  /**
   * @public Создать объект трансформации по указанным точкам.
   * 
   * @param {Number[]} point0 
   * @param {Number[]} point1 
   * 
   * @returns {Object} transform
   */
  createTransformByTwoPoints(point0, point1) {
    const p0 = point0
    const p1 = point1

    const distance = geometry.math.distance(p0, p1)

    const denormMeasurementVec = geometry.math.subVec3(p1, p0)
    const measurementVec = geometry.math.normalizeVec3(denormMeasurementVec)
    const halfDistanceVec = geometry.math.mulVec3Scalar(measurementVec, distance / 2)

    const quaternion = geometry.math.quaternionByTwoVectors(BASE_VEC, measurementVec)
    const scale = [distance, distance, distance]
    const position = halfDistanceVec

    return {
      quaternion: quaternion,
      position: position,
      scale: scale,
    }
  }

  /**
   * @public Установить матрицу преобразования через отдельные компоненты матрицы.
   * 
   * @param {Object} transform Параметры трансформации
   * @param {Number[]} transform.position Новая позиция в формате [x, y, z]
   * @param {Number[]} transform.quaternion Новый поворот в формате [i, j, k, w]
   * @param {Number[]} transform.scale Новый масштаб в формате [x, y, z]
   */
  setTransform(transform) {
    const position = transform.position ?? [0, 0, 0]
    const quaternion = transform.quaternion ?? [0, 0, 0, 1]
    const scale = transform.scale ?? [1, 1, 1]
    this.#position = position
    this.#scale = scale
    this.#quaternion = quaternion

    this.#matrix = math.composeMat4(position, quaternion, scale)
    this._needUpdate()
  }

  /**
   * @public Установить настройки измерения.
   * 
   * @param {String} units 
   * @param {Number} decimalPlaces 
   */
  setMeasurementSettings(units, decimalPlaces) {
    this.#numberSettings.units.title = units
    this.#numberSettings.decimalPlaces = decimalPlaces
    this.#measurementLabel.setMeasurementSettings(units, decimalPlaces, true)
    if (this.#needAxes) {
      this.#measurementAxes.setMeasurementSettings(units, decimalPlaces, true)
    }
  }

  /**
   * @public Установить отображение.
   * 
   * @param {Boolean} value 
   */
  setVisible(value) {
    this.#visible = value
    this.#measurementNode.visible = this.#visible
    if (this.#measurementAxes) { this.#measurementAxes.setVisible(this.#visible) }
  }

  /**
   * @public Отобразить вместо величины измерения текстовый заполнитель (актуально для рулетки).
   */
  showLabelPlaceholder() {
    this.#measurementLabel.showPlaceholder()
    if (this.#needAxes) {
      this.#measurementAxes.showPlaceholder()
    }
  }

  /**
   * @public Отобразить величину измерения.
   */
  showLabelLength() {
    this.#measurementLabel.showLength()
    if (this.#needAxes) {
      this.#measurementAxes.showLength()
    }
  }

  /**
   * @public Устанавливить конечную точку измерения, пересчитать длины.
   */
  setTarget(targetWorldPos) {
    this.#targetWorldPos = new Float64Array(targetWorldPos)
    const p0 = this.originWorldPos
    const p1 = this.targetWorldPos
    const distance = geometry.math.distance(p0, p1)
    this.#vec = geometry.math.subVec3(p1, p0)
    this.length = distance

    if (this.#needAxes) {
      for (const axis of AXES) {
        if (geometry.math.areVecsCollinear(this.#vec, axis.vec)) this.#measurementAxes.setAxisVisible(axis.type, false)
        else this.#measurementAxes.setAxisVisible(axis.type, true)
      }
    }

    this.#measurementLabel.setLength(this.#length)
  }

  /**
   * @protected Метод класса {@link Component}
   */
  _update() {
    if (this.#needAxes) {
      this.#measurementAxes.update({
        p0: this.originWorldPos,
        p1: this.targetWorldPos,
        xLength: this.xLength,
        yLength: this.yLength,
        zLength: this.zLength,
        position: this.#position
      })
    }
    this.#measurementNode.matrix = this.#matrix
  }

  /**
   * @public Удаление данного измерения.
   */
  destroy() {
    this.#measurementNode.destroy()
    this.#measurementNode = null
    if (this.#needAxes) {
      this.#measurementAxes.destroy()
      this.#measurementNode = null
    }

    try { super.destroy() } 
    catch (error) { error; console.log("|| BAD MEASUREMENT DESTROY ||") }
  }
}