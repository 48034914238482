import { DirLight, AmbientLight } from '@xeokit/xeokit-sdk'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { LightSource } from './lightSource'

const DEFAULT_LIGHTS = [ // Список необязательно, но желательно должен совпадать с дефолтными значениями на бэке
  {
    type: "AmbientLight",
    color: [1.0, 1.0, 1.0],
    dir: [null, null, null],
    intensity: 0.7,
    space: "world"
  },
  {
    type: "DirLight",
    dir: [0.8, -.5, -0.5],
    color: [0.67, 0.67, 1.0],
    intensity: 0.7,
    space: "world"
  },
  {
    type: "DirLight",
    dir: [-0.8, -1.0, 0.5],
    color: [1, 1, .9],
    intensity: 0.9,
    space: "world"
  },
  {
    type: "DirLight",
    dir: [0, 1, 0],
    color: [0.8, 0.8, 1.0],
    intensity: 0.7,
    space: "world"
  }
]

/*eslint-disable no-dupe-class-members*/
export class BimLights  {

  static #_lightSourceList = []

  static get #_viewer() { return XeokitMediator.viewer }
  static get #_scene() { return XeokitMediator.viewer.scene }
  static get #_lights() { return XeokitMediator.viewer.scene.lights }
  static get #_lightSettings() { return XeokitMediator.BimLights.lightSettings }

  /**
   * Создание освещения из {@link LightSettings}.
   * Весь существующий свет на {@link Scene} будет удален.
   */
  static loadLights() {
    this.#_scene.clearLights();
    this.#_clearLightSources();

    if (this.#_lightSettings == 0) {
      this.#_createDefaultLights()
    }
    else {
      this.#_createLightsFromSettings()
    }
  }

  /**
   * Установить цвет источнику света.
   * 
   * @param {Object} light 
   * @param {Number[]} color 
   */
  static setColorLight(light, color) {
    const id = light.id.replace('dirLight', 'node')
    const rgb = [color.r, color.g, color.b].map(value => (value / 255).toFixed(2))
    this.#_lightSourceList.forEach(lightSource => { 
      if (lightSource.uuid === id) lightSource.setColor(rgb)
    })

    light.color = rgb
    this.updateSetting(light)
  }

  /**
   * Добавить направленный источник света {@link DirLight}.
   * Также добавляет четыре полупрозрачные сферы, имитирующие источник света {@link LightSource}.
   * 
   * @param {Number[]} position 
   */
  static addDirLight(position = null) {
    const pos = position ?? this.#_viewer.camera.eye
    const viewMat = this.#_viewer.camera.viewMatrix
    const dirVec = [-viewMat[2], -viewMat[6], -viewMat[10]]
    const newLightSettings = this.#_lightSettings

    const dirLight = this.#_createDirLight(dirVec, pos)
    const newSetting = this.#_createLightSetting(dirLight)
    newLightSettings.push(newSetting)
    XeokitMediator.BimLights.setLightSettings(newLightSettings)
  }

  /**
   * Удаляет указанный источник света. 
   * Удаление происходит как на {@link Scene}, так и в настроках {@link LightSettings}.
   * 
   * @param {Object} light 
   */
  static destroyDirLight(light) {
    const lightUuid = light.id
    const nodeUuid = lightUuid.replace('dirLight', 'node')

    this.#_lightSourceList = this.#_lightSourceList.filter(lightSource => {
      if (lightSource.uuid == nodeUuid) {
        lightSource.destroy()
        this.#_lights[lightUuid]?.destroy()
      }
      else return lightSource
    })

    XeokitMediator.BimLights.deleteLightSettingById(lightUuid)
  }

  /**
   * Устанавливает новую {@link LightSetting} для указанного источника света.
   * 
   * @param {Object} light 
   */
  static updateSetting(light) {
    const lightUuid = light.id
    let newLightSettings = null

    if (lightUuid in this.#_lights) {
      this.#_lights[lightUuid].intensity = light.intensity
      this.#_lights[lightUuid].color = light.color
    }

    if (this.#_lightSettings.find(setting => setting.id == lightUuid)) {
      newLightSettings = this.#_lightSettings.map(setting => {
        if (setting.id == lightUuid) return light
        return setting
      })
    }
    else newLightSettings.push(light)

    XeokitMediator.BimLights.setLightSettings(newLightSettings)
  }

  /**
   * @private 
   */
  static #_addLight(scene, light) {
    if (light.type == "DirLight") new DirLight(scene, light)
    if (light.type == "AmbientLight") new AmbientLight(scene, light)
  }

  /**
   * @private 
   */
  static #_addLightWithLightSource(scene, light) {
    const dir = light.dir 
    const position = [-dir[0], -dir[1], -dir[2]]
    const color = light.color
    const id = light.id.replace('dirLight', 'node')

    const lightSource = new LightSource({
      uuid: id,
      scene: scene,
      position: position,
      color: color
    })
    new DirLight(scene, light)
    this.#_lightSourceList.push(lightSource)
  }

  /**
   * @private 
   */
  // Необходим для обновления настроек освещения по всем проектам после 25.07.2024 - решает проблему слепой зоны освещения.
  static #_addThirdDefaultDirLight(scene) {
    const newLightSettings = this.#_lightSettings
    const newLight = DEFAULT_LIGHTS[3]

    newLight.id = "__" + newLight.type + "-" + this.#_genUUID()
    new DirLight(scene, newLight)
    newLightSettings.push(newLight)

    XeokitMediator.BimLights.setLightSettings(newLightSettings)
  }

  /**
   * @private 
   */
  static #_createDefaultLights() {
    const newLightSettings = []
    for (const light of DEFAULT_LIGHTS) {
      light.id = "__" + light.type + "-" + this.#_genUUID()
      this.#_addLight(this.#_scene, light)
      newLightSettings.push(light)
    }
    XeokitMediator.BimLights.setLightSettings(newLightSettings) // Запись на бэк
  }

  /**
   * @private 
   */
  static #_createLightsFromSettings() {
    let defaultLightsCount = 0

    const AMBIENT_LIGHT = this.#_lightSettings.find(light => light.type === 'AmbientLight')
    this.#_addLight(this.#_scene, AMBIENT_LIGHT)

    const lightsettings = this.#_lightSettings.filter(light => light !== AMBIENT_LIGHT)
    for (const light of lightsettings) {
      if (light.id.startsWith("__")) { // Создание дефолтных источников света
        defaultLightsCount++
        this.#_addLight(this.#_scene, light)
      }
      else {                           // Создание кастомных источников света
        this.#_addLightWithLightSource(this.#_scene, light)
      }
    }

    // REWORK Если не хватает источников света - обновить настройки, добавив 4 источник. TODO Если все проекты обновлены, можно убирать
    if (defaultLightsCount == 3) {
      this.#_addThirdDefaultDirLight(this.#_scene)
    }
  }

  /**
   * @private
   */
  static #_clearLightSources() {
    this.#_lightSourceList.forEach(lightSource => lightSource.destroy())
  }

  /**
   * @private 
   */
  static #_createDirLight(dir, position) {
    const uuid = this.#_genUUID()
    const lightUuid = `dirLight-${uuid}`
    const nodeUuid = `node-${uuid}`

    const light = {
      id: lightUuid,  
      dir: dir,
      color: [1, 1, 1],
      intensity: 1,
      space: "world",
    }

    // TODO заменить генерацию Uuid на методы защищенного контекста (crypto.randomUuid())
    if (this.#_lights[lightUuid]) {
      return this.#_lights[lightUuid]
    }

    const lightSource = new LightSource({
      scene: this.#_scene,
      position: position,
      uuid: nodeUuid,
    })

    this.#_lightSourceList.push(lightSource)

    return new DirLight(this.#_scene, light)
  }

  /**
   * @private 
   */
  static #_createLightSetting (dirLight) {
    let {id, intensity, type, space, color, dir} = dirLight

    let [r, g, b] = color
    let [x, y, z] = dir ?? []

    return {
      id, 
      type, 
      space: space ?? "world",
      intensity: +intensity, 
      color: [r, g, b] ?? [], 
      dir: [x, y, z] ?? [],
    }
  }

  /**
   * @private 
   */
  static #_genUUID () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
}