import DrawerLayout from '@/layouts/Drawer.vue'

const adminRoutes = {
  path: '/admin',
  redirect: '/admin/users',
  component: DrawerLayout,
  children: [
    {
      path: 'process',
      component: () => import('@/pages/admin/process/Process.vue')
    },
    {
      path: 'roles',
      component: () => import('@/pages/admin/ListRoles.vue')
    },
    {
      path: 'users',
      component: () => import('@/pages/admin/ListUsers.vue')
    },
    {
      path: 'projects',
      component: () => import('@/pages/admin/ListProjects.vue')
    },
    {
      path: 'bclassificator/:classificatorId/classes',
      component: () => import('@/pages/admin/classification/ListClasses.vue'),
      props: true
    },
    {
      path: 'devicereestr',
      component: () => import('@/pages/admin/iot/ListDeviceReestr.vue'),
    },
    {
      path: 'devicereestr/:deviceReestrId/deviceparameters',
      component: () => import('@/pages/admin/iot/ListDeviceParam.vue'),
      props: true
    },
    {
      path: 'measureunits',
      component: () => import('@/pages/admin/MeasureUnits.vue')
    },
    {
      path: 'languages',
      component: () => import('@/pages/admin/Languages.vue')
    },
    {
      path: 'dictionary',
      component: () => import('@/pages/admin/dictionary/Dictionary.vue')
    },
    {
      path: 'dictionary/:dictionaryId/word',
      component: () => import('@/pages/admin/dictionary/Word.vue'),
      props: true
    },
    {
      path: 'forms/:formId',
      component: () => import('@/pages/admin/forms/Form.vue'),
      props: true
    },
    {
      path: 'orgs',
      component: () => import('@/pages/admin/corp/Orgs.vue'),
      props: true
    },
    {
      path: 'plugins',
      component: () => import('@/pages/admin/Plugins.vue'),
    },
    {
      path: 'smetadictionary',
      component: () => import('@/pages/admin/smetadictionary/SmetaDictionary.vue')
    },
  ]
}

export default adminRoutes
export { adminRoutes }