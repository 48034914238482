<template lang="pug">
v-main.surface.lighten-4(v-if="isAdmin()")
  v-navigation-drawer.surface.base(app expand-on-hover permanent dark ref="nav" :width="280" mini-variant-width="80" color="#959595" @transitionend="updateState")
    template(v-slot:prepend)
      v-layout.logo(column align-center pt-7 pb-2 space-between)
        app-appear-animation
          .mt-1.logo__text(v-if="!mini") BIMIT
      
    .drawer.py-0(test-id="drawer-panel")
      drawer-item(v-for="(el, index) in menuItems" :key="index" :item="el" :mini="mini" :id="index" test-id="drawer=item")

  router-view
  app-dialog-loading(v-model="loading")
</template>

<script>
import { DrawerItems as items } from '@/assets/data/DrawerItems'
import DrawerItem from '@/components/layout/DrawerItem'

export default {
  components: {
    [DrawerItem.name]: DrawerItem
  },

  data () {
    return {
      mini: true,
      loading:false,
    }
  },

  computed: {
    menuItems () {
      return items
    },
  },

  mounted () {
    this.$router.beforeEach((to, from, next) => {
      this.loading = true,
      next()
    });
    this.$router.afterEach(() => {
      this.loading = false
    })
  },

  methods: {
    updateState () {
      this.mini = !this.$refs.nav.isMouseover
    },

    isAdmin () {
      if (localStorage.getItem("roles") === "ADMIN") 
        return true 
      this.$router.push({ path: `/dashboard`})
    }
  }
}
</script>

<style lang="scss" scoped>
$logo-height: 100px;

.logo {
  height: $logo-height;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  
  &__text {
    font: normal 12px $cond;
    // color: #C4C4C4;
    color: var(--v-primary-darken2);

    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
  }
}

.drawer {
  height: calc(100vh - #{$logo-height});
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}
.drawer--bg {
  background-color: #959595;
  &:hover {
    background: #19AFDE;
  }
}
.drawer-panel--active {
  background-color: rgba(196, 196, 196, 0.5);
}
.drawer-panel__header {
  color: white;
}
</style>
