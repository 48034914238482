import { defineStore } from 'pinia'

export const useImagePlaneStore = defineStore('imagePlane', {
  state: () => {
    return {
      isWaitImagePlanePick: false,
      imageSrc: "",
      imagePlaneTitle: "",
      controlImagePlaneEnabled: false,

      controlImagePlaneSize: 100,
      controlImagePlaneCoordZ: 0,

      selectedImagePlaneUuid: null,

      createdImageSize: 100,

      isDialogDrawerCropperShowed: false,

      imagePlanesShort: [],

      coordChangerTemp: false
    }
  },

  actions: {
    setWaitImagePlanePick(status) {
      this.isWaitImagePlanePick = status
    },

    setImageSrc(imageSrc) {
      this.imageSrc = imageSrc
    },

    setImagePlaneTitle(title) {
      this.imagePlaneTitle = title
    },

    setCreatedImageSize(size) {
      this.createdImageSize = size
    },

    setSelectedImagePlaneUuid(uuid) {
      this.selectedImagePlaneUuid = uuid
    },

    setControlImagePlaneSize(size) {
      this.controlImagePlaneSize = size
    },

    setControlImagePlaneEnabled(enable) {
      this.controlImagePlaneEnabled = enable
    },

    setDialogDrawerCropperShowed(dialogDrawerCropperShowed) {
      this.isDialogDrawerCropperShowed = dialogDrawerCropperShowed
    },

    setControlImagePlaneCoordZ(coord) {
      this.controlImagePlaneCoordZ = coord
    },

    setCoordChangerTemp(value) {
      this.coordChangerTemp = value
    },

    setImagePlanesShort(imagePlanesShort) {
      this.imagePlanesShort = imagePlanesShort
    }

  },
})