import { ThreeMediator } from '../threeJs/ThreeMediator'
import { fonts } from '../threeJs/plugins/fonts/fonts'
import { geometry } from '../xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '../xeokit/XeokitMediator'
import store from '@/store'

/*eslint-disable no-dupe-class-members*/
export class IFCGridsDrawer {
  static #_drawedMeshes = []
  static #_drawedLabels = []
  static #_color = null
  static #_font = null

  static drawIFCGrids(gridData) {

    this.#_color = this.getContrastBlackWhiteColor()
    this.createFont()

    for (let i = 0; i < gridData.length; i++) {
      this.drawIFCGrid(gridData[i].grids, gridData[i].diagonalLength)
    }
    store.dispatch('IFCGrids/saveEdgeMeshes', this.#_drawedMeshes)
    store.dispatch('IFCGrids/saveLabelMeshes', this.#_drawedLabels)
    this.#_drawedMeshes = []
    this.#_drawedLabels = []

  }

  static drawIFCGrid(grid, diagLength) {
    let edges = []
    let labels = []
    let locations = []
    for (let i = 0; i < grid.length; i++) {
      edges.push(...this.#_getEdgesFromGrid(grid[i]))
      labels.push(...this.#_getLabelsFromGrid(grid[i]))
      locations.push(...this.#_getLocationsFromGrid(grid[i]))
    }
    this.#_drawEdges(edges)
    this.#_drawLabels(labels, locations, diagLength)
  }

  static #_getLabelsFromGrid(grid) {
    let uvwArray = []
    uvwArray.push(...grid.u)
    uvwArray.push(...grid.v)
    uvwArray.push(...grid.w)

    let labels = []

    for (let i = 0; i < uvwArray.length; i++) {
      labels.push(uvwArray[i].label)
    }
    return labels
  }

  static #_getLocationsFromGrid(grid) {
    let uvwArray = []
    uvwArray.push(...grid.u)
    uvwArray.push(...grid.v)
    uvwArray.push(...grid.w)
    let matrix = grid.matrix
    let locations = []

    for (let i = 0; i < uvwArray.length; i++) {
      let location1 = geometry.math.mulMat4v3(matrix, [uvwArray[i].location1[0], uvwArray[i].location1[1], uvwArray[i].location1[2]])
      let location2 = geometry.math.mulMat4v3(matrix, [uvwArray[i].location2[0], uvwArray[i].location2[1], uvwArray[i].location2[2]])

      locations.push([
        [location1[0], location1[1], location1[2]],
        [location2[0], location2[1], location2[2]],
      ])
    }
    return locations
  }

  static #_drawLabels(labels, locations, diagLength ) {
    // let transparent = store.state.IFCGrids.transparency
    let transparent = false

    let labelSize = 0
    if (diagLength == 1) labelSize = diagLength 
    else labelSize = diagLength / 80

    for (let i = 0; i < labels.length; i++) {
      for (let j = 0; j < labels[i].length; j++) {
        for (let k = 0; k < locations[j].length; k++) {
          
          let labelMesh = ThreeMediator.SceneObjects.createFontText({
            billboard: 'spherical',
            text: labels[i],
            font: this.#_font,
            position: locations[i][k],
            scale: labelSize,
            color: this.#_color,
            selected: transparent
          })

          this.#_drawedLabels.push(labelMesh)
        }
      }
    }
  }

  static #_getEdgesFromGrid(grid) {
    let uvwArray = []
    let matrix = grid.matrix
    uvwArray.push(...grid.u)
    uvwArray.push(...grid.v)
    uvwArray.push(...grid.w)
    let edges = []

    for (let i = 0; i < uvwArray.length; i++) {
      let currentCurve = uvwArray[i].curve
      let points = []
      for (let j = 0; j < currentCurve.vertexes.length; j += 3) {
        let point = geometry.math.mulMat4v3(matrix, [currentCurve.vertexes[j], currentCurve.vertexes[j + 1], currentCurve.vertexes[j + 2]])
        points.push([point[0], point[1], point[2]])
      }
      for (let k = 0; k < points.length - 1; k++) {
        edges.push([points[k], points[k + 1]])
      }
    }
    return edges
  }

  static #_drawEdges(edges) {
    let transparent = store.state.IFCGrids.transparency

    edges.forEach((edge) => {
      let edgeMesh = XeokitMediator.SceneObjects.createDashDottedSegmentMesh({
        segmentPositions: edge,
        segmentColor: this.#_color, //[0.23137254901960785, 0.5764705882352941, 0.6862745098039216],
        unitNumber: 10,
        selected: transparent
      })
      this.#_drawedMeshes.push(edgeMesh)
    })
  }

  static areGridsNotExists(gridData) {
    if (gridData == 0) return true
    for (let i = 0; i < gridData.length; i++) {
      if (gridData[i].grids.length != 0) {
        return false
      }
    }

    return true
  }

  static getContrastBlackWhiteColor() {
    let measurementScrim = document.getElementById("measurementScrim")
    let hexcolor = measurementScrim.style.backgroundColor
    let temp1 = hexcolor.substring(4)
    let temp2 = temp1.substring(0, temp1.length - 1)
    let colorArr = temp2.split(',')
    let r = parseInt(colorArr[0]);
    let g = parseInt(colorArr[1]);
    let b = parseInt(colorArr[2]);
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    return (yiq >= 128) ? [0.4, 0.4, 0.4] : [1.5, 1.5, 1.5];
  }

  static createFont() {
    this.#_font = ThreeMediator.fontLoader.parse(fonts.RobotoMediumRegular)
  }

  static clearDrawedMeshes() { store.dispatch('IFCGrids/deleteEdgeMeshes') }
  static clearDrawedLabels() { store.dispatch('IFCGrids/deleteLabelMeshes') }

  static redrawGrids(gridData) {
    this.clearGrids()
    this.drawIFCGrids(gridData)
  }

  static clearGrids() {
    this.clearDrawedLabels()
    this.clearDrawedMeshes()
  }
}
