import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { VertexPin } from '../../distanceMeasurement/hoveringPins/vertexPin'

const MOUSE_CANVAS_CLICK_TOLERANCE = 5
const DISTANCE_COEFF = 0.05

/*eslint-disable no-dupe-class-members*/
export class VertexToVertexRegime {
  static #firstPoint = null
  static #originPickedVertexPin = null

  static #vertexPin = null

  static #onMouseHoverSurface = null
  static #onInputMouseUp = null
  static #onInputMouseDown = null
  static #keyUpListenerId = null

  static #mouseDownCanvasX
  static #mouseDownCanvasY
  static #mouseDownLeft = false
  static #mouseDownRight = false

  /**
   * Включить режим измерения между вершинами.
   */
  static activate() {
    this.#activateInputListeners()
    this.#activateHoverListener()
  }

  /**
   * Отключить режим измерения между вершинами.
   */
  static deactivate() {
    const input = XeokitMediator.viewer.scene.input
    const cameraControl = XeokitMediator.viewer.cameraControl

    input.off(this.#onInputMouseDown)
    input.off(this.#onInputMouseUp)
    input.off(this.#keyUpListenerId)
    cameraControl.off(this.#onMouseHoverSurface)
    
    this.#firstPoint = null

    this.#vertexPin?.destroy()
    this.#vertexPin = null

    this.#originPickedVertexPin?.destroy()
    this.#originPickedVertexPin = null
  }

  static #pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedWorldPos = pickResult._worldPos

    if (pickResult.isSectionControl) return
    if (pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')) return

    let edges = []
    let nearestCorner = pickedWorldPos

    pickedEntity.meshes.forEach((mesh) => {
      if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
    })
    nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickedWorldPos, edges)

    if (!this.#firstPoint) {
      this.#firstPoint = nearestCorner 
      const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, this.#firstPoint) * DISTANCE_COEFF
      this.#originPickedVertexPin = new VertexPin(XeokitMediator.viewer.scene)
      this.#originPickedVertexPin.update({
        position: this.#firstPoint,
        scale: [scale, scale, scale]
      })
    } 
    else {
      XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
        originWorldPos: this.#firstPoint, 
        targetWorldPos: nearestCorner,
        billboard: geometry.utils.arePointsEqual(this.#firstPoint, nearestCorner, 0.00001) ? 'spherical' : 'none'
      })
    }
  }

  static #activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#onInputMouseDown)
    input.off(this.#onInputMouseUp)

    this.#onInputMouseDown = input.on('mousedown', (coords) => {
      this.#mouseDownCanvasX = coords[0]
      this.#mouseDownCanvasY = coords[1]
      this.#mouseDownLeft = input.mouseDownLeft
      this.#mouseDownRight = input.mouseDownRight
    })

    this.#onInputMouseUp = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.#mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.#mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.#mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.#mouseDownLeft = false
        this.#mouseDownRight = false
        return
      }

      if (this.#mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
        })

        if (pickResult) this.#pick(pickResult)
      }

      this.#mouseDownLeft = false
      this.#mouseDownRight = false
    })

    this.#keyUpListenerId = input.on("keyup", (code) => {
      if (code != 78) return

      this.deactivate()
      this.activate()
    })
  }

  static #activateHoverListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    this.#vertexPin = new VertexPin( XeokitMediator.viewer.scene, { visible: false } )

    let lastNearestCorner = null
    let nearestCorner = null

    this.#onMouseHoverSurface = cameraControl.on('hover', (event) => {
      if (this.#mouseDownLeft || this.#mouseDownRight) {
        this.#vertexPin.setVisible(false)
        return
      }

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
      })

      if (!pickResult) return
      if (pickResult.isSectionControl) return
      if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) return

      let edges = []
      pickResult.entity.meshes.forEach((mesh) => {
        if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
      })

      nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickResult.worldPos, edges)
      if (!lastNearestCorner) lastNearestCorner = nearestCorner
      if (geometry.utils.arePointsClose(lastNearestCorner, nearestCorner)) return

      const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, nearestCorner) * DISTANCE_COEFF
      this.#vertexPin.update({
        position: nearestCorner,
        scale: [scale, scale, scale]
      })
      this.#vertexPin.setVisible(true)
    })
  }
}
