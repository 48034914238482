import { api } from '@/api'
import { defineStore } from 'pinia'
import vuexStore from '@/store'
import { SmetaLinkItem, SmetaShareInfo, getPanelUnitByType } from './SmetaConnections.model'
import { getSmetaCacheItem } from '@/store/smeta/smeta.module'
import { SmetaPanelType } from '@/components/project/panel/left/smeta/components/utils/SmetaPanelType'

export const useSmetaConnectionsStore = defineStore('smeta.connections', {
  state: () => ({
    organizationInfo: new SmetaShareInfo(),
    smetaLinks: {},
    selectedNode: null,
    filters: {
      onlyLinks: false,
      withClassificator: false,
      withWorm: false,
      withTask: false
    }
  }),

  getters: {
    smetaLinksByParent: (state) => { 
      if (state.selectedNode) return state.smetaLinks[state.selectedNode]
      return []
    }
  },

  actions: {
    async openPanelUnit(unit = 'empty', link) {
      if (unit == 'wormpanel') {
        const projectWormUuid = findWormProjectUuid(link)
        vuexStore.commit('project/setProjectSettingsProjectWorm', projectWormUuid)
      }
      if (unit == 'smeta') {
        const projectClassificatorUuid = findProjectClassificatorUuid(link)
        vuexStore.commit('project/setProjectSettingsProjectClassificator', projectClassificatorUuid)
        vuexStore.commit('smeta/setSmetaPanelType', SmetaPanelType.CLASSIFICATOR)
        vuexStore.commit('smeta/setScrollTo', link.uuid)
      }
      vuexStore.commit('project/setActiveGlobalTab', unit)
    },

    async openTopPanelUnit(unit = null, link) {
      const workspaceUuid = findTaskWorkspace(link)
      await vuexStore.dispatch('workspace/changeWorkSpace', workspaceUuid)
      vuexStore.commit('task/setSelectedTaskByUuid', link.uuid)
      vuexStore.commit('project/setTopUnit', unit)
    },

    openUnit(link) {
      const unit = getPanelUnitByType(link.type)
      if (unit == 'task') {
        this.openTopPanelUnit(unit, link)
      } else {
        this.openPanelUnit(unit, link)
      } 
    },

    fetchSmetaConnections() {
      const uuid = vuexStore.state.smeta.projectClassificatorUuid // Текущий выбранный классификатор
      const classificatorTitle = vuexStore.state.smeta.projectClassificators.find(item => item.uuid == uuid).title
      this.organizationInfo = new SmetaShareInfo()
      this.smetaLinks = {}
      
      api.smeta.showConnections(uuid).then(data => {
        this.organizationInfo = new SmetaShareInfo(data?.shareInfo?.organization, classificatorTitle)    
        data?.linkingInfo?.forEach(item => {
          const cacheItem = getSmetaCacheItem(item.source)
          if (cacheItem.nodeType.name == "NODE" || cacheItem?.hasRules) {
            if (!this.smetaLinks[item.source]) this.smetaLinks[item.source] = []
            this.smetaLinks[item.source].push(new SmetaLinkItem(item, cacheItem))
          }
          if (cacheItem.nodeType.name == "RULE") {
            const parentUuid = cacheItem.parent.uuid
            if (!this.smetaLinks[parentUuid]) this.smetaLinks[parentUuid] = []
            this.smetaLinks[parentUuid].push(new SmetaLinkItem(item, cacheItem))
          }
        })

        Object.keys(this.smetaLinks).forEach(link => {
          let linkTypes = []
          this.smetaLinks[link].forEach(item => { 
            linkTypes.push(...item.links.map(el => el.type))
          })
          vuexStore.commit('smeta/setTreeLinks', { nodeUuid: link, linkTypes: linkTypes })
        })
      })
    }
  }
})

function findTaskWorkspace(link) { 
  let item = link.parent
  let workspaceUuid = null
  while (item) { 
    if (item.type == "WorkSpace") {
      workspaceUuid = item.uuid
      break
    }
    item = item.parent
  }
  return workspaceUuid
}

function findWormProjectUuid(link) { 
  let item = link
  let wormProjectUuid = null
  while (item) { 
    if (item.type == "ProjectWorm") {
      wormProjectUuid = item.uuid
      break
    }
    item = item.parent
  }
  return wormProjectUuid
}

function findProjectClassificatorUuid(link) { 
  let item = link
  let projectClassificatorUuid = null
  while (item) {
    if (item.type == "ProjectClassificator") {
      projectClassificatorUuid = item.uuid
      break
    }
    item = item.parent
  }
  return projectClassificatorUuid
}