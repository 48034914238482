<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.70711" y="20.6777" width="24" height="5" transform="rotate(-45 3.70711 20.6777)" fill="white" stroke="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6777 7.2426L22.0919 8.65681L21.3848 9.36392L19.9706 7.94971L20.6777 7.2426Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6066 12.8996L15.728 15.0209L15.0209 15.728L12.8995 13.6067L13.6066 12.8996Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8493 8.65691L19.9706 10.7782L19.2635 11.4853L17.1422 9.36401L17.8493 8.65691Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1923 15.728L13.6065 17.1422L12.8994 17.8493L11.4852 16.4351L12.1923 15.728Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.94978 19.9706L9.364 21.3848L8.65689 22.0919L7.24268 20.6777L7.94978 19.9706Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.435 11.4853L17.8492 12.8995L17.1421 13.6066L15.7279 12.1924L16.435 11.4853Z" fill="#303030"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.36397 17.1423L11.4853 19.2636L10.7782 19.9707L8.65686 17.8494L9.36397 17.1423Z" fill="#303030"/>
    <path d="M11.1465 28.6465C11.1465 28.9226 11.3703 29.1465 11.6465 29.1465L16.1465 29.1465C16.4226 29.1465 16.6465 28.9226 16.6465 28.6465C16.6465 28.3703 16.4226 28.1465 16.1465 28.1465L12.1465 28.1465L12.1465 24.1465C12.1465 23.8703 11.9226 23.6465 11.6465 23.6465C11.3703 23.6465 11.1465 23.8703 11.1465 24.1465L11.1465 28.6465ZM29.1465 11.6465C29.1465 11.3703 28.9226 11.1465 28.6465 11.1465L24.1465 11.1465C23.8703 11.1465 23.6465 11.3703 23.6465 11.6465C23.6465 11.9226 23.8703 12.1465 24.1465 12.1465L28.1465 12.1465L28.1465 16.1465C28.1465 16.4226 28.3703 16.6465 28.6465 16.6465C28.9226 16.6465 29.1465 16.4226 29.1465 16.1465L29.1465 11.6465ZM12 29L29 12L28.2929 11.2929L11.2929 28.2929L12 29Z" fill="#B5B5B5"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>