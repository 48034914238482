<template>
  <svg width="30" height="21" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 12V1L29 9V20L17 12Z" stroke="#B5B5B5"/>
    <path d="M17 12H3L14.5 20H29" stroke="#B5B5B5"/>
    <path d="M17 0.999985L29 8.99998" stroke="#FF0000"/>
    <path d="M5 5L23.2141 5.11694" stroke="#303030"/>
    <rect x="21.5" y="3.5" width="3" height="3" fill="white" stroke="#303030"/>
    <circle cx="17" cy="1" r="1" fill="#FF0000"/>
    <circle cx="3" cy="5" r="2.5" fill="#303030" stroke="#303030"/>
    <circle cx="29" cy="9" r="1" fill="#FF0000"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>