import { App } from '@app/App'

// TODO: необходимо делать проверку во всех методах, что переменная src имеет какие-то данные
// И решить, что делать дальше: возврщать NULL, выбрасывать ошибку или генерировать путь, заменяя src пустой строкой
export class SourcePath {
  static makePath (path = '') {
    return `${App.server.apiURL}${path}?access_token=${App.auth.accessToken}`
  }

  static avatar (src = '') {
    if (src == null || src === undefined) return ''
    return this.makePath(`avatar/${src}`)
  }
  
  static preview (src = '') {
    if (src == null || src === undefined) return ''
    return this.makePath(`preview/${src}`)
  }

  static file (src = '') {
    if (src == null || src === undefined) return ''
    return this.makePath(`files/${src}`)
  }

  static fileCloud (src = '') {
    if (src == null || src === undefined) return ''
    return `${App.server.apiURL}cloud/files/${src}`
  }

  static attachment (src = '') {
    if (src == null || src === undefined) return ''
    return this.makePath(`attachment/${src}`)
  }
  
  static equipmentAttachment (src = '') {
    if (src == null || src === undefined) return ''
    return this.makePath(`corp/maintenance/equipmentfile/${src}`)
  }
  
  static material (src = '') {
    if (src == null || src === undefined) return ''
    return this.makePath(`materials/materialFile/${src}`)
  }

  static elementFile (src) {
    if (src == null || src === undefined) return ''
    return this.makePath(`projects/elementfile/${src}`)
  }

  static revision (src = '') {
    if (src == null || src === undefined) return false
    return this.makePath(`revisions/${src}/download`)
  }
  
  static exportGroup ({ uuid, title } = {}) {
    return this.makePath(`projects/export/group/${uuid}/${title}.xlsx`)
  }

  static exportBCF (projectId) {
    return this.makePath(`tasks/exportBCF/${projectId}/${projectId}.bcfzip`)
  }

  static loadPointCloud(pointCloudUuid) {
    return this.makePath(`file/pointCloud/${pointCloudUuid}`)
  }

  static ifcWithAttr(revisionUID) {
    return this.makePath(`revisions/${revisionUID}/download/withcustomattr`)
  }

  static downloadLAZ(pointCloudUuid) {
    return this.makePath(`files/point_cloud_laz/${pointCloudUuid}`)
  }
  
  static downloadE57(pointCloudUuid) {
    return this.makePath(`files/point_cloud_e57/${pointCloudUuid}`)
  }
}