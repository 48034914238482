import { math as mathematics } from "@xeokit/xeokit-sdk"
import { geometry } from "./geometry"

export const math = {

  addVec3(v, u) {
    const v0 = v[0]
    const u0 = u[0]
    const v1 = v[1]
    const u1 = u[1]
    const v2 = v[2]
    const u2 = u[2]
    const result = []
    result[0] = v0 + u0
    result[1] = v1 + u1
    result[2] = v2 + u2
    return result
  },

  subVec3(v, u) {
    return [v[0] - u[0], v[1] - u[1], v[2] - u[2]]
  },

  dotVec2(v, u) {
    console.log(v, u)
    return v[0] * u[0] + v[1] * u[1]
  },

  dotVec3(v, u) {
    return v[0] * u[0] + v[1] * u[1] + v[2] * u[2]
  },

  dotVec4(v, u) {
    return v[0] * u[0] + v[1] * u[1] + v[2] * u[2] + v[3] * u[3]
  },

  mulVec3Scalar(v, a) {
    return [v[0] * a, v[1] * a, v[2] * a];
  },

  mulVec4Scalar(v, a) {
    return [v[0] * a, v[1] * a, v[2] * a, v[3] * a];
  },

  lenVec3(v) {
    return Math.sqrt(this.sqLenVec3(v));
  },

  lenVec4(v) {
    return Math.sqrt(this.sqLenVec4(v));
  },

  sqLenVec3(v) {
    return this.dotVec3(v, v);
  },

  sqLenVec4(v) {
    return this.dotVec4(v, v);
  },

  normalizeVec3(v) {
    if (v[0] == 0 && v[1] == 0 && v[2] == 0) {
      return [0, 0, 0]
    }
    const f = 1.0 / this.lenVec3(v);
    return this.mulVec3Scalar(v, f);
  },

  normalizeVec4(v) {
    const f = 1.0 / this.lenVec4(v);
    return this.mulVec4Scalar(v, f);
  },

  crossVec3(v, u) { //TODO: чем не устроил math.crossVec3()?
    return [
      v[1] * u[2] - v[2] * u[1],
      v[2] * u[0] - v[0] * u[2],
      v[0] * u[1] - v[1] * u[0]
    ];
  },

  mulMat4v3(m, v, dest = mathematics.vec4()) {
    const v0 = v[0];
    const v1 = v[1];
    const v2 = v[2];
    const v3 = 1;
    dest[0] = m[0] * v0 + m[4] * v1 + m[8] * v2 + m[12] * v3;
    dest[1] = m[1] * v0 + m[5] * v1 + m[9] * v2 + m[13] * v3;
    dest[2] = m[2] * v0 + m[6] * v1 + m[10] * v2 + m[14] * v3;
    dest[3] = m[3] * v0 + m[7] * v1 + m[11] * v2 + m[15] * v3;
    return dest;
  },

  determinantMat3(mat) {
    // Cache the matrix values (makes for huge speed increases!)
    const a00 = mat[0]
    const a01 = mat[1]
    const a02 = mat[2]
    const a10 = mat[3]
    const a11 = mat[4]
    const a12 = mat[5]
    const a20 = mat[6]
    const a21 = mat[7]
    const a22 = mat[8]

    return a00 * a11 * a22 + a01 * a12 * a20 + a02 * a10 * a21 - a02 * a11 * a20 - a01 * a10 * a22 - a00 * a12 * a21
  },

  distance(point1, point2) {
    // Вычисляем расстояние между двумя точками

    const [x1, y1, z1] = point1
    const [x2, y2, z2] = point2

    const dx = x2 - x1
    const dy = y2 - y1
    const dz = z2 - z1

    return Math.sqrt(dx * dx + dy * dy + dz * dz)
  },

  distanceBetweenByCoordinates(first, second) {
    const distance = Math.sqrt(Math.pow(first[0] - second[0], 2) + Math.pow(first[1] - second[1], 2) + Math.pow(first[2] - second[2], 2))

    return distance
  },

  frustumMat4(left, right, bottom, top, near, far) {
    let dest = mathematics.mat4();

    const rl = (right - left);
    const tb = (top - bottom);
    const fn = (far - near);

    // // как в учебнике
    // dest[0] = (near * 2) / rl
    // dest[1] = 0
    // dest[2] = 0
    // dest[3] = 0
    // dest[4] = 0
    // dest[5] = (near * 2) / tb
    // dest[6] = 0
    // dest[7] = 0
    // dest[8] = 0
    // dest[9] = 0
    // dest[10] = -(far + near) / fn
    // dest[11] = -1
    // dest[12] = -near * (right + left) / rl
    // dest[13] = -near * (top + bottom) / tb
    // dest[14] = (2 * far * near) / (near - far)
    // dest[15] = 0

    dest[0] = (near * 2) / rl;
    dest[1] = 0;
    dest[2] = 0;
    dest[3] = 0;
    dest[4] = 0;
    dest[5] = (near * 2) / tb;
    dest[6] = 0;
    dest[7] = 0;
    dest[8] = (right + left) / rl;
    dest[9] = (top + bottom) / tb;
    dest[10] = -(far + near) / fn;
    dest[11] = -1;
    dest[12] = 0;
    dest[13] = 0;
    dest[14] = -(far * near * 2) / fn;
    dest[15] = 0;

    return dest;
  },

  /**
     * Returns a 4x4 'lookat' viewing transform matrix.
     * @method lookAtMat4v
     * @param pos vec3 position of the viewer
     * @param target vec3 point the viewer is looking at
     * @param up vec3 pointing "up"
     *
     * @return {mat4} dest if specified, a new mat4 otherwise
     */
  lookAtMat4v(pos, target, up) {
    const dest = mathematics.mat4()

    const posx = pos[0];
    const posy = pos[1];
    const posz = pos[2];
    const upx = up[0];
    const upy = up[1];
    const upz = up[2];
    const targetx = target[0];
    const targety = target[1];
    const targetz = target[2];

    if (posx === targetx && posy === targety && posz === targetz) {
      return mathematics.identityMat4()
    }

    let z0;
    let z1;
    let z2;
    let x0;
    let x1;
    let x2;
    let y0;
    let y1;
    let y2;
    let len;

    //vec3.direction(eye, center, z);
    z0 = posx - targetx;
    z1 = posy - targety;
    z2 = posz - targetz;

    // normalize (no check needed for 0 because of early return)
    len = 1 / Math.sqrt(z0 * z0 + z1 * z1 + z2 * z2);
    z0 *= len;
    z1 *= len;
    z2 *= len;

    //vec3.normalize(vec3.cross(up, z, x));
    x0 = upy * z2 - upz * z1;
    x1 = upz * z0 - upx * z2;
    x2 = upx * z1 - upy * z0;
    len = Math.sqrt(x0 * x0 + x1 * x1 + x2 * x2);

    if (!len) {
      x0 = 0;
      x1 = 0;
      x2 = 0;
    } 
    else {
      len = 1 / len;
      x0 *= len;
      x1 *= len;
      x2 *= len;
    }

    //vec3.normalize(vec3.cross(z, x, y));
    y0 = z1 * x2 - z2 * x1;
    y1 = z2 * x0 - z0 * x2;
    y2 = z0 * x1 - z1 * x0;

    len = Math.sqrt(y0 * y0 + y1 * y1 + y2 * y2);

    if (!len) {
      y0 = 0;
      y1 = 0;
      y2 = 0;
    } else {
      len = 1 / len;
      y0 *= len;
      y1 *= len;
      y2 *= len;
    }

    dest[0] = x0;
    dest[1] = y0;
    dest[2] = z0;
    dest[3] = 0;
    dest[4] = x1;
    dest[5] = y1;
    dest[6] = z1;
    dest[7] = 0;
    dest[8] = x2;
    dest[9] = y2;
    dest[10] = z2;
    dest[11] = 0;
    dest[12] = -(x0 * posx + x1 * posy + x2 * posz);
    dest[13] = -(y0 * posx + y1 * posy + y2 * posz);
    dest[14] = -(z0 * posx + z1 * posy + z2 * posz);
    dest[15] = 1;

    return dest;
  },

  transposeMat4(mat) {
    // If we are transposing ourselves we can skip a few steps but have to cache some values
    const m4 = mat[4];

    const m14 = mat[14];
    const m8 = mat[8];
    const m13 = mat[13];
    const m12 = mat[12];
    const m9 = mat[9];
    
    let dest = mathematics.mat4()

    dest[0] = mat[0];
    dest[1] = m4;
    dest[2] = m8;
    dest[3] = m12;
    dest[4] = mat[1];
    dest[5] = mat[5];
    dest[6] = m9;
    dest[7] = m13;
    dest[8] = mat[2];
    dest[9] = mat[6];
    dest[10] = mat[10];
    dest[11] = m14;
    dest[12] = mat[3];
    dest[13] = mat[7];
    dest[14] = mat[11];
    dest[15] = mat[15];

    return dest;
  },

  quaternionToRotationMat4(q) {
    const m = mathematics.mat4()

    const x = q[0];
    const y = q[1];
    const z = q[2];
    const w = q[3];

    const x2 = x + x;
    const y2 = y + y;
    const z2 = z + z;
    const xx = x * x2;
    const xy = x * y2;
    const xz = x * z2;
    const yy = y * y2;
    const yz = y * z2;
    const zz = z * z2;
    const wx = w * x2;
    const wy = w * y2;
    const wz = w * z2;

    m[0] = 1 - (yy + zz);
    m[4] = xy - wz;
    m[8] = xz + wy;

    m[1] = xy + wz;
    m[5] = 1 - (xx + zz);
    m[9] = yz - wx;

    m[2] = xz - wy;
    m[6] = yz + wx;
    m[10] = 1 - (xx + yy);

    // last column
    m[3] = 0;
    m[7] = 0;
    m[11] = 0;

    // bottom row
    m[12] = 0;
    m[13] = 0;
    m[14] = 0;
    m[15] = 1;

    return m;
  },

  eulerToQuaternion(euler, order) {

    const dest = mathematics.vec4()

    const a = (euler[0] * mathematics.DEGTORAD) / 2;
    const b = (euler[1] * mathematics.DEGTORAD) / 2;
    const c = (euler[2] * mathematics.DEGTORAD) / 2;

    const c1 = Math.cos(a);
    const c2 = Math.cos(b);
    const c3 = Math.cos(c);
    const s1 = Math.sin(a);
    const s2 = Math.sin(b);
    const s3 = Math.sin(c);

    if (order === 'XYZ') {

      dest[0] = s1 * c2 * c3 + c1 * s2 * s3;
      dest[1] = c1 * s2 * c3 - s1 * c2 * s3;
      dest[2] = c1 * c2 * s3 + s1 * s2 * c3;
      dest[3] = c1 * c2 * c3 - s1 * s2 * s3;

    } else if (order === 'YXZ') {

      dest[0] = s1 * c2 * c3 + c1 * s2 * s3;
      dest[1] = c1 * s2 * c3 - s1 * c2 * s3;
      dest[2] = c1 * c2 * s3 - s1 * s2 * c3;
      dest[3] = c1 * c2 * c3 + s1 * s2 * s3;

    } else if (order === 'ZXY') {

      dest[0] = s1 * c2 * c3 - c1 * s2 * s3;
      dest[1] = c1 * s2 * c3 + s1 * c2 * s3;
      dest[2] = c1 * c2 * s3 + s1 * s2 * c3;
      dest[3] = c1 * c2 * c3 - s1 * s2 * s3;

    } else if (order === 'ZYX') {

      dest[0] = s1 * c2 * c3 - c1 * s2 * s3;
      dest[1] = c1 * s2 * c3 + s1 * c2 * s3;
      dest[2] = c1 * c2 * s3 - s1 * s2 * c3;
      dest[3] = c1 * c2 * c3 + s1 * s2 * s3;

    } else if (order === 'YZX') {

      dest[0] = s1 * c2 * c3 + c1 * s2 * s3;
      dest[1] = c1 * s2 * c3 + s1 * c2 * s3;
      dest[2] = c1 * c2 * s3 - s1 * s2 * c3;
      dest[3] = c1 * c2 * c3 - s1 * s2 * s3;

    } else if (order === 'XZY') {

      dest[0] = s1 * c2 * c3 - c1 * s2 * s3;
      dest[1] = c1 * s2 * c3 - s1 * c2 * s3;
      dest[2] = c1 * c2 * s3 + s1 * s2 * c3;
      dest[3] = c1 * c2 * c3 + s1 * s2 * s3;
    }

    return dest;
  },

  mat4ToEuler(mat, order) {
    const clamp = mathematics.clamp;
    const dest = []
    // Assumes the upper 3x3 of m is a pure rotation matrix (i.e, unscaled)

    const m11 = mat[0];

    const m12 = mat[4];
    const m13 = mat[8];
    const m21 = mat[1];
    const m22 = mat[5];
    const m23 = mat[9];
    const m31 = mat[2];
    const m32 = mat[6];
    const m33 = mat[10];

    if (order === 'XYZ') {

        dest[1] = Math.asin(clamp(m13, -1, 1));

        if (Math.abs(m13) < 0.99999) {
            dest[0] = Math.atan2(-m23, m33);
            dest[2] = Math.atan2(-m12, m11);
        } else {
            dest[0] = Math.atan2(m32, m22);
            dest[2] = 0;

        }

    } else if (order === 'YXZ') {

        dest[0] = Math.asin(-clamp(m23, -1, 1));

        if (Math.abs(m23) < 0.99999) {
            dest[1] = Math.atan2(m13, m33);
            dest[2] = Math.atan2(m21, m22);
        } else {
            dest[1] = Math.atan2(-m31, m11);
            dest[2] = 0;
        }

    } else if (order === 'ZXY') {

        dest[0] = Math.asin(clamp(m32, -1, 1));

        if (Math.abs(m32) < 0.99999) {
            dest[1] = Math.atan2(-m31, m33);
            dest[2] = Math.atan2(-m12, m22);
        } else {
            dest[1] = 0;
            dest[2] = Math.atan2(m21, m11);
        }

    } else if (order === 'ZYX') {

        dest[1] = Math.asin(-clamp(m31, -1, 1));

        if (Math.abs(m31) < 0.99999) {
            dest[0] = Math.atan2(m32, m33);
            dest[2] = Math.atan2(m21, m11);
        } else {
            dest[0] = 0;
            dest[2] = Math.atan2(-m12, m22);
        }

    } else if (order === 'YZX') {

        dest[2] = Math.asin(clamp(m21, -1, 1));

        if (Math.abs(m21) < 0.99999) {
            dest[0] = Math.atan2(-m23, m22);
            dest[1] = Math.atan2(-m31, m11);
        } else {
            dest[0] = 0;
            dest[1] = Math.atan2(m13, m33);
        }

    } else if (order === 'XZY') {

        dest[2] = Math.asin(-clamp(m12, -1, 1));

        if (Math.abs(m12) < 0.99999) {
            dest[0] = Math.atan2(m32, m22);
            dest[1] = Math.atan2(m13, m11);
        } else {
            dest[0] = Math.atan2(-m23, m33);
            dest[1] = 0;
        }
    }

    return [dest[0] * mathematics.RADTODEG, dest[1] * mathematics.RADTODEG, dest[2] * mathematics.RADTODEG];
  },

  quaternionByTwoVectors(vec1, vec2) {
    const v1 = vec1
    const v2 = vec2
    
    // Нулевой вектор
    if (v2[0] == 0 && v2[1] == 0 && v2[2] == 0) {
      return [0, 0, 0, 1]
    }

    // Противоположный вектор
    if (v2[0] == -v1[0] && v2[1] == -v1[1] && v2[2] == -v1[2]) {
      return [0, 0, 0, -1]
    }

    const v1Length = this.lenVec3(v1)
    const v2Length = this.lenVec3(v2)
    const quaternion = this.crossVec3(v1, v2) // Ось поворота
    quaternion.push(Math.sqrt((v1Length * v1Length) * (v2Length * v2Length)) + this.dotVec3(v1, v2)) // Градус поворота

    return this.normalizeVec4(quaternion)
  },

  mulQuaternions(p, q) {
    const dest = mathematics.vec4()

    const p0 = p[0];
    const p1 = p[1];
    const p2 = p[2];
    const p3 = p[3];
    const q0 = q[0];
    const q1 = q[1];
    const q2 = q[2];
    const q3 = q[3];
    dest[0] = p3 * q0 + p0 * q3 + p1 * q2 - p2 * q1;
    dest[1] = p3 * q1 + p1 * q3 + p2 * q0 - p0 * q2;
    dest[2] = p3 * q2 + p2 * q3 + p0 * q1 - p1 * q0;
    dest[3] = p3 * q3 - p0 * q0 - p1 * q1 - p2 * q2;
    return dest;
  },

  vec3ApplyQuaternion(q, vec) {
    const dest = mathematics.vec3()

    const x = vec[0];
    const y = vec[1];
    const z = vec[2];

    const qx = q[0];
    const qy = q[1];
    const qz = q[2];
    const qw = q[3];

    // calculate quat * vector

    const ix = qw * x + qy * z - qz * y;
    const iy = qw * y + qz * x - qx * z;
    const iz = qw * z + qx * y - qy * x;
    const iw = -qx * x - qy * y - qz * z;

    // calculate result * inverse quat

    dest[0] = ix * qw + iw * -qx + iy * -qz - iz * -qy;
    dest[1] = iy * qw + iw * -qy + iz * -qx - ix * -qz;
    dest[2] = iz * qw + iw * -qz + ix * -qy - iy * -qx;

    return dest;
  },

  mat4ToQuaternion(m) {
    const dest = mathematics.vec4()

    const m11 = m[0];
    const m12 = m[4];
    const m13 = m[8];
    const m21 = m[1];
    const m22 = m[5];
    const m23 = m[9];
    const m31 = m[2];
    const m32 = m[6];
    const m33 = m[10];
    let s;

    const trace = m11 + m22 + m33;

    if (trace > 0) {
      s = 0.5 / Math.sqrt(trace + 1.0);

      dest[3] = 0.25 / s;
      dest[0] = (m32 - m23) * s;
      dest[1] = (m13 - m31) * s;
      dest[2] = (m21 - m12) * s;
    } 
    else if (m11 > m22 && m11 > m33) {
      s = 2.0 * Math.sqrt(1.0 + m11 - m22 - m33);

      dest[3] = (m32 - m23) / s;
      dest[0] = 0.25 * s;
      dest[1] = (m12 + m21) / s;
      dest[2] = (m13 + m31) / s;
    } 
    else if (m22 > m33) {
      s = 2.0 * Math.sqrt(1.0 + m22 - m11 - m33);

      dest[3] = (m13 - m31) / s;
      dest[0] = (m12 + m21) / s;
      dest[1] = 0.25 * s;
      dest[2] = (m23 + m32) / s;
    } 
    else {
      s = 2.0 * Math.sqrt(1.0 + m33 - m11 - m22);

      dest[3] = (m21 - m12) / s;
      dest[0] = (m13 + m31) / s;
      dest[1] = (m23 + m32) / s;
      dest[2] = 0.25 * s;
    }

    return dest;
  },

  customLookAtMat(right, forward, up) {
    const dest = mathematics.mat4()
    dest[0] = right[0]
    dest[1] = up[0]
    dest[2] = forward[0]
    dest[3] = 0
    dest[4] = right[1]
    dest[5] = up[1]
    dest[6] = forward[1]
    dest[7] = 0
    dest[8] = right[2]
    dest[9] = up[2]
    dest[10] = forward[2]
    dest[11] = 0
    dest[12] = 0
    dest[13] = 0
    dest[14] = 0
    dest[15] = 1

    return dest
  },

  getLookAtCameraQuaternion(camera, objectUp, objectForward) {
    const viewMatrix = camera.viewMatrix
    const cameraVec = [viewMatrix[2], viewMatrix[6], viewMatrix[10]]

    const quaternion = geometry.math.quaternionByTwoVectors(objectUp, cameraVec)
    const upVec = geometry.math.normalizeVec3(geometry.math.vec3ApplyQuaternion(quaternion, objectForward))
    const cameraUp = geometry.math.normalizeVec3(camera.up)
    const upQuaternion = geometry.math.quaternionByTwoVectors(upVec, cameraUp)

    const quaternionProduct = geometry.math.mulQuaternions(upQuaternion, quaternion)
    
    return quaternionProduct
  },

  areVecsCollinear(v1, v2) {
    const crossProduct = this.crossVec3(v1, v2)
    if (geometry.utils.arePointsEqual(crossProduct, [0, 0, 0], 0.001)) return true

    return false
  }
}