export default ({ Factory, Presets }) => ({

  groups: (id) => Factory.corp.maintenance.group[id].get(),
  saveGroup: (obj) => Factory.corp.maintenance.group.post(obj),
  deleteGroup: (id) => Factory.corp.maintenance.group[id].delete(),

  saveEquipment: (obj) => Factory.corp.maintenance.equipment.post(obj),
  deleteEquipment: (id) => Factory.corp.maintenance.equipment[id].delete(),
  linkEquipment: (id, els) => Factory.corp.maintenance.equipment[id].patch(els),

  calcAllTO: (projectId) => Factory.corp.maintenance.calctask.all[projectId].get(),
  calcTO: (id) => Factory.corp.maintenance.calctask[id].get(),
  loadTasks: (id) => Factory.corp.maintenance.equipment[id].tasks.get(),

  rule: (id) => Factory.corp.maintenance.task[id].rule.get(),
  checks: (id) => Factory.corp.maintenance.task[id].checks.get(),
  doneCheck: (taskId, checkId, done) => Factory.corp.maintenance.task[taskId].check[checkId][done].patch(),
  doneTask: (id) => Factory.corp.maintenance.task[id].patch(),
  doneTaskAndCheck: (id, checks) => Factory.corp.maintenance.task[id].post(checks),
  getMaintenanceTask: (id) => Factory.corp.maintenance.task[id].get(),
  editMaintenanceDate: (obj, projectId) => Factory.corp.maintenance.task.date[projectId].patch(obj),
  editMaintenanceDutyNum: (task, projectId) => Factory.corp.maintenance.task.duty[projectId].patch(task),

  getSchedule: (id) => Factory.corp.maintenance.schedule[id].get(),

  getWarningLevels: () => Factory.equipment.warning.levels.get(),
  getWarningProfiles: (projectId) => Factory.equipment.warning.profiles[projectId].get(),
  saveWarningProfile: (obj) => Factory.equipment.warning.profile.post(obj),
  deleteWarningProfile: (id) => Factory.equipment.warning.profile[id].delete(),
  saveWarningFeature: (obj, type) => Factory.equipment.warning.feature[type].post(obj),
  deleteWarningFeature: (id) => Factory.equipment.warning.feature[id].delete(),
  getWarningEvents: (projectId) => Factory.equipment.warning.events[projectId].get(),
  getWarningEventsByFilter: (filter) => Factory.equipment.warning.events.post(filter),

  saveEquipmentAttachment: (equipmentId, file) => {
    let formData = new FormData()
    formData.append("file", file)
    return Factory.corp.maintenance.equipmentfile[equipmentId].post( formData, Presets.headers.MULTIPART )
  },
  downloadEquipmentAttachment: (equipmentAttachmentId) => Factory.corp.maintenance.equipmentfile[equipmentAttachmentId].get(),
  deleteEquipmentAttachment: (equipmentAttachmentId) => Factory.corp.maintenance.equipmentfile[equipmentAttachmentId].delete(),
  getEquipmentAttachment: (equipmentId) => Factory.corp.maintenance.equipment[equipmentId].get()

  //testW: () => Factory.equipment.warning.channel["9e8cb45a-89e2-48b3-a7b6-d51d841e9802"].data["9e8cb45a-89e2-48b3-a7b6-d51d841e9832"].post("3"),

})