import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { useImagePlaneStore } from '@/pinia';
import store from '@/store';
import { FloorBoundsCache } from '../floors/floorBoundsCache';

/*eslint-disable no-dupe-class-members*/
export class PickImagePlane {

  static #_onInputMouseClick = null

  static get #_scene() {
    return XeokitMediator.viewer.scene
  }

  static get #_imagePlaneStore() {
    return useImagePlaneStore()
  }

  static get isWaitImagePlanePick() {
    return this.#_imagePlaneStore.isWaitImagePlanePick
  }

  static get imageSrc() {
    return this.#_imagePlaneStore.imageSrc
  }

  static #_setImageSrc(imageSrc) {
    this.#_imagePlaneStore.setImageSrc(imageSrc)
  }

  static #_setImagePlaneTitle(title) {
    this.#_imagePlaneStore.setImagePlaneTitle(title)
  }

  static #_setWaitImagePlanePick(waitImagePlanePick) {
    this.#_imagePlaneStore.setWaitImagePlanePick(waitImagePlanePick)
  }

  static async waitImagePlanePick() {
    if (this.imageSrc == "") return

    store.commit('project/setActiveGlobalTab', null)

    await XeokitMediator.SectionCube.loadFloors()
    let floor = XeokitMediator.SectionCube.floors.find(floor => floor.uuid == XeokitMediator.SectionCube.selectedFloor)

    if (floor) {
      const [coordinates] = FloorBoundsCache.getFloorBounds(floor)

      let centerX = (coordinates[0] + coordinates[3]) / 2;
      let centerY = (coordinates[1] + coordinates[4]) / 2;
      let centerZ = coordinates[5];
      const coord = [centerX, centerY, centerZ]

      XeokitMediator.ImagePlanes.createImagePlane({
        imageSrc: this.imageSrc,
        position: coord,
        dir: [0, 0, 1],
        editable: true,
        flipDir: true
      })
      
      return
    }

    this.#_setWaitImagePlanePick(true)

    this.#_onInputMouseClick = XeokitMediator.viewer.scene.input.on("mouseclicked", (canvasCoords) => {
      this.cancelWaitImagePlanePick()

      let pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: canvasCoords,
        pickSurface: true
      });
      if (pickResult) {
        XeokitMediator.ImagePlanes.createImagePlane({
          imageSrc: this.imageSrc,
          position: pickResult._worldPos,
          dir: pickResult._worldNormal,
          editable: true,
          flipDir: true
        })
      }
    })

  }

  static cancelWaitImagePlanePick() {
    this.#_setWaitImagePlanePick(false)

    XeokitMediator.viewer.scene.input.off(this.#_onInputMouseClick)
  }

  static pick() {
    
  }
}