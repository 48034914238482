<template>
  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 17.8V7L27 13.5V24.3L19 17.8Z" stroke="#FF0000"/>
    <path d="M1 17.6V2L13 12.4V28L1 17.6Z" stroke="#FF0000"/>
    <path d="M7 16L15.5 16L23 16" stroke="#303030"/>
    <path d="M23 19V13" stroke="#303030"/>
    <path d="M7 19V13" stroke="#303030"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>