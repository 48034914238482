import { defineStore } from 'pinia'
import { useViewerIfcTypesStore, useViewerColorsStore, ColorCodingType } from '@/pinia'
import { api } from '@/api'
import vuexStore from '@/store'

export const useViewerAddColorCodingStore = defineStore('viewer.addColorCoding', {
  state: () => ({
    uuid: null,
    color: makeRandomHexColor(),
    title: '',
    items: [],
    selectedItems: [],
    selectedType: null,
    ready: false,
  }),

  actions: {
    async prepareWith(colorCoding) {
      this.uuid = colorCoding.uuid
      this.color = colorCoding.color
      this.title = colorCoding.title
      await this.setTypeToAdd(colorCoding.type.value)

      let list = []
      colorCoding.items.forEach(uuid => {
        let item = this.items.find(obj => obj.uuid === uuid)
        if (item) {
          list.push(item)
        }
      })
      this.selectedItems = list
    },

    async setTypeToAdd (type) {
      this.ready = false
      this.selectedType = type
      this.selectedItems = []
      this.items = await this.fetchItemsByType(type)
      this.ready = true
    },

    async fetchItemsByType (type) {
      if (type == ColorCodingType.CLASSES) return this.fetchClasses()
      else if (type == ColorCodingType.MODELS) return this.fetchModels()
      else if (type == ColorCodingType.GROUPS) return this.fetchGroups()
      return []
    },

    async fetchClasses () {
      let ifcTypesStore = useViewerIfcTypesStore()
      let types = await ifcTypesStore.fetchModelBasedIfcTypes()
      return types.map(t => makeItem(t, t)).sort((a, b) => a.title.localeCompare(b.title))
    },

    fetchModels () {
      let tree = vuexStore.state.project?.project?.model || []
      let models = flatlist(tree)
      return models.map(m => makeItem(m.uuid, m.title)).sort((a, b) => a.title.localeCompare(b.title))
    },
    
    async fetchGroups () {
      let projectUuid = vuexStore.getters['project/projectUuid']
      let hashProject = vuexStore.getters['project/hashProject']
      let groups = await api.axes.fetchCustomAxisGroup(projectUuid, hashProject)
      return groups.map(g => makeItem(g.uuid, g.title)).sort((a, b) => a.title.localeCompare(b.title))
    },

    save () {
      let title = this.title || this.selectedItems.map(item => item.title).join(', ')
      let selectedUuids = this.selectedItems.map(item => item.uuid)

      let store = useViewerColorsStore()
      if (this.uuid) {
        store.updateColorCoding(title, this.color, this.selectedType, selectedUuids, this.uuid)
      } else {
        store.addColorCoding(title, this.color, this.selectedType, selectedUuids)
      }
    }
  }
})

function makeRandomHexColor () {
  const randomColor = (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0')
  return "#" + randomColor.toLocaleUpperCase()
}

function makeItem (uuid, title) {
  return { uuid, title }
}

function flatlist (tree) {
  let array = []
  tree.forEach(element => {
    array.push(element, ...flatlist(element.model))
  })
  return array
}