<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" fill="transparent" />
    <path d="M20 17.8V7L28 14.2V25L20 17.8Z" stroke="currentColor" />
    <path d="M24 19V13" stroke="currentColor" />
    <path d="M6 16L24 16" stroke="currentColor" />
    <circle cx="4" cy="16" r="3" fill="currentColor" />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>