<template>
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 12.8V2L27 9.2V20L19 12.8Z" stroke="#FF0000"/>
    <path d="M23 14V8" stroke="#303030"/>
    <path d="M5 11L23 11" stroke="#303030"/>
    <circle cx="3" cy="11" r="2.5" fill="#303030" stroke="#303030"/>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>