import { webPushService } from "./webPush.service"

async function checkNotificationPermission() {
    if (Notification.permission === 'granted') return true
  
    if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        return true
      }
    }
  
    return false
  }

export async function subscribePush() {
    const notificationEnabled = await checkNotificationPermission()
    if (!notificationEnabled) return
  
    const registration = await navigator?.serviceWorker.getRegistration()
    if (!registration) return
  
    const publicKey = await webPushService.getPublicKey()
    if(!publicKey) return
  
    let serverKey = base64UrlToUint8Array(publicKey)
    registration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: serverKey,
      })
      .catch((er) => console.log('error', er))
      .then((subscription) => {
        if (subscription)
          webPushService.subscribe(subscription)
      })
  }

  export async function unsubscribePush() {
    const registration = await navigator.serviceWorker.getRegistration()
    if (registration) {
      const subscription = await registration.pushManager.getSubscription()
      if (!subscription) return
      
      const success = await subscription.unsubscribe()
      if (success) {
        webPushService.unsubscribe(subscription)
      }
    }
  }

  function base64UrlToUint8Array(base64UrlData) {
    const padding = '='.repeat((4 - (base64UrlData.length % 4)) % 4)
    const base64 = (base64UrlData + padding).replace(/-/g, '+').replace(/_/g, '/')
  
    const rawData = atob(base64)
    const buffer = new Uint8Array(rawData.length)
  
    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i)
    }
  
    return buffer
  }