import { api } from '@/api'

export default {
  namespaced: true,

  state: {
    edgeMeshes: [],
    labelMeshes: [],
    gridsActivity: false,
    transparency: false
  },
  mutations: {
    setGridsTransparency: (state, transparency) => { state.transparency = transparency },
    setGridsActivity: (state, activity) => { state.gridsActivity = activity },
  },
  getters: {},

  actions: {
    getIFCGrids(obj, projectUuid) {
      return api.IFCGrids.getIFCGridsByProjectUuid(projectUuid).then(data => {
        return data
      })
    },

    // downloadIFCGridsByRevisionUuid(obj, revisionUuid) {
    //   return api.IFCGrids.getIFCGridsByModelRevisionUuid(revisionUuid).then(data => {
    //     return data
    //   })
    // }
    getIFCTree(obj, projectUuid) {
      return api.IFCGrids.getIFCTreeByProjectUuid(projectUuid).then(data => {
        return data
      })
    },

    saveIFCGridsVisibility(obj, revisionUuid) {
      return api.IFCGrids.changeIFCGridsVisibility(revisionUuid).then(data => {
        return data
      })
    },

    getLabelSize(obj, projectUuid) {
      return api.IFCGrids.getLabelSize(projectUuid).then(data => {
        return data
      })
    },

    saveEdgeMeshes({state}, meshes) {
      state.edgeMeshes = meshes
    },

    saveLabelMeshes({state}, meshes) {
      state.labelMeshes = meshes
    },

    deleteEdgeMeshes({state}) {
      state.edgeMeshes.forEach(mesh => {
        mesh?.destroy()
      })
      state.edgeMeshes = []
    },

    deleteLabelMeshes({state}) {
      state.labelMeshes.forEach(mesh => {
        mesh?.destroy()
      })
      state.labelMeshes = []
    },
  }
}
