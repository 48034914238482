import { api } from '@/api'
import { App } from '@app/App'

let defaultClosePanelsKeys = [
  "PROJECT_MODEL_STATUS",
  "PROJECT_MODEL_ATTACHMENTS",
  "MAINTENANCE_DATA",
  "STRUCTURE_PATCH_LIST",
  "MAINTENANCE_LINKED_ELEMENTS"
]

export default {
  namespaced: true,

  state: {
    visibleArray: [],
  },

  getters: {
    floatingPanels: (state, getters, rootState) => (floatingPanelKey) => {
      let floatingPanel = Object.assign({}, rootState.authUser.user.settings?.floatingPanels.find(panel => panel?.floatingPanel?.title == floatingPanelKey))
      if (floatingPanel.opened == true && floatingPanel.height == 0.0) {
        floatingPanel.opened = defaultClosePanelsKeys.some(key => key == floatingPanelKey) ? false : true
      }
      return floatingPanel
    },
    getVisibleArray: (state) => () => {
      return state.visibleArray
    }
  },
  
  mutations: {
    setVisibleArray(state, payload) {
      state.visibleArray = payload
    }
  },

  actions: {
    updateFloatingPanels: ({ dispatch }, newFloatingPanels) => {
      if (!App.version.isCloud && newFloatingPanels.length > 0) {
        api.usersSettings.setFloatingPanelsSettings(newFloatingPanels).then((updatedFloatingPanels) => {
          dispatch('authUser/setFloatingPanel', updatedFloatingPanels, { root: true })
        })
      }
    }
  }
}