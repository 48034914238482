import { geometry } from "@/plugins/xeokit/plugins/geometry/geometry";
import { mergeAABBs, getAabbCenter } from "../sectionCube/aabb.utils";
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import { AlertService } from "@/assets/app/AlertService";
import { math } from "@xeokit/xeokit-sdk";
import i18n from "@/plugins/i18n";
import store from '@/store'
import _ from "lodash";

/*eslint-disable no-dupe-class-members*/
export class CameraFlight {

  static get #_viewer() {
    return XeokitMediator.viewer
  }

  static get #_collisionSelectedElements() {
    return store.state.collision.search.collisionSelectedElements
  }

  static get #_pickedElement() {
    return XeokitMediator.ElementsSelection.pickedElement
  }

  static _defaultSceneAabb = [-1, -1, -1, 1, 1, 1]

  /**
   * Подлет {@link Camera} к цели.
   *
   *  * Если целью является объект (модель или граница), {@link Camera} совершит подлет к цели и остановится, когда цель будет занимать большую часть {@link Canvas}.
   *  * Если целью является позиция {@link Camera}, заданная как ``eye``, ``look`` and ``up``, тогда {@link CameraFlightAnimation} совершит плавный подлет {@link Camera} к цели и остановит ее там.
   *
   * @param {Object|Component} [params=Scene] Либо объект parameters, либо {@link Component}, имеющий
   * AABB. По умолчанию - {@link Scene}, что приводит к тому, что область видимости {@link Camera} покроет видимую сцену.
   * @param {Number} [params.arc=0] Коэффициент в диапазоне ``[0..1]``, показывающий, насколько позиция {@link Camera.eye}
   * отклониться от позиции {@link Camera.look} при подлете к цели.
   * @param {Number|String|Component} [params.component] Uuid или экземпляр {@link Component}, к которому будет произведен подлет.
   * По умолчанию - вся {@link Scene}.
   * @param {Number[]} [params.aabb] Параллельный осям описывающий параллелепипед (AABB) в мировом пространстве, являющий целью для подлета.
   * @param {Number[]} [params.eye] Позиция ``eye`` как цель для подлета. При неизменной ``look`` произойдет перемещение камеры относительно точки ``look``.
   * @param {Number[]} [params.look] Положение ``look``, к которому будет направлена камера. При неизменной ``eye`` произойдет поворот камеры в направлении ``look``.
   * @param {Number[]} [params.up] Положение камеры по вертикали.
   * @param {String} [params.projection] Тип проекции, к которой будет произведен плавный перех. Принимает значения {@link Camera.projection}.
   * @param {Boolean} [params.fit=true] Следует ли вписывать цель в область просмотра. Переопределяет {@link CameraFlightAnimation.fit}.
   * @param {Number} [params.fitFOV] Величина ширины поля обзора (FOV) в градусах, которую {@link Entity} или его AABB должны заполнить
   * при подлете. Переопределяет {@link CameraFlightAnimation.fitFOV}.
   * @param {Number} [params.duration] Длительность подлета в секундах. Переопределяет {@link CameraFlightAnimation.duration}.
   * @param {Number} [params.orthoScale] Анимировать ортогональный масштаб камеры на указанное значение. См. {@link Ortho.scale}.
   * @param {Function} [callback] Обратный вызов по прибытии.
   * @param {Object} [scope] Опциональная цель обратного вызова.
   */
  static flyTo(params, callback, scope) {
    XeokitMediator.viewer.cameraFlight.flyTo(params, callback, scope)
  }

  /**
   * Подлет {@link Camera} к группе моделей проекта.
   * 
   * * Если среди переданных uuid моделей некоторые будут отсутствовать на {@link Scene}, они будут проигнорированы.
   * * Если все модели будут отсутствовать на {@link Scene}, выведется {@link Alert} о необходимости включить хотя бы одну модель.
   * 
   * @param {String[]} modelUuids 
   */
  static flyToProjectModels(modelUuids) {
    const models = store.getters['project/flatlist'].filter(model => modelUuids.includes(model.uuid))
    const AABBs = []
    let found = false
    for (const model of models) {
      if (model.revision) {
        found = true
        const xktModel = XeokitMediator.viewer.scene.models[model.revision.uuid]
        if (xktModel) {
          const aabb = this.#_getModelAabbWithSuspectFilter(xktModel)
          AABBs.push(aabb)
        }
      }
      else if (model.pointCloud) {
        found = true
        const xktModel = XeokitMediator.viewer.scene.models[model.pointCloud.uuid]
        if (xktModel) {
          const aabb = xktModel.aabb
          AABBs.push(aabb)
        }
      }
    }
    
    if (!found) {
      AlertService.warning({ info: i18n.t("viewer.tools.zoomWarn.notFound") })
      return
    }
    
    const filteredAABBs = AABBs.filter(aabb => aabb != null)

    if (filteredAABBs.length != 0) {
      const aabb = mergeAABBs(filteredAABBs)
      const aabbCenter = getAabbCenter(aabb)
      XeokitMediator.PivotPin.setPivotPinPosition(aabbCenter)
      this.flyTo({ aabb: aabb })
    }
    else AlertService.warning({ info: i18n.t("viewer.tools.zoomWarn") })
  }

  /**
   * Подлет {@link Camera} к модели проекта.
   * 
   * * Если модель будут отсутствовать на {@link Scene}, выведется {@link Alert} о необходимости включить модель.
   * 
   * @param {String} modelUuid 
   */
  static flyToProjectModel(model) {
    let found = false

    if (model.revision) {
      found = true
      const xktModel = XeokitMediator.viewer.scene.models[model.revision.uuid]

      if (xktModel) {
        this.flyToXktModel(xktModel)
        return
      }
    }
    else if (model.pointCloud) {
      found = true
      const xktModel = XeokitMediator.viewer.scene.models[model.pointCloud.uuid]

      if (xktModel) {
        const aabb = xktModel.aabb
        const aabbCenter = getAabbCenter(aabb)
        XeokitMediator.PivotPin.setPivotPinPosition(aabbCenter)
        this.flyTo({ aabb: aabb })
        return
      }
    }

    if (!found) {
      AlertService.warning({ info: i18n.t("viewer.tools.zoomWarn.notFound") })
      return
    }

    AlertService.warning({ info: i18n.t("viewer.tools.zoomWarn") })
  }

  /**
   * Подлет {@link Camera} к модели на сцене.
   * 
   * @param {SceneModel} model 
   */
  static flyToXktModel(model) {
    const aabb = this.#_getModelAabbWithSuspectFilter(model)
    const aabbCenter = getAabbCenter(aabb)
    XeokitMediator.PivotPin.setPivotPinPosition(aabbCenter)
    this.flyTo(aabb)
  }

  /**
   * Подлет {@link Camera} к такому виду, при котором:
   * * вся видимая область будет помещения в поле зрения камеры;
   * * выделенные или подсвеченные элементы на сцене будут помещены в поле зрения камеры.
   * @param {String} [cfg.projection] Проекция, на которую необходимо переключится
   */
  static async flyToDefaultView(cfg) {
    
    let projection = null
    if (cfg) projection = cfg.projection ?? null

    let aabbdiag = Math.floor((math.getAABB3Diag(this.#_viewer.scene.aabb))*2)

    this.#_viewer.cameraControl.dollyMinSpeed = 0.004           // Настройка скорости приближения
    this.#_viewer.cameraControl.dollyProximityThreshold = 50.0  // камеры к объекту под указателем
    this.#_viewer.cameraControl.mouseWheelDollyRate = 100.0     // по вращению
    this.#_viewer.camera.perspective.near = 0.01                // колесика мыши

    this.#_viewer.camera.perspective.far = aabbdiag > 2000 ? aabbdiag : 2000
    this.#_viewer.camera.ortho.far = this.#_viewer.camera.perspective.far * 10
    this.#_viewer.camera.ortho.near = this.#_viewer.camera.perspective.far * -1

    // Фильтр подозрительных элементов при расчете aabb для подлета камеры. Нужно ввести, когда подозрительные элементы станут корректными.
    let primaryIds = _.difference(this.#_viewer.scene.visibleObjectIds, this.#_viewer.scene.xrayedObjectIds)
    let suspectElements = store.state.project.suspectElements
    let suspectElementIds = []
    
    suspectElements?.forEach(revision => {
      revision.elements.forEach(element => {
        suspectElementIds.push(element.globalId)
      })
    })

    // Фильтр сломанных
    let objIds = XeokitMediator.viewer.scene.objectIds
    let brokenObjectIds = []
    objIds.forEach(id => {
      let obj = XeokitMediator.viewer.scene.objects[id]
      if (obj.aabb[3] === obj.aabb[0] && obj.aabb[4] === obj.aabb[1] && obj.aabb[5] === obj.aabb[2]) {
        brokenObjectIds.push(obj.id)
      }
    });
    let secondaryIds = _.difference(primaryIds, brokenObjectIds)
    let ids = _.difference(secondaryIds, suspectElementIds)
    
    //let ids = _.difference(this.#_viewer.scene.visibleObjectIds, this.#_viewer.scene.xrayedObjectIds)
    
    let aabb = ids.length > 0 ? this.#_viewer.scene.getAABB(ids) : this.#_viewer.scene.aabb;

    // Обрезка aabb плоскостями сечения
    Object.keys(this.#_viewer.scene.sectionPlanes).forEach(sectionPlaneKey => {
      if (this.#_viewer.scene.sectionPlanes[sectionPlaneKey].active && !XeokitMediator.SectionCube.active) {
        let section = this.#_viewer.scene.sectionPlanes[sectionPlaneKey]
        if(geometry.intersection.isPlaneIntersectAABB(section, aabb)){
          let aabbEdges = geometry.aabb.getAabbEdges(aabb)
          let aabbVertices = geometry.aabb.getAabbVertices(aabb)
          let newAabbVertices = []
          for(let i = 0; i < aabbEdges.length; i++){
            let intersectionObject = geometry.intersection.findLineSegmentPlaneIntersectionPoint(aabbEdges[i], section)
            if(geometry.intersection.isLineIntersectsPlane(aabbEdges[i], section.dir) && intersectionObject.areIntersected) {
              newAabbVertices.push(intersectionObject.intersectionPoint)
            }
          }
          newAabbVertices.push(...geometry.intersection.findNonCutOffAabbVertices(aabbVertices, section))
          aabb = geometry.aabb.buildAabbByPointsArray(newAabbVertices)
        }
      }
    })

    this._defaultSceneAabb = aabb
    const aabbCenter = getAabbCenter(aabb)

    await new Promise(resolve => this.flyTo({ aabb: aabb }, () => {
      XeokitMediator.PivotPin.setPivotPinPosition(aabbCenter)
      if (projection) this.flyTo({ projection: projection })
      // Фикс поломанный цветов 
      XeokitMediator.viewer.scene.camera.ortho.far = 3000
      XeokitMediator.viewer.scene.camera.ortho.near = 0

      resolve()
    }))
  }

  /**
   * Подлет {@link Camera}, при котором выделенные коллизионные элементы, подсвеченный элемент, а также переданные
   * в аргументе элементы окажутся вписаны в область видимости камеры.
   * @param {Array<String>} [objectUuids = null] UUIDs элементов, которые будут включены в список цели для подлета камеры.
   */
  static flyToElements(objectUuids= null) {
    const flyToObjectIds = [...objectUuids, ...this.#_collisionSelectedElements, this.#_pickedElement]
    if (!flyToObjectIds) {
      //this.error('flyToObject() - Argument expected: flyToObjectIds')
      return
    }
    
    this.#_flyToObjects(flyToObjectIds)
  }

  /**
   * Подлет {@link Camera}, при котором переданный в аргументе элемент, окажется вписан в область видимости камеры.
   * @param {Strin} objectUuid UUID элемента, к которому необходимо подлететь камерой
   */
  static flyToElement(objectUuid) {
    if (objectUuid === null) return
    
    this.#_flyToObjects([objectUuid])
  }

  static #_flyToObjects(flyToObjectIds) {
    const entityIds = []
    for (let i = 0, len = flyToObjectIds.length; i < len; i++) {
      const objectId = flyToObjectIds[i]
      this.#_viewer.metaScene.withMetaObjectsInSubtree(objectId, (metaObject) => {
        if (this.#_viewer.scene.objects[metaObject.id]) {
          entityIds.push(metaObject.id)
        }
      })
    }
    if (entityIds.length === 0) {
      return
    }

    XeokitMediator.ElementsSettings.setElementsVisible(entityIds, true) // TODO: Нужно?
    const aabb = this.#_viewer.scene.getAABB(entityIds)
    const aabbCenter = getAabbCenter(aabb)
    XeokitMediator.PivotPin.setPivotPinPosition(aabbCenter)

    this.flyTo({ aabb: aabb })
    this.#_viewer.cameraControl.pivotPos = math.getAABB3Center(aabb)
  }

  static #_getModelAabbWithSuspectFilter(model) {
    let entityIds = model.entityList.map(entity => {
      return entity.id
    })
    
    let suspectElements = store.state.project.suspectElements
    let suspectElementIds = []
    
    suspectElements?.forEach(revision => {
      revision.elements.forEach(element => {
        suspectElementIds.push(element.globalId)
      })
    })

    // Фильтр сломанных
    let objIds = XeokitMediator.viewer.scene.objectIds
    let brokenObjectIds = []
    objIds.forEach(id => {
      let obj = XeokitMediator.viewer.scene.objects[id]
      if (obj.aabb[3] === obj.aabb[0] && obj.aabb[4] === obj.aabb[1] && obj.aabb[5] === obj.aabb[2]) {
        brokenObjectIds.push(obj.id)
      }
    });
    let noSuspectIds = _.difference(entityIds, suspectElementIds)
    let ids = _.difference(noSuspectIds, brokenObjectIds)
    
    let aabb = ids.length > 0 ? this.#_viewer.scene.getAABB(ids) : this.#_viewer.scene.aabb;

    return aabb
  }
}