/* eslint-disable */
import { Mesh, PhongMaterial, Plugin, ReadableGeometry, SectionPlane, SectionPlanesPlugin, math, buildBoxGeometry, buildSphereGeometry, EmphasisMaterial } from "@xeokit/xeokit-sdk"
import { XeokitSectionPlanesPlugin } from "../XeokitSectionPlanesPlugin/XeokitSectionPlanesPlugin"
import { XeokitMediator } from "../XeokitMediator"

class SectionCubePlugin extends Plugin {
  _active
  
  /**@type {SectionPlanesPlugin} */
  _sectionPlanesPlugin
  
  /**@type {Array<SectionPlane>} */
  _sectionPlanes = []
  
  /**@type {Mesh} */
  _cube
  
  /**@type {Array<Mesh>} */
  _triggers = []
  
  _hoverEnterSubId
  _sceneTickSubId

  _visible

  _lastDist

  #_edges = []

  constructor(viewer, cfg = {}) {
    super('SectionCubePlugin', viewer, cfg)
    this._sectionPlanesPlugin = new XeokitSectionPlanesPlugin(this.viewer)

    this._init()
  }
  
  _init() {
    this._sectionPlanes = []
    // this._bindEvents()
  }
  
  _createSectionPlane(pos, dir) {
    const sectionPlane = this._sectionPlanesPlugin.createSectionPlane({
      pos, dir,
    })
    sectionPlane.active = false
    sectionPlane.isSectionCube = true
    const controls = this._sectionPlanesPlugin._controls[sectionPlane.id]
    controls._rootNode.collidable = false
    Object.keys(controls._displayMeshes).forEach(key => {
      if ((!key.startsWith('z') && !key.startsWith('center')) || key.includes('Curve')) {
        controls._displayMeshes[key].destroy()
      }
    })

    return sectionPlane
  }

  _bindEvents() {
    this._sectionPlanes.forEach((sectionPlane, index) => {
      sectionPlane.on('pos', (pos) => {
        const modelAabbs = Object.values(this.viewer.scene.models).map(model => model.aabb)

        // const controls = this._sectionPlanesPlugin._controls[sectionPlane.id]
        const parrallel = this._sectionPlanes[index >= 3 ? index - 3 : index + 3]
        
        // const [xmin, ymin, zmin, xmax, ymax, zmax] = modelAabbs.length ? geometry.aabb.summaryAabb(modelAabbs) : this.viewer.scene.aabb
        const [xmin, ymin, zmin, xmax, ymax, zmax] = this.viewer.scene.getAABB(this.viewer.scene.objectIds)
        let [x, y, z] = pos
        const [px, py, pz] = parrallel.pos

        if (index >= 3) {
          x < px && (x = px)
          y < py && (y = py)
          z < pz && (z = pz)

          x > xmax && (x = xmax)
          y > ymax && (y = ymax)
          z > zmax && (z = zmax)
        }
        else {
          x > px && (x = px)
          y > py && (y = py)
          z > pz && (z = pz)

          x < xmin && (x = xmin)
          y < ymin && (y = ymin)
          z < zmin && (z = zmin)
        }

        if (x !== pos[0] || y !== pos[1] || z !== pos[2]) {
          this._setSectionPlanePos(sectionPlane, [x, y, z])
        }

        if (this.active) {
          const aabb = [
            this._sectionPlanes[0].pos[0],
            this._sectionPlanes[1].pos[1],
            this._sectionPlanes[2].pos[2],
            this._sectionPlanes[3].pos[0],
            this._sectionPlanes[4].pos[1],
            this._sectionPlanes[5].pos[2],
          ]

          this._update(aabb)
        }
      })
    })

    this._hoverEnterSubId = this.viewer.cameraControl.on('hoverEnter', (hit) => {
      const idx = this._triggers.findIndex(trigger => trigger.id === hit?.entity?.id)
      if (idx < 0) return

      this._sectionPlanesPlugin.showControl(this._sectionPlanes[idx].id)
    })

    this._sceneTickSubId = this.viewer.scene.on('tick', () => {
      if (!this.visible) return
      
      let dist = math.getAABB3Diag([...this.viewer.scene.camera.eye, ...math.getAABB3Center(this.aabb)])
      if (this._lastDist !== dist) {
        this._lastDist = dist
        this._updateTriggers(this.aabb)
      }
    })
  }

  _unbindEvents() {
    this.viewer.cameraControl.off(this._hoverEnterSubId)
    this.viewer.scene.off(this._sceneTickSubId)
  }

  _createTrigger(xyz) {
    return XeokitMediator.SceneObjects.createSphereMesh({
      worldPos: xyz,
      radius: math.getAABB3Diag([...this.viewer.scene.camera.eye, ...math.getAABB3Center(this.aabb)]) / 100,
      visible: this.visible,
      clippable: false,
      pickable: true,
      color: [0.0, 0.0, 1.0],
      heightSegments: 60,
      widthSegments: 60,
    })
  }

  _updateTriggers(aabb) {
    const [xmin, ymin, zmin, xmax, ymax, zmax] = aabb

    this._triggers.forEach(trigger => trigger.destroy())
    this._triggers = [
      this._createTrigger([xmin, (ymin + ymax) / 2, (zmin + zmax) / 2]),
      this._createTrigger([(xmin + xmax) / 2, ymin, (zmin + zmax) / 2]),
      this._createTrigger([(xmin + xmax) / 2, (ymin + ymax) / 2, zmin]),
      
      this._createTrigger([xmax, (ymin + ymax) / 2, (zmin + zmax) / 2]),
      this._createTrigger([(xmin + xmax) / 2, ymax, (zmin + zmax) / 2]),
      this._createTrigger([(xmin + xmax) / 2, (ymin + ymax) / 2, zmax])
    ]
  }

  _updateSectionCube(aabb) {
    this._cube?.destroy()

    this._cube = XeokitMediator.SceneObjects.createAabbMesh({
      aabb: aabb,
      alphaMode: 'mask'
    })

    // TODO Наработка для быстрого рендеринга
    // const [xmin, ymin, zmin, xmax, ymax, zmax] = aabb
    // const edges = [
    //   [xmin, ymin, zmin, xmax, ymin, zmin],
    //   [xmin, ymin, zmin, xmin, ymin, zmax],
    //   [xmin, ymin, zmin, xmin, ymax, zmin],

    //   [xmax, ymax, zmin, xmax, ymin, zmin],
    //   [xmax, ymax, zmin, xmax, ymax, zmax],
    //   [xmax, ymax, zmin, xmin, ymax, zmin],

    //   [xmin, ymax, zmax, xmax, ymax, zmax],
    //   [xmin, ymax, zmax, xmin, ymin, zmax],
    //   [xmin, ymax, zmax, xmin, ymax, zmin],

    //   [xmax, ymin, zmax, xmax, ymax, zmax],
    //   [xmax, ymin, zmax, xmax, ymin, zmin],
    //   [xmax, ymin, zmax, xmin, ymin, zmax],
    // ]

    // this.#_edges.forEach(edge => edge.destroy())
    // this.#_edges = edges.map(edgeAabb => {
    //   const xSize = (Math.sqrt(Math.pow(edgeAabb[3] - edgeAabb[0], 2)) / 2) || 0.02
    //   const ySize = (Math.sqrt(Math.pow(edgeAabb[4] - edgeAabb[1], 2)) / 2) || 0.02
    //   const zSize = (Math.sqrt(Math.pow(edgeAabb[5] - edgeAabb[2], 2)) / 2) || 0.02
      
    //   return new Mesh(this.viewer.scene, {
    //     pickable: false,
    //     clippable: false,
    //     collidable: false,
    //     visible: this.visible,
        
    //     geometry: new ReadableGeometry(this.viewer.scene, buildBoxGeometry({
    //       xSize, ySize, zSize,
    //       center: math.getAABB3Center(edgeAabb)
    //     })),
        
    //     highlightMaterial: new EmphasisMaterial(this.viewer.scene, {
    //       edges: false,
    //       edgeColor: [0.0, 0.0, 0.0],
    //       filled: true,
    //       fillColor: [0.8, 0.8, 0.8],
    //       fillAlpha: 1.0
    //     }),
        
    //     material: new PhongMaterial(this.viewer.scene, {
    //       diffuse: [0.23137254901960785, 0.5764705882352941, 0.6862745098039216],
    //     }),
    //   })
    // })
  }

  _updateSectionPlanes(aabb) {
    const [xmin, ymin, zmin, xmax, ymax, zmax] = aabb

    this._setSectionPlanePos(this._sectionPlanes[0], [xmin, (ymin + ymax) / 2, (zmin + zmax) / 2])
    this._setSectionPlanePos(this._sectionPlanes[1], [(xmin + xmax) / 2, ymin, (zmin + zmax) / 2])
    this._setSectionPlanePos(this._sectionPlanes[2], [(xmin + xmax) / 2, (ymin + ymax) / 2, zmin])
    this._setSectionPlanePos(this._sectionPlanes[3], [xmax, (ymin + ymax) / 2, (zmin + zmax) / 2])
    this._setSectionPlanePos(this._sectionPlanes[4], [(xmin + xmax) / 2, ymax, (zmin + zmax) / 2])
    this._setSectionPlanePos(this._sectionPlanes[5], [(xmin + xmax) / 2, (ymin + ymax) / 2, zmax])
    
    this._sectionPlanes.forEach(sectionPlane => sectionPlane.active = true)
  }

  _update(aabb) {
    this._updateSectionCube(aabb)
    this._updateTriggers(aabb)
    this._updateSectionPlanes(aabb)
    
    this._handleUpdate()
  }

  _setSectionPlanePos(sectionPlane, pos) {
    const controls = this._sectionPlanesPlugin._controls[sectionPlane.id]

    sectionPlane._state.pos.set(pos)
    sectionPlane._state.dist = (-math.dotVec3(sectionPlane._state.pos, sectionPlane._state.dir))
    controls._setPos(pos)
  }

  set active(val) {
    this._active = !!val
    this._active ? this._activate() : this._deactivate()
  }

  _activate() {
    this._sectionPlanes = [
      this._createSectionPlane([0, 0, 0], [1, 0, 0]),
      this._createSectionPlane([0, 0, 0], [0, 1, 0]),
      this._createSectionPlane([0, 0, 0], [0, 0, 1]),
      this._createSectionPlane([0, 0, 0], [-1, 0, 0]),
      this._createSectionPlane([0, 0, 0], [0, -1, 0]),
      this._createSectionPlane([0, 0, 0], [0, 0, -1])
    ]
    this._bindEvents()
    this._visible = true
    this.viewer.scene.camera.ortho.far = 2000
    this.viewer.scene.camera.ortho.near = 0
    
    // const modelAabbs = Object.values(this.viewer.scene.models).map(model => model.aabb)
    const aabb = this.viewer.scene.getAABB(this.viewer.scene.objectIds)

    // console.log(aabb)
    // console.log(this.viewer.scene.getAABB(this.viewer.scene.objectIds))

    this._update(aabb)
  }
  
  _deactivate() {
    this._cube?.destroy()
    this.#_edges.forEach(edge => {
      edge.destroy()
      edge = null
    })
    this.#_edges.splice(0)
    this.#_edges = []
    this._triggers.forEach(trigger => {
      trigger.destroy()
      trigger = null
    })
    this._triggers.splice(0)
    this._triggers = []
    this._sectionPlanes.forEach(sectionPlane => {
      this._sectionPlanesPlugin.destroySectionPlane(sectionPlane.id)
    })
    this._sectionPlanes.splice(0)
    this._sectionPlanes = []
    // this._sectionPlanesPlugin.hideControl(this._sectionPlanesPlugin.getShownControl())
    this._unbindEvents()
    this._visible = false

    this._handleUpdate()
  }

  get active() {
    return this._active
  }

  get aabb() {
    return this._cube?.aabb
  }

  get visible() {
    return this._active && this._visible
  }

  set visible(val) {
    this._visible = !this.active ? false : !!val

    if (this._visible) {
      this._update(this.aabb)
    }
    else {
      (this._cube || {}).setVisible?.(false)
      this._triggers.forEach(trigger => trigger.destroy())
      this._sectionPlanesPlugin.hideControl(this._sectionPlanesPlugin.getShownControl())
      
      this._handleUpdate()
    }
  }

  _handleUpdate() {
    this.fire('update', this.DTO)
  }

  get DTO() {
    return {
      aabb: this.aabb, 
      active: this.active,
      visible: this.visible
    }
  }

  set aabb(val) {
    if (!this.active) return
    
    this._update(val)
    // this.visible = this.visible
  }
}

export { SectionCubePlugin }

`
  set active
    activate
      updateSectionPlanes
        set planes positions
        set planes active

      updateOther
        update cube
        update triggers

    deactivate
      destroy triggers
      destroy cube

      deactivate planes
  
  constructor
    create section planes plugin
    init
      create each axis section plane
      deactivate planes
      bind events
        bind each section plane pos event
          update cube
          update triggers

        bind scene hover entity
          show section plane control

  set visible
    false
      hide cube
      destroy triggers
    
    true
      restore cube
      create triggers
`