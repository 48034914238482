import { PivotPin } from "./pivotPin"
import { XeokitMediator } from "../XeokitMediator"
import { usePivotPinStore } from '@/pinia'
import { geometry } from "../plugins/geometry/geometry"

/*eslint-disable no-dupe-class-members*/
export class PivotPinController {

  static #pivotPin = null

  static get #store() { return usePivotPinStore() }
  static get pivotPinCoord() { return this.#store.pivotPinCoord }

  static createPivotPin() {
    this.#pivotPin = new PivotPin(XeokitMediator.viewer.scene)
  }

  static updateScale() {
    const scaleData = this.#getScaleData()
    this.#pivotPin.updateScale(scaleData.scale, scaleData.projMode)
  }
  
  static setPivotPinPosition(position) {
    this.#store.setPivotPinCoord(position)
    const distance = geometry.math.distance(XeokitMediator.viewer.scene.camera.eye, position)
    this.#pivotPin.updatePositionAndScale(position, distance)
  }

  static #getScaleData() {
    if (XeokitMediator.ProjectionMode.projectionMode == XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) {
      const distance = geometry.math.distance(XeokitMediator.viewer.scene.camera.eye, this.pivotPinCoord)
      return { scale: distance, projMode: "perspective" }
    }
    else if (XeokitMediator.ProjectionMode.projectionMode == XeokitMediator.ProjectionMode.Modes.ORTHO) {
      const orthoScale = XeokitMediator.viewer.scene.camera.ortho.scale
      return { scale: orthoScale, projMode: "ortho" }
    }
  }
}
