<template>
  <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 1V13H13V21H29V8.77778" stroke="#B5B5B5"/>
    <path d="M17 13L29 21" stroke="#B5B5B5"/>
    <path d="M1 1V13L13 21V8.77778" stroke="#B5B5B5"/>
    <path d="M17 1.00001L28.9112 9.02179" stroke="#FF0000"/>
    <path d="M1 1.00001L12.9112 9.02179" stroke="#FF0000"/>
    <path d="M5 5H24" stroke="#303030"/>
    <circle cx="1" cy="1" r="1" fill="#FF0000"/>
    <circle cx="17" cy="1" r="1" fill="#FF0000"/>
    <circle cx="13" cy="9" r="1" fill="#FF0000"/>
    <circle cx="29" cy="9" r="1" fill="#FF0000"/>
    <rect x="5.5" y="3.5" width="3" height="3" fill="white" stroke="#303030"/>
    <rect x="21.5" y="3.5" width="3" height="3" fill="white" stroke="#303030"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>