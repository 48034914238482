// let host=process.env.VUE_APP_MYHOST
let host = window.settings.server.url
let cloud = window.settings.cloud.url
let iotHost = window.settings.iot.url
//let social = window.settings.social
let pluginsHost = window.settings.plugins.url
let notification = window.settings.notification.url
let drawing = window.settings.drawing.url
let telemetry = window.settings.telemetry.url
// let social = window.settings.social

export const config = {
  host,
  cloud,
  apiUrl: host + 'pirsbim/',
  iotUrl: iotHost + 'iot/',
  pluginsUrl: pluginsHost,
  notificationUrl: notification + 'notification/',
  drawingUrl: drawing + 'drawings/',
  telemetryUrl: telemetry + 'telemetry/'

  // socialUrl: host+'pirsbim/oauth/social/',
  // ...social
  // cmsUrl: host+'unicorn/',
  // authUrl: host+'pirsbim/oauth/token'
}
