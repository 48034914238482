import { config } from '@/_helpers'
import { authUserService } from '@/_services'
import { PresetKey } from '@/utils'
import axios from 'axios'

import { App } from '@app/App'
import { AlertService } from '@app/AlertService'

import * as NodeCache from 'node-cache'
import i18n from '@/plugins/i18n'

import router from '@/router'
import store from '@/store'


let cache = new NodeCache()

axios.Axios.prototype['cacheGet'] = function(url, config = {}) {
  config.headers = { ...config.headers, 'If-None-Match': '' }
  return this.get(url, config)
}

let iot_options = {
  baseURL: config.iotUrl,
  headers: { 'Content-Type': 'application/json' }
}

export const $_iot_server = axios.create(iot_options)

let plugin_options = {
  baseURL: config.pluginsUrl,
  headers: { 'Content-Type': 'application/json' }
}

export const $_plugin_server = axios.create(plugin_options)

let options = {
  baseURL: config.apiUrl,
  headers: { 'Content-Type': 'application/json' }
}

export const $_app_server_noAuth = axios.create(options)

export const $_app_server = axios.create(options)

let notification_options = {
  baseURL: config.notificationUrl,
  headers: { 
    'Content-Type': 'application/json',
 }
}

export const $_notification_server = axios.create(notification_options)

let drawing_options = {
  baseURL: config.drawingUrl,
  headers: { 
    'Content-Type': 'application/json',
 }
}

export const $_drawing_server = axios.create(drawing_options)

const telemetry_options = {
  baseURL: config.telemetryUrl,
  headers: {
    'Content-Type': 'application/json'
  }
}

export const $_telemetry_server = axios.create(telemetry_options)

export const $_drawing_server_noAuth = axios.create(drawing_options)

export const $_notification_server_noAuth = axios.create(notification_options)

const makeBearer = () => `Bearer ${App.auth.accessToken}`

$_app_server.interceptors.request.use(requestInterceptor)
$_app_server.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

$_drawing_server.interceptors.request.use(requestInterceptor)
$_drawing_server.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

$_telemetry_server.interceptors.request.use(requestInterceptor)
$_telemetry_server.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

$_notification_server.interceptors.request.use(requestInterceptor)
$_notification_server.interceptors.response.use(responseInterceptor, responseErrorInterceptorNotification)

function requestInterceptor (config) {
  if ('If-None-Match' in config.headers) {
    const cachedResult = cacheByConfig(config)
    config.headers['If-None-Match'] = cachedResult ? cachedResult.etag : ''
  }

  if (router && router.currentRoute && router.currentRoute.params &&  router.currentRoute.name && router.currentRoute.name.indexOf("project") != -1) {
    config.headers["project"] = router.currentRoute.params.uuid
  } 
  else if (store.getters["project/projectUuid"]) {
    config.headers["project"] = store.getters["project/projectUuid"]
  }





  let isCloudRequest = config[PresetKey.cloudy] && App.version.isCloud
  if (isCloudRequest) {
    config.url = '/cloud' + config.url
  }
  else if (!config[PresetKey.guest] && !App.version.isCloud) {
    config.headers['Authorization'] = makeBearer()
  }
  return config
}

function responseInterceptor (response) {
  if (response.headers.etag) {
    const uuid = uuidByConfig(response.config)
    cache.set(uuid, { etag: response.headers.etag, value: response.data })
  }
  return response
}

function responseErrorInterceptorNotification (error) {
  if (error.response !== undefined) {
    if (error.response.status === 401 && error.config.url.indexOf("oauth/token") != 0) {
      return authUserService.refreshToken().then(() => {
        error.config.headers.Authorization = makeBearer()
        error.config.baseURL = undefined;
        return $_notification_server.request(error.config);
      })
      .catch(() => {
        //if (!App.version.isCloud) authUserService.logout;
        if (!App.version.isCloud) {
          localStorage.removeItem("bimit_social_type")
          $_app_server.defaults.headers.common.Authorization = null
          App.auth.clear()    
          window.location = "/";
        }
      } );
    } else
      return responseErrorInterceptor(error);
  }
  return Promise.reject(error)
}

let atTokenRefreshing = false
let tokenRefreshingPromise = null

function responseErrorInterceptor (error) {
  if (error.response !== undefined) {
    if (error.response.status === 304) {
      const cachedResult = cacheByConfig(error.response.config)
      if (cachedResult) { 
        const newResponse = error.response
        newResponse.status = 203
        newResponse.data = cachedResult.value
        return Promise.resolve(newResponse)
      }
    }

    if (error.response.status === 500 && !App.auth.accessToken) {
      localStorage.setItem("locationHref", location.href)
      authUserService.logout()
    }

    if (error.response.status === 401 && error.config.url.indexOf("oauth/token") != 0) {
      if (atTokenRefreshing) {
        return tokenRefreshingPromise.then(() => $_app_server.request(error.config))
      }
      atTokenRefreshing = true

      return tokenRefreshingPromise = authUserService.refreshToken().then(() => {
        atTokenRefreshing = false
        
        error.config.headers.Authorization = makeBearer()
        error.config.baseURL = undefined;
        return $_app_server.request(error.config);
      })
      .catch(() => {
        console.log("test");
        localStorage.setItem("locationHref", location.href)
        if (!App.version.isCloud) authUserService.logout()
      } );
    }

    if (error.response.status === 403 || error.response.status === 400) {
      
      if (error.config.url.indexOf("oauth/token") == 0) {
        //authUserService.logout()
        localStorage.setItem("locationHref", location.href)
        window.location = "/login?fromext";
      } else {  
        let locale = localStorage.getItem("locale") || "ru"
        if (error.response.data.error === "invalid_grant")
          error.response.data.error_description = i18n.t("error.loginPassword", locale)
        else if (error.response.data.error === "project_forbidden") {
          router.push('/dashboard').catch(()=>{})
        }
        else if (error.response.data.error === "profile_not_found") 
          error.response.data.error_description = i18n.t("error.profileNotFound", locale)
        AlertService.error(error.response)
      }
    }
  }
  return Promise.reject(error)
}

function uuidByConfig (config) {
  return config.url.startsWith(config.baseURL) ? config.url : config.baseURL + config.url
}

function cacheByConfig (config) {
  const uuid = uuidByConfig(config)
  return cache.get(uuid)
}