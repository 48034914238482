import { api } from '@/api'

const getDefaultState = () => {
  return {
    projectDicts: [],
    selectedProjectDict: null,
    selectedProjectDictAttributeTree: [],
    selectedAttribute: null,
    selectedAttributeElements: [],
    isTreeInPreparation: false,
    selectedElement: [],
    copyMatchingAttributes: null,
    showPanel: false,
    editModelAttribute: false,
    editingMode: false,
    count: 0,
    openUniversalAttributesPanel: false,
  }
}

const state = getDefaultState()

export default {
  namespaced: true,
  
  state,

  mutations: {
    resetState(state) { Object.assign(state, getDefaultState()) },

    setTreeInPreparation: (state, flag) => {
      state.isTreeInPreparation = flag
    },

    setCopyMatchingAttributes(state, copyName) {
      state.count++
      state.copyMatchingAttributes = copyName
    },

    setShowPanel(state, boolean) {
      state.showPanel = boolean
    },

    setEditModelAttribute(state, flag) {
      state.editModelAttribute = flag
    },

    setEditingMode(state, flag) {
      state.editingMode = flag
    },

    setOpenUniversalAttributesPanel(state, flag) {
      state.openUniversalAttributesPanel = flag
    },
  },

  actions: {
    loadProjectDicts({ state, rootGetters }) {
      const projectUuid = rootGetters['project/projectUuid']
      
      api.projectDict.getProjectDicts(projectUuid).then(projectDicts => {
        state.projectDicts = projectDicts
      })
    },

    createProjectDict({ state, commit, dispatch }, projectDict) {
      commit('setTreeInPreparation', true)
      api.projectDict.createProjectDict(projectDict).then((data) => {
        state.projectDicts.push(data)
        state.selectedProjectDict = data.uuid
        dispatch('updateSelectedProjectDictAttributeTree')
        commit('setTreeInPreparation', false)
      })
    },

    importProjectDict({ state, dispatch }, formData) {
      api.projectDict.importDictionary(formData).then((data) => {
        for(let i; i < data.length; i++){
          state.projectDict.push(data[i])
        }
        dispatch('updateSelectedProjectDictAttributeTree')
      })
    },

    updateSelectedProjectDictAttributeTree({ state, commit }) {
      const selectedProjectDict = state.selectedProjectDict
      commit('setTreeInPreparation', true)
      if (selectedProjectDict) {
        api.projectDict.getProjectDictAttributeTree(selectedProjectDict).then(attributeTree => {
          state.selectedProjectDictAttributeTree = attributeTree
          commit('setTreeInPreparation', false)
        })
      } 
      else {
        state.selectedProjectDictAttributeTree = []
        state.selectedAttribute = null
      }
    },

    createAttributeGroup({ dispatch }, { attributeGroup, projectDictUuid }) {
      api.projectDict.createAttributeGroup(attributeGroup, projectDictUuid).then(() => {
        dispatch('updateSelectedProjectDictAttributeTree')
      })
    },

    createAttribute({ dispatch }, {attribute, attributeGroupUuid}) {
      api.projectDict.createAttribute(attribute, attributeGroupUuid).then((data) => {
        dispatch('updateSelectedProjectDictAttributeTree')
        dispatch('setSelectedAttribute', data)
      })
    },

    setSelectedProjectDict({ state, dispatch }, projectDict) {
      state.selectedProjectDict = projectDict
      state.selectedAttribute = null
      dispatch('updateSelectedProjectDictAttributeTree')
    },

    setSelectedAttribute({ state, commit }, attribute) {
      commit('setEditModelAttribute', false)

      return api.projectDict.getAttribute(attribute.uuid).then((data) => {
        state.selectedAttribute = data
      })
    },

    editAttribute({ state, dispatch, commit }, attribute) {
      commit('setTreeInPreparation', true)
      api.projectDict.editAttribute(attribute).then((update) => {
        if (state.selectedAttribute && state.selectedAttribute.uuid === update.uuid){
          state.selectedAttribute = update
        }
        dispatch('updateSelectedProjectDictAttributeTree')
        commit('setTreeInPreparation', false)
      })
    },

    setSelectedElements({state}, elementUuid) {
      state.selectedElement = elementUuid
    },

    deleteElement({dispatch}, elementUuid) {
      api.projectDict.deleteItem(elementUuid).then(() => {
        dispatch('updateSelectedProjectDictAttributeTree')
        state.selectedAttribute = null
      })
    },

    classificateAttribute({ commit }, { projectUuid, attributeDictionaryUuid }) {
      commit('setTreeInPreparation', true)
      api.projectDict.classificate({projectUuid, attributeDictionaryUuid}).then(() => {
        commit('setTreeInPreparation', false)
      })
    },

    deleteProjectDict({state, commit, dispatch}, dictUuid) {
      commit('setTreeInPreparation', true)
      api.projectDict.deleteDict(dictUuid).then(() => {
        dispatch('loadProjectDicts')
        state.selectedProjectDict = null
        state.selectedProjectDictAttributeTree = []
        commit('setTreeInPreparation', false)
      })
    },

    classificateGroupAttribute({ commit }, {projectUuid, projectAttributeDictionaryUuid, attributeDictionaryUuid}) {
      commit('setTreeInPreparation', true)
      api.projectDict.classificate({ projectUuid, projectAttributeDictionaryUuid, attributeDictionaryUuid }).then(() => {
        commit('setTreeInPreparation', false)
      })
    }
  }
}