import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import {
  Mesh,
  ReadableGeometry,
  PhongMaterial,
  EmphasisMaterial,
  math,
  buildBoxGeometry,
  EdgeMaterial,
  buildSphereGeometry,
  buildCylinderGeometry,
} from '@xeokit/xeokit-sdk'
import { BuildVectorTextGeometry } from '../buildVectorTextGeometry'
import { BuildDashDottedLineGeometry } from '../buildDashDottedLineGeometry'

/*eslint-disable no-dupe-class-members*/
export class SceneObjects {
  /** Создание (отрисовка) отрезка на сцене
   * @param {Object} cfg
   * @param {Array<Array<Number>>} [cfg.segmentPositions = []] Координаты [originWorldPos, targetWorldPos] отрезка
   * @param  {Array<Number>} [cfg.segmentColor = [1, 0, 0]] Цвет отрезка [r, g, b].
   */
  static createSegmentMesh(cfg) {
    const segmentPositions = cfg.segmentPositions
    const segmentColor = cfg.segmentColor || [1, 0, 0]
    const clippable = cfg.clippable ?? true

    const originWorldPos = segmentPositions[0]
    const targetWorldPos = segmentPositions[1]

    const origin = originWorldPos

    const selected = cfg.selected ?? true

    const p1 = math.vec3()
    const p2 = math.vec3()
    math.subVec3(originWorldPos, origin, p1)
    math.subVec3(targetWorldPos, origin, p2)

    const segmentMesh = new (function () {
      const segment = new Mesh(XeokitMediator.viewer.scene, {
        geometry: new ReadableGeometry(XeokitMediator.viewer.scene, {
          primitive: 'lines',
          positions: [0, 0, 0, 0, 0, 0],
          indices: [0, 1],
        }),
        material: new PhongMaterial(XeokitMediator.viewer.scene, {
          emissive: segmentColor,
          diffuse: [0, 0, 0],
          ambient: [0, 0, 0],
          lineWidth: 2,
        }),
        selectedMaterial: new EmphasisMaterial(XeokitMediator.viewer.scene, {
          fill: false,
          edges: true,
          edgeColor: segmentColor,
          edgeAlpha: 1,
          edgeWidth: 10,
        }),
        origin: origin,
        clippable: clippable,
        pickable: false,
        selected: selected,
      })

      let segmentPositions = new Float32Array(6)

      this.setSegmentPositions = function (originWorldPos, targetWorldPos) {
        this.originWorldPos = math.vec3()
        this.targetWorldPos = math.vec3()

        math.addVec3(originWorldPos, segment.origin, this.originWorldPos)
        math.addVec3(targetWorldPos, segment.origin, this.targetWorldPos)

        segmentPositions[0] = originWorldPos[0] // Origin
        segmentPositions[1] = originWorldPos[1]
        segmentPositions[2] = originWorldPos[2]
        segmentPositions[3] = targetWorldPos[0] // Direction
        segmentPositions[4] = targetWorldPos[1]
        segmentPositions[5] = targetWorldPos[2]

        segment.geometry.positions = segmentPositions
      }

      this.destroy = function () {
        segment?.destroy()
      }
    })()

    const segmentOriginWorldPos = new Float32Array([p1[0], p1[1], p1[2]])
    const segmentTargetWorldPos = new Float32Array([p2[0], p2[1], p2[2]])
    segmentMesh.setSegmentPositions(segmentOriginWorldPos, segmentTargetWorldPos)

    return segmentMesh
  }

  /** Создание (отрисовка) штрихпунктирного отрезка на сцене
   * @param {Object} cfg
   * @param {Array<Array<Number>>} cfg.segmentPositions Координаты [originWorldPos, targetWorldPos] отрезка
   * @param {Number} cfg.unitNumber Количество штрихпунктирных делений (делений 6:1:1:1)
   */
  static createDashDottedSegmentMesh(cfg) {
    const segmentPositions = cfg.segmentPositions
    const unitNumber = cfg.unitNumber ?? 10
    const segmentColor = cfg.segmentColor ?? [1, 1, 1]
    const selected = cfg.selected ?? false
    const origin = cfg.origin ?? segmentPositions[0]

    const p1 = math.vec3()
    const p2 = math.vec3()
    math.subVec3(segmentPositions[0], origin, p1)
    math.subVec3(segmentPositions[1], origin, p2)
    
    const segmentMesh = new (function () {
      const segment = new Mesh(XeokitMediator.viewer.scene, {
        geometry: new ReadableGeometry(
          XeokitMediator.viewer.scene,
          BuildDashDottedLineGeometry.buildDashDottedLineGeom({ positions: [p1, p2], unitNumber: unitNumber })
        ),
        material: new PhongMaterial(XeokitMediator.viewer.scene, {
          emissive: segmentColor,
          diffuse: [0, 0, 0],
          ambient: [0, 0, 0],
          lineWidth: 2,
        }),
        selectedMaterial: new EmphasisMaterial(XeokitMediator.viewer.scene, {
          fill: false,
          edges: true,
          edgeColor: segmentColor,
          edgeAlpha: 1,
        }),
        origin: origin,

        pickable: false,
        selected: selected,
      })

      this.destroy = function () {
        segment?.destroy()
      }
    })()

    return segmentMesh
  }

  /** Создание (отрисовка) aabb на сцене
   * @param {Object} cfg
   * @param {Array<Number>} [cfg.aabb = []] aabb координаты
   */
  static createAabbMesh(cfg) {
    const aabbOrigin = cfg.aabb
    let aabb = [...cfg.aabb]

    const alphaMode = cfg.alphaMode ?? "blend"
    const origin = [aabb[0], aabb[1], aabb[2]]

    aabb[0] -= origin[0]
    aabb[1] -= origin[1]
    aabb[2] -= origin[2]
    aabb[3] -= origin[0]
    aabb[4] -= origin[1]
    aabb[5] -= origin[2]

    const xSize = Math.sqrt(Math.pow(aabb[3] - aabb[0], 2)) / 2 || 0.005
    const ySize = Math.sqrt(Math.pow(aabb[4] - aabb[1], 2)) / 2 || 0.005
    const zSize = Math.sqrt(Math.pow(aabb[5] - aabb[2], 2)) / 2 || 0.005

    const aabbMesh = new (function () {
      const aabbMesh = new Mesh(XeokitMediator.viewer.scene, {
        edges: true,
        pickable: false,
        clippable: false,
        collidable: false,
        visible: this.visible,

        geometry: new ReadableGeometry(
          XeokitMediator.viewer.scene,
          buildBoxGeometry({
            xSize,
            ySize,
            zSize,
            center: math.getAABB3Center(aabb),
          })
        ),

        material: new PhongMaterial(XeokitMediator.viewer.scene, {
          diffuse: [0.4, 0.4, 1.0],
          ambient: [0.9, 0.3, 0.9],
          shininess: 30,
          alpha: 0.2,
          alphaMode: alphaMode,
        }),

        edgeMaterial: new EdgeMaterial(XeokitMediator.viewer.scene, {
          edgeColor: [0.23137254901960785, 0.5764705882352941, 0.6862745098039216],
          edgeAlpha: 1.0,
          edgeWidth: 1,
        }),

        origin: origin,
      })

      this.aabb = aabbOrigin

      this.visible = aabbMesh.visible

      this.destroy = function () {
        aabbMesh?.destroy()
      }

      this.setVisible = (visible) => {
        aabbMesh.visible = visible
      }
    })(this)

    return aabbMesh
  }

  /** Создание (отрисовка) сферы на сцене
   * @param {Object} cfg
   * @param {Array<Number>} [cfg.worldPos] Координата центра сферы
   * @param {Number} [cfg.radius = 0.3] Радиус сферы
   * @param {Boolean} [cfg.visible = true] Видимость сферы
   * @param {Array<Number>} [cfg.scale = [1, 1, 1]] Масштаб сферы
   * @param {Array<Number>} [cfg.color = [0.3, 1.0, 0.3]] Цвет сферы
   * @param {Array<Number>} [cfg.clippable = true] Можно ли обрезать сферу
   * @param {Array<Number>} [cfg.pickable = false] Можно ли пикать сферу
   * @param {Number} [cfg.heightSegments = 30] Number of latitudinal bands
   * @param {Number} [cfg.widthSegments = 30] Number of longitudinal bands
   */
  static createSphereMesh(cfg) {
    const position = [0, 0, 0]
    const origin = cfg.worldPos ?? [0, 0, 0]

    const radius = cfg.radius ?? 0.03
    const visible = cfg.visible ?? true
    const scale = cfg.scale ?? [1, 1, 1]
    const diffuse = cfg.color ?? [0.3, 1.0, 0.3]
    const heightSegments = cfg.heightSegments ?? 10
    const widthSegments = cfg.widthSegments ?? 10
    const pickable = cfg.pickable ?? false
    const clippable = cfg.clippable ?? true

    const geometry = new ReadableGeometry(
      XeokitMediator.viewer.scene,
      buildSphereGeometry({
        radius: radius,
        heightSegments: heightSegments,
        widthSegments: widthSegments,
      })
    )

    const material = cfg.material ? cfg.material : new PhongMaterial(XeokitMediator.viewer.scene, {
          diffuse: diffuse,
          ambient: [0.0, 0.0, 0.0],
          specular: [0.6, 0.6, 0.3],
          shininess: 80,
          lineWidth: 1,
        })

    const sphereMesh = new (function () {
      const sphereMesh = new Mesh(XeokitMediator.viewer.scene, {
        geometry: geometry,
        origin: origin,
        position: position,
        material: material,
        pickable: pickable,
        collidable: false,
        clippable: clippable,
        visible: visible,
        scale: scale,
      })

      this.id = sphereMesh.id

      this.destroy = function () {
        sphereMesh?.destroy()
      }

      this.update = function (cfg) {
        if (cfg.isPositionScaling) {
          const pos = sphereMesh.origin
          sphereMesh.origin = [pos[0] * scale[0], pos[1] * scale[1], pos[2] * scale[2]]
        }
        sphereMesh.scale = cfg.scale ? cfg.scale : sphereMesh.scale
      }
    })()

    return sphereMesh
  }

  static createCylinderMesh(cfg) {
    const radiusTop = cfg.radiusTop ?? 0.1
    const radiusBottom = cfg.radiusBottom ?? 0.5
    const height = cfg.height ?? 1

    const position = cfg.offset ?? [0, 0, 0]
    const origin = cfg.worldPos ?? [0, 0, 0]
    const visible = cfg.visible ?? true
    const scale = cfg.scale ?? [1, 1, 1]
    const color = cfg.color ?? [0.3, 1.0, 0.3]
    const rotation = cfg.rotation ?? [0, 0, 0]
    const radialSegments = cfg.radialSegments ?? 10
    const clippable = cfg.clippable ?? true
    const highlighted = cfg.highlighted ?? false

    const geometry = new ReadableGeometry(
      XeokitMediator.viewer.scene,
      buildCylinderGeometry({
        radiusTop: radiusTop,
        radiusBottom: radiusBottom,
        height: height,
        radialSegments: radialSegments,
        heightSegments: 1,
        openEnded: false,
      })
    )

    const material = new PhongMaterial(XeokitMediator.viewer.scene, {
      diffuse: color,
      emissive: color,
      ambient: [0.0, 0.0, 0.0],
      specular: [0.6, 0.6, 0.3],
      shininess: 80,
      lineWidth: 2,
    })

    const highlightMaterial = new EmphasisMaterial(XeokitMediator.viewer.scene, {
      edges: false,
      fill: true,
      fillColor: color,
      fillAlpha: 0.0,
    })

    const cylinderMesh = new (function () {
      const offset = position
      const cylinderMesh = new Mesh(XeokitMediator.viewer.scene, {
        geometry: geometry,
        origin: origin,
        position: cfg.isPositionScaling ? [position[0] * scale[0], position[1] * scale[1], position[2] * scale[2]] : position,
        rotation: rotation,
        material: material,
        highlighted: highlighted,
        highlightMaterial: highlightMaterial,
        pickable: false,
        collidable: false,
        visible: visible,
        scale: scale,
        clippable: clippable
      })

      this.destroy = function () {
        cylinderMesh?.destroy()
      }

      this.update = function (cfg) {
        const pos = cfg.offset ?? offset
        const scale = cfg.scale ?? cylinderMesh.scale
        if (cfg.isPositionScaling) {
          cylinderMesh.position = [pos[0] * scale[0], pos[1] * scale[1], pos[2] * scale[2]]
        } 
        else {
          cylinderMesh.position = pos
        }
        cylinderMesh.scale = scale
      }
    })()

    return cylinderMesh
  }

  static createTextMesh(cfg) {
    const text = cfg.text ?? ""
    const size = cfg.size ?? 1

    const position = cfg.offset ?? [0, 0, 0]
    const origin = cfg.worldPos ?? [0, 0, 0]
    const visible = cfg.visible ?? true
    const scale = cfg.scale ?? [1, 1, 1]
    const emissive = cfg.color ?? [0.3, 1.0, 0.3]
    const billboard = cfg.billboard ?? 'none'

    const rotationMatrix = cfg.rotationMatrix ?? null

    const geometry = new ReadableGeometry(
      XeokitMediator.viewer.scene,
      BuildVectorTextGeometry.buildVectorTextGeom({
        text: text,
        size: size,
        matrix: rotationMatrix,
      })
    )

    const material = new PhongMaterial(XeokitMediator.viewer.scene, {
      emissive: emissive,
      ambient: [0.0, 0.0, 0.0],
      specular: [0.6, 0.6, 0.3],
      shininess: 80,
      lineWidth: 1,
    })

    const textMesh = new (function () {
      const offset = position
      const textMesh = new Mesh(XeokitMediator.viewer.scene, {
        geometry: geometry,
        origin: origin,
        position: position,
        material: material,
        pickable: false,
        collidable: false,
        visible: visible,
        scale: scale,
        billboard: billboard,
      })

      this.destroy = function () {
        textMesh?.destroy()
      }

      this.update = function (cfg) {
        const pos = cfg.offset ?? offset
        const scale = cfg.scale ?? textMesh.scale
        if (cfg.isPositionScaling) {
          textMesh.position = [pos[0] * scale[0], pos[1] * scale[1], pos[2] * scale[2]]
        } 
        else {
          textMesh.position = pos
        }
        textMesh.scale = cfg.scale ?? textMesh.scale
      }
    })()

    return textMesh
  }

  static createViewPointMeshes(scale, worldPos) {
    const meshes = new (function () {
      const shaftX = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.015,
        radiusBottom: 0.015,
        height: 0.24,
        worldPos: worldPos,
        offset: [0.12, 0, 0],
        scale: scale,
        rotation: [0, 0, -90],
        color: [1.0, 0.0, 0.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      const shaftY = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.015,
        radiusBottom: 0.015,
        height: 0.24,
        worldPos: worldPos,
        offset: [0, 0.12, 0],
        scale: scale,
        color: [0.0, 1.0, 0.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      const shaftZ = XeokitMediator.SceneObjects.createCylinderMesh({
        radiusTop: 0.015,
        radiusBottom: 0.015,
        height: 0.24,
        worldPos: worldPos,
        offset: [0, 0, 0.12],
        scale: scale,
        rotation: [90, 0, 0],
        color: [0.0, 0.0, 1.0],
        isPositionScaling: true,
        radialSegments: 3,
        clippable: false,
        highlighted: true
      })

      // Когда на сцене - вызов каждый onSceneTick с новым scale
      this.updateScale = function (scale) {
        shaftX.update({ scale: scale, isPositionScaling: true })
        shaftY.update({ scale: scale, isPositionScaling: true })
        shaftZ.update({ scale: scale, isPositionScaling: true })
      }

      this.destroy = function () {
        shaftX?.destroy()
        shaftY?.destroy()
        shaftZ?.destroy()
      }
    })()

    return meshes
  }

  static lightMapTest() {
    const pointGeom = new ReadableGeometry(XeokitMediator.viewer.scene, buildSphereGeometry({
      center: [0, 0, 0],
      radius: 0.1
    }))

    const sphereGeom = new ReadableGeometry(XeokitMediator.viewer.scene, buildSphereGeometry({
      center: [0, 0, 0],
      radius: 1
    }))

    const sphereEmphMaterial = new EmphasisMaterial(XeokitMediator.viewer.scene, {
      edges: true,
      edgeColor: [1, 1, 1],
      fill: false,
      edgeAlpha: 1
    })

    const spherePhongMaterial = new PhongMaterial(XeokitMediator.viewer.scene)

    this.sphereMesh = new Mesh(XeokitMediator.viewer.scene, {
      geometry: sphereGeom,
      xrayMaterial: sphereEmphMaterial,
      xrayed: true,
      material: spherePhongMaterial,
      heightSegments: 10,
      widthSegments: 10
    })

    const yellowHighlight = new EmphasisMaterial(XeokitMediator.viewer.scene, {
      edges: false,
      fill: true,
      fillColor: [1000, 1000, 0],
      fillAlpha: 1,
      backfaces: false,
      glowThrough: true
    })

    const yellowPhong = new PhongMaterial(XeokitMediator.viewer.scene, {
      diffuse: [0, 0, 0],
      emissive: [0, 0, 0],
      ambient: [0, 0, 0],
      specular: [0, 0, 0],
      shininess: 128,
      alpha: 0,
    })

    const testSphereGeom = new ReadableGeometry(XeokitMediator.viewer.scene, buildSphereGeometry({
      center: [0, 0, 0],
      radius: 10,
      heightSegments: 1000,
      widthSegments: 1000
    }))

    this.testSphere = new Mesh(XeokitMediator.viewer.scene, {
      geometry: testSphereGeom,
      selectedMaterial: yellowHighlight,
      selected: true,
      material: yellowPhong,
      visible: false,
      receivesShadow: false
    })

    this.pointXMesh = new Mesh(XeokitMediator.viewer.scene, {
      geometry: pointGeom,
      colorize: [1, 0, 0],
      position: [-0.57735, -0.57735, 0.57735]
    })

    this.pointYMesh = new Mesh(XeokitMediator.viewer.scene, {
      geometry: pointGeom,
      colorize: [0, 1, 0],
      position: [0, 1, 0]
    })

    this.pointZMesh = new Mesh(XeokitMediator.viewer.scene, {
      geometry: pointGeom,
      colorize: [0, 0, 1],
      position: [0.57735, -0.57735, -0.57735]
    })

    this.pointCenterMesh = new Mesh(XeokitMediator.viewer.scene, {
      geometry: pointGeom,
      colorize: [1, 1, 1],
      position: [0, 0, 0]
    })

    console.log("X", this.pointXMesh)
    console.log("Y", this.pointYMesh)
    console.log("Z", this.pointZMesh)
    console.log("test", this.testSphere)
  }
}
