import { XeokitMediator } from "../XeokitMediator"

class Models {
  static get scene() {
    return XeokitMediator.viewer.scene
  }

  static get models() {
    return this.scene.modelIds.map(modelId => this.scene.models[modelId])
  }

  static get modelIds() {
    return this.scene.modelIds
  }

  static get modelsMap() {
    return this.scene.models
  }

  static setModelsPickable(modelIds, pickable) {
    modelIds.forEach(modelId => {
      this.modelsMap[modelId] && (this.modelsMap[modelId].pickable = pickable)
    })
  }

  static setModelsVisible(modelIds, visible) {
    modelIds.forEach(modelId => {
      this.modelsMap[modelId] && (this.modelsMap[modelId].visible = visible)
    })
  }

  static setModelsXrayed(modelIds, xrayed) {
    modelIds.forEach(modelId => {
      this.modelsMap[modelId] && (this.modelsMap[modelId].xrayed = xrayed)
    })
  }

  static setModelsColorized(modelIds, colorized) {
    modelIds.forEach(modelId => {
      this.modelsMap[modelId] && (this.modelsMap[modelId].colorized = colorized)
    })
  }

  static setModelsSelected(modelIds, selected) {
    modelIds.forEach(modelId => {
      this.modelsMap[modelId] && (this.modelsMap[modelId].selected = selected)
    }) 
  }

  static saveModelsMemento() {
    XeokitMediator.objectsMemento.saveObjects(XeokitMediator.viewer.scene)
  }

  static restoreModels() {
    XeokitMediator.objectsMemento.restoreObjects(XeokitMediator.viewer.scene)
    XeokitMediator.ElementsSelection.rehighlightElements()
  }
}

export { Models }