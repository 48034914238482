export class Authorization {
  #_accessToken = null
  #_refreshToken = null
  #_roles = null

  get accessToken () {
//    return localStorage.getItem('access_token')
    let token = localStorage.getItem('access_token')!== undefined ? localStorage.getItem('access_token') : null
    return (this.#_accessToken = this.#_accessToken || token)
  }
  
  get refreshToken () {
//    return localStorage.getItem('refresh_token')
    return (this.#_refreshToken = this.#_refreshToken || localStorage.getItem('refresh_token'))
  }

  get roles () {
    return (this.#_roles = this.#_roles || localStorage.getItem('roles') || [])
  }

  get loginType () {
    return localStorage.getItem("bimit_login_type") ? localStorage.getItem("bimit_login_type") : "new"
  }

  get fio () {
    return localStorage.getItem("fio") ? localStorage.getItem("fio") : ""
  }

  use (data = {}) {

    let { access_token, grants, fio, refresh_token, login_type } = data
    this.#_accessToken = access_token
    localStorage.setItem('access_token', access_token)

    login_type = login_type ? login_type : "local"
    localStorage.setItem("bimit_login_type", login_type)
    //alert(login_type);
    this.#_refreshToken = refresh_token
    localStorage.setItem('refresh_token', refresh_token)
    localStorage.setItem('grants', grants)
    localStorage.setItem('fio', fio.replace(/"/gi, ''))

    
    //alert(refresh_token);
    
    this.#_roles = JSON.parse(grants).map(item => item.grantType).join(',')
    localStorage.setItem('roles', this.#_roles)

    //alert(refresh_token);
  }

  get isAdmin () {
    return this.roles.indexOf("ADMIN") != -1 ? true : false 
  }

  clear () {
    this.#_accessToken = null
    this.#_refreshToken = null
    this.#_roles = null

    let loginName = localStorage.getItem("loginName")
    let fio = localStorage.getItem("fio")
    let nextcloud = localStorage.getItem("nextCloud")
    let locale = localStorage.getItem("locale")
    let login_type = localStorage.getItem("bimit_login_type")
    localStorage.clear()
    if (locale) localStorage.setItem("locale", locale)
    if (fio) localStorage.setItem("fio", fio)
    if (login_type) localStorage.setItem("bimit_login_type", login_type)
    if (loginName && loginName.indexOf("social") == -1) {
      localStorage.setItem("loginName", loginName)
      if (nextcloud) localStorage.setItem("nextCloud", nextcloud)
    }
  }

 }