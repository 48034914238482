import { usePickCoordinateViewerStore } from "@/pinia"
import { CoordinatePinsPlugin } from "./coordinatePinsPlugin"
import { CoordinatePinAddingMode } from "./coordinatePinAddingMode"

/*eslint-disable no-dupe-class-members*/
export class PickCoordinate {

  /**
   * @type { CoordinatePinsPlugin }
   */
  static #coordinatePinsPlugin = null

  static get isPickCoordinateActive() {
    return usePickCoordinateViewerStore().isPickCoordinateActive
  }

  static get coordinatePins() {
    return this.#coordinatePinsPlugin?.coordinatePins || {}
  }

  static setActive(value) {
    if (value == this.isPickCoordinateActive) return
    
    if (value == true) { CoordinatePinAddingMode.activate() }
    else CoordinatePinAddingMode.deactivate()

    usePickCoordinateViewerStore().setPickCoordinateActive(value)
  }

  static createCoordinatePin(cfg) {
    return this.#coordinatePinsPlugin.createCoordinatePin(cfg)
  }

  static createHoveringPin(cfg) {
    return this.#coordinatePinsPlugin.createHoveringPin(cfg)
  }

  static destroyCoordinatePins(coordinatePins = []) {
    this.#coordinatePinsPlugin.destroyCoordinatePins(coordinatePins)
  }

  static setCoordinatePinsVisible(coordinatePins = [], visible) {
    this.#coordinatePinsPlugin.setCoordinatePinsVisible(coordinatePins, visible)
  }

  static init() {
    this.#coordinatePinsPlugin = new CoordinatePinsPlugin()
  }
}