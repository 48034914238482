import { projectService } from "@/_services"
import { defineStore } from "pinia"
import store from "@/store"

const UNGROUPABLE_LONGNAME = 'UNGROUPABLE'

const FLOORS_STATE = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
}

const useSectionCubeStore = defineStore('sectionCube', {
  state: () => ({
    floors: [],
    sectionCubes: [],
    selectedFloor: null,

    visible: false,
    active: false,

    FLOORS_STATE: FLOORS_STATE.INITIAL,
  }),

  actions: {
    setActive(active) {
      this.active = active
    },

    setVisible(visible) {
      this.visible = visible
    },

    createSectionCube(sectionCube) {
      sectionCube.id ||= this.sectionCubes.length + 1
      this.sectionCubes.push(sectionCube)
    },

    deleteSectionCube(id) {
      this.sectionCubes = this.sectionCubes.filter(sectionCube => sectionCube.id !== id)
    },

    patchSectionCube(data) {
      const idx = this.sectionCubes.findIndex(sectionCube => sectionCube.id === data.id)
      if (idx < 0) return

      const patched = Object.assign(this.sectionCubes[idx], data)
      return patched
    },

    async loadFloors() {
      const projectUuid = store.getters['project/projectUuid']
      this.FLOORS_STATE = FLOORS_STATE.LOADING
      const data = await projectService.loadElementTree(projectUuid, 4)
      this.floors = data.filter(floor => floor.longName !== UNGROUPABLE_LONGNAME)
      this.FLOORS_STATE = FLOORS_STATE.LOADED
    },

    setSelectedFloor(floorUuid) {
      this.selectedFloor = floorUuid
    }
  },

  getters: {
    isFloorsLoading() {
      return this.FLOORS_STATE === FLOORS_STATE.LOADING
    },
  }
})

export { useSectionCubeStore }