import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { VertexPin } from '../../distanceMeasurement/hoveringPins/vertexPin'
import { EdgePin } from '../../distanceMeasurement/hoveringPins/edgePin'

const MOUSE_CANVAS_CLICK_TOLERANCE = 5
const DISTANCE_COEFF = 0.05

/*eslint-disable no-dupe-class-members*/
export class VertexToEdgeRegime {
  static #pickingPoint = true

  static #firstPoint = null
  static #originPickedVertexPin = null

  static #edgePin = null
  static #vertexPin = null

  static #mouseHoverListenerId = null
  static #mouseUpListenerId = null
  static #mouseDownListenerId = null
  static #keyUpListenerId = null

  static #mouseDownCanvasX
  static #mouseDownCanvasY
  static #mouseDownLeft = false
  static #mouseDownRight = false

  /**
   * Включить режим измерения между точкой и ребром.
   */
  static activate() {
    this.#activateInputListeners()
    this.#activateHoverListener()
  }

  /**
   * Отключить режим измерения между точкой и ребром.
   */
  static deactivate() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    const input = XeokitMediator.viewer.scene.input

    cameraControl.off(this.#mouseHoverListenerId)
    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)
    input.off(this.#keyUpListenerId)

    this.#pickingPoint = true

    this.#firstPoint = null

    this.#vertexPin?.destroy()
    this.#vertexPin = null

    this.#edgePin?.destroy()
    this.#edgePin = null

    this.#originPickedVertexPin?.destroy()
    this.#originPickedVertexPin = null
  }

  static #pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedWorldPos = pickResult._worldPos

    if (pickResult.isSectionControl) return
    if (pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')) return

    let edges = []
    pickedEntity.meshes.forEach((mesh) => {
      if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
    })

    if (this.#pickingPoint) {
      this.#firstPoint = geometry.nearestCoordFinder.findNearestCornerByEdges(pickedWorldPos, edges)

      const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, this.#firstPoint) * DISTANCE_COEFF
      this.#originPickedVertexPin = new VertexPin( XeokitMediator.viewer.scene )
      this.#originPickedVertexPin.update({
        position: this.#firstPoint,
        scale: [scale, scale, scale]
      })

      this.#pickingPoint = false
    } 
    else {
      const nearestEdgeData = geometry.nearestCoordFinder.findNearestPointOnEdgeToPoint(edges, pickedWorldPos)
      if (!nearestEdgeData) return

      const perpendicularPointToSegment = geometry.pointToEdgeMeasurement.findPerpendicularPointToSegment(
        this.#firstPoint,
        nearestEdgeData.edge
      )
      
      XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
        originWorldPos: this.#firstPoint,
        targetWorldPos: perpendicularPointToSegment,
        billboard: geometry.utils.arePointsEqual(this.#firstPoint, perpendicularPointToSegment, 0.00001) ? 'spherical' : 'none'
      })
    }
  }

  static #activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)

    this.#mouseDownListenerId = input.on('mousedown', (coords) => {
      this.#mouseDownCanvasX = coords[0]
      this.#mouseDownCanvasY = coords[1]
      this.#mouseDownLeft = input.mouseDownLeft
      this.#mouseDownRight = input.mouseDownRight
    })

    this.#mouseUpListenerId = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.#mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.#mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.#mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.#mouseDownLeft = false 
        this.#mouseDownRight = false
        return
      }

      if (this.#mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
        })

        if (pickResult) this.#pick(pickResult)
      }
      
      this.#mouseDownLeft = false 
      this.#mouseDownRight = false
    })

    this.#keyUpListenerId = input.on("keyup", (code) => {
      if (code != 78) return

      this.deactivate()
      this.activate()
    })
  }

  static #activateHoverListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    this.#vertexPin = new VertexPin( XeokitMediator.viewer.scene, { visible: false } )
    this.#edgePin = new EdgePin( XeokitMediator.viewer.scene, { visible: false } )

    let nearestCorner = null

    this.#mouseHoverListenerId = cameraControl.on('hover', (event) => {
      if (this.#mouseDownLeft || this.#mouseDownRight) {
        if (this.#pickingPoint) this.#pickingPoint.setVisible(false)
        else this.#edgePin.setVisible(false)
        return
      }

      let pickResult = null
      pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
      })

      if (!pickResult) return
      if (pickResult.isSectionControl) return
      if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) return 

      let edges = []
      pickResult.entity.meshes.forEach((mesh) => {
        if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
      })

      if (this.#pickingPoint) {
        nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickResult.worldPos, edges)
        
        const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, nearestCorner) * DISTANCE_COEFF
        this.#vertexPin.update({
          position: nearestCorner,
          scale: [scale, scale, scale]
        })
        this.#vertexPin.setVisible(true)
      } 
      else {
        this.#vertexPin.setVisible(false)
        const nearestEdgeData = geometry.nearestCoordFinder.findNearestPointOnEdgeToPoint(edges, pickResult.worldPos)
        const transform = this.#edgePin.createTransformByTwoPoints(nearestEdgeData.edge[0], nearestEdgeData.edge[1])
        this.#edgePin.update(transform)
        this.#edgePin.setVisible(true)
      }
    })
  }
}
