import { FixedAxis, CustomAxis, Axis, ProjectAxisForm } from '@models/axis'
import { assert } from '@/utils'
import { api } from '@/api'
import tree from './axis-tree.module'
import SelectedModule from '../selected.module'
import i18n from '@/plugins/i18n'
import { simpleMapper } from '@/utils'
import {XeokitMediator} from "@/plugins/xeokit/XeokitMediator";

let Tab = Object.freeze({ IFC: 1, LAYERS: 2, CLASSES: 3, FLOORS: 4, GROUPS: 5 })

// ! Нет динамики переключения языка
// const defaultTabs = [ 
//   FixedAxis(Tab.IFC, i18n.t('section.elementTree.tabs.IFC')), 
//   FixedAxis(Tab.LAYERS, i18n.t('section.elementTree.tabs.layers')), 
//   FixedAxis(Tab.CLASSES, i18n.t('section.elementTree.tabs.classes')), 
//   FixedAxis(Tab.FLOORS, i18n.t('section.elementTree.tabs.floors')) 
// ]

const emptyAxis = new Axis()

const getDefaultState = () => {
  return {
    axisDataList: [],
    selected: null,
    floors:[],
    layers:[],

    selectedAxisGroup: null,
    presetAxisGroup: null,
  }
}
const state = getDefaultState()

export default {
  namespaced: true,

  modules: {
    tree,
    SelectedModule
  },

  state,

  getters: {
    fixedAxisGroup: () => {
      return [ 
        FixedAxis(Tab.IFC, i18n.t('section.elementTree.tabs.IFC')), 
        FixedAxis(Tab.LAYERS, i18n.t('section.elementTree.tabs.layers')), 
        FixedAxis(Tab.CLASSES, i18n.t('section.elementTree.tabs.classes')), 
        FixedAxis(Tab.FLOORS, i18n.t('section.elementTree.tabs.floors')),
        FixedAxis(Tab.GROUPS, i18n.t('section.elementTree.tabs.groups')) 
      ]
    },
    customAxisGroup: ({ axisDataList }) => axisDataList.map(i => CustomAxis(i)),
    selectedAxis: ({ selected }, getters ) => selected || getters.fixedAxisGroup[0],

    editedProjectAxis: ({ projectAxis }) => {
      let saveObj = new ProjectAxisForm(null)
      if(projectAxis) simpleMapper(saveObj, projectAxis)
      return saveObj
    },

  },
  
  mutations: {
    resetState (state) { Object.assign(state, getDefaultState()) },
    SET_AXIS_DATA_LIST: (state, list) => { state.axisDataList = list },
    SET_SELECTED_AXIS: (state, axis) => { state.selected = axis },
    SET_FLOORS: (state, floors) => { state.floors = floors },
    SET_LAYERS: (state, layers) => { state.layers = layers },
    setSelectedAxisGroup: (state, group) => { state.selectedAxisGroup = group },
    setPresetAxisGroup: (state, group) => { state.presetAxisGroup = group },
  },

  actions: {
    init ({ dispatch, getters, commit, state }, type) {
      dispatch('project/loadConditionEnum', null, { root: true })
      dispatch('loadAxisDataList')

      if (state.presetAxisGroup) {
        commit('SET_SELECTED_AXIS', getters.fixedAxisGroup[4])  
        dispatch('selectAxisGroup', state.presetAxisGroup)
        commit('setPresetAxisGroup', null)
      }
      else {
        commit('SET_SELECTED_AXIS', getters.fixedAxisGroup[0])
      }

      dispatch('tree/init', type)
    },

    initShort ({ dispatch, getters }) {
      dispatch('project/loadConditionEnum', null, { root: true })
      dispatch('loadAxisDataList')
      dispatch('selectAxis', getters.fixedAxisGroup[0])
    },
    
    loadAxisDataList ({ commit, rootGetters, rootState, dispatch }, newAxisUuid) {
      let projectUuid = rootGetters['project/projectUuid']
      let hashProject = rootGetters['project/hashProject']
      api.axes.fetchCustomAxisGroup(projectUuid, hashProject)
      .then(data => {
        commit('SET_AXIS_DATA_LIST', data)
        if( rootState.project.activeGlobalTab == 'show' ) dispatch('colorize')
        // ! В этом действии не будет необходимости, если перенести управление Осями из модуля project в этот модуль
        let newAxisData = newAxisUuid && data.find(one => one.uuid == newAxisUuid)
        if (newAxisData) {
          // dispatch('selectAxis', emptyAxis)
          // dispatch('selectAxis', CustomAxis(newAxisData))
          emptyAxis
          let axis = CustomAxis(newAxisData)
          dispatch('selectAxisGroup', axis)
        } 
      })
    },

    selectAxis ({ commit, dispatch, getters }, axis) {
      assert.is(axis, Axis)
      if (getters.selectedAxis.isEqual(axis) == false) {
        commit('SET_SELECTED_AXIS', axis)
        // TODO: Сказали снимать выделения при переключение на любую другую вкладку
        // if (axis.value !== 5) {
          dispatch('tree/fetchTree')
          XeokitMediator.ElementsSelection.selectElements([]) // при переключения tab-вкладки сбрасывать выделенные элементы
        // }
      }
    },

    selectAxisGroup({ commit, dispatch }, group) {      
      commit('setSelectedAxisGroup', group)
      group && dispatch('tree/fetchTree', group)
    },

    setSelectedAxisGroupGroups ({ commit, getters }, axis) {
      // dispatch('selectAxis', getters.fixedAxisGroup[4] )

      if(!axis) return

      assert.is(axis, Axis)
      if (getters.selectedAxis.isEqual(axis) == false) {
        commit('SET_SELECTED_AXIS', axis)
      }
    },

    deleteAxis: async ({ commit, getters, dispatch, state: { axisDataList, selectedAxisGroup } }, { uuid }) => {
      
      const axis = axisDataList.find(a => a.colorize === true && a.uuid === uuid)
      if(axis) await dispatch('tree/colorizeAxis', { axis: CustomAxis(axis), clearColorize: true })

      api.axes.delete(uuid)
      .then(() => {
        const notEqualUuid = i => i.uuid !== uuid
        commit('SET_AXIS_DATA_LIST', axisDataList.filter(notEqualUuid))

        if (getters.selectedAxis.value == uuid) {
          dispatch('selectAxis', getters.fixedAxisGroup[0])
        }

        // Сбросить выбранную группу при удалении
        if (selectedAxisGroup?.value === uuid) {
          dispatch('selectAxisGroup', null)
        }
      })
    },


    shareAxis: ({ commit, state: { axisDataList } }, { uuid }) => {
      api.axes.share(uuid)
      .then(data => {
        const replaceByUuid = i => i.uuid === data.uuid ? data : i
        let list = axisDataList.map(replaceByUuid)
        commit('SET_AXIS_DATA_LIST', list)
      })
    },

    // ! Использовать этот метод для сохранения Осей (AxisGroup) вместо project/saveAxis
    // ! Доработать под нужды Романа для модуля ElementTree
    saveAxisData ({ state: { axisDataList }, commit,  rootGetters }, axisData) {
      axisData.project = rootGetters['project/projectUuid']
      return api.axes.createOrUpdate(axisData)
      .then(data => {
        const replaceByUuid = i => i.uuid === data.uuid ? data : i
        let list = axisData.uuid ? axisDataList.map(replaceByUuid) : [...axisDataList, data]

        commit('SET_AXIS_DATA_LIST', list)
        // dispatch('selectAxis', CustomAxis(data))
        return data
      })
    },

    deleteRevisionElements({rootGetters, dispatch}, revisionUuid) {
      let projectUuid = rootGetters['project/projectUuid']
      let elIndex
      let revElements
      let elementsUuids = []


      api.axes.getFloors(projectUuid).then( data => {
        let newFloors = data
        newFloors.forEach(floor => {
          revElements = floor.elements.filter(el => el.modelRevision == revisionUuid)
  
          if(revElements.length > 0) {
            revElements.forEach(el => {
              elIndex = floor.elements.findIndex(element => element == el)
              floor.elements.splice(elIndex, 1)
            })

            floor.elements.forEach(element => {
              elementsUuids.push({uuid:element.uuid})
            })

            floor.elements = elementsUuids
            elementsUuids = []

            return api.axes.postFloor(floor, projectUuid)
            .then(data => {
              dispatch("floorDataFilter", data)
            })
          }
        });
      })
    },

    loadFloors ({ commit }, projectUuid) {
      api.axes.getFloors(projectUuid)
      .then( data => {
        commit( "SET_FLOORS", data )
      })
    },

    loadLayers ({ commit }, projectUuid) {
      api.axes.getLayers(projectUuid)
      .then(data => {
        commit("SET_LAYERS", data)
      })
    },

    floorDataFilter ({ commit, state }, floor) {
      let floorIndex = state.floors.findIndex( flr => flr.uuid == floor.uuid )
      let stateFloors = state.floors
      if (floorIndex != -1) {
        stateFloors.splice(floorIndex, 1, floor)
        commit("SET_FLOORS", stateFloors)
      }
      else {
        stateFloors.push(floor)
        commit("SET_FLOORS", stateFloors)
      }
    },

    deleteVuexFloor ({ commit,state }, floorId) {
      let stateFloors = state.floors
      let floorIndex = state.floors.findIndex(floor => floor.uuid == floorId)
      stateFloors.splice(floorIndex, 1)
      commit("SET_FLOORS", stateFloors)
    },


    saveFloor({ dispatch }, { savedFloor, projectUuid }) {
      return api.axes.postFloor(savedFloor, projectUuid)
      .then(data => {
        return dispatch("floorDataFilter", data)
      })
    },

    removeFloor({ dispatch },{ elUuid, projectUuid }) {
      api.axes.deleteFloor(elUuid, projectUuid)
      .then(data => {
        dispatch("deleteVuexFloor", data)
      })
    },


    colorize ({ state, dispatch }) {
      dispatch('SelectedModule/dropColorized')
      state.axisDataList.filter(a => a.colorize == true).forEach( a => {
        dispatch("tree/colorizeAxis", { axis: CustomAxis(a), clearColorize: false})
      })
    },

    colorizeItemsByGroup ({ state, dispatch }, axle) {
      dispatch('SelectedModule/dropColorized')
      state.axisDataList.filter(a => a.colorize == true && a.uuid == axle.item.value).forEach( a => {
        dispatch("tree/colorizeAxis", { axis: CustomAxis(a), clearColorize: false })
      })
    },

  }
}