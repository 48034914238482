import { geometry } from './geometry'
import { math } from '@xeokit/xeokit-sdk'

export const intersection = {
  getRayPlaneIntersectionPoint(rayO, rayV, planeN, planeO) {
    let tempVec3a = math.vec3()
    let intersPoint = math.vec3()

    let d = -math.dotVec3(planeN, planeO)
    let denom = math.dotVec3(planeN, rayV)
    let t = -(math.dotVec3(planeN, rayO) + d) / denom

    math.mulVec3Scalar(rayV, t, tempVec3a)
    math.addVec3(rayO, tempVec3a, intersPoint)

    return intersPoint
  },

  getLinePlaneIntersectionPoint(p1, p2, planeN, planeO) {
    let tempVec3a = math.vec3()
    let tempVec3b = math.vec3()
    let intersPoint = math.vec3()

    let d = -math.dotVec3(planeN, planeO)
    let u =
      (planeN[0] * p1[0] + planeN[1] * p1[1] + planeN[2] * p1[2] + d) /
      (planeN[0] * (p1[0] - p2[0]) + planeN[1] * (p1[1] - p2[1]) + planeN[2] * (p1[2] - p2[2]))

    math.subVec3(p2, p1, tempVec3a)
    math.mulVec3Scalar(tempVec3a, u, tempVec3b)
    math.addVec3(p1, tempVec3b, intersPoint)

    return intersPoint
  },

  isPointOnPolygon(planeN, edges, rayO) {
    let tempVec3a = math.vec3()
    let voluntaryVec = math.vec3([1, 1, 1])
    math.cross3Vec3(planeN, voluntaryVec, tempVec3a)
    let lenCrossVec = math.lenVec3(tempVec3a)
    if (lenCrossVec >= -0.01 && lenCrossVec <= 0.01) {
      voluntaryVec = math.vec3([-0.2, -0.8, -0, 5])
    }
    let crossVec = math.vec3()
    math.cross3Vec3(voluntaryVec, planeN, crossVec)

    let intersCount = 0
    for (let i = 0; i < edges.length; i++) {
      if (this.isRayIntersectsSegment(rayO, crossVec, edges[i][0], edges[i][1])) {
        intersCount++
      }
    }

    if (intersCount & 1) {
      // Нечет
      return true
    } 
    else return false
  },

  isRayIntersectsSegment(rayO, rayV, p1, p2) {
    let tempVec3a = math.vec3()
    let tempVec3b = math.vec3()
    let tempVec3c = math.vec3()

    let rayP = []
    math.mulVec3Scalar(rayV, 10, tempVec3a)
    math.addVec3(rayO, tempVec3a, rayP)

    let x0 = rayO[0]
    let x1 = rayP[0]
    let x2 = p1[0]
    let x3 = p2[0]

    let y0 = rayO[1]
    let y1 = rayP[1]
    let y2 = p1[1]
    let y3 = p2[1]

    let z0 = rayO[2]
    let z1 = rayP[2]
    let z2 = p1[2]
    let z3 = p2[2]

    let d1343 = (x0 - x2) * (x3 - x2) + (y0 - y2) * (y3 - y2) + (z0 - z2) * (z3 - z2)
    let d4321 = (x3 - x2) * (x1 - x0) + (y3 - y2) * (y1 - y0) + (z3 - z2) * (z1 - z0)
    let d1321 = (x0 - x2) * (x1 - x0) + (y0 - y2) * (y1 - y0) + (z0 - z2) * (z1 - z0)
    let d4343 = (x3 - x2) * (x3 - x2) + (y3 - y2) * (y3 - y2) + (z3 - z2) * (z3 - z2)
    let d2121 = (x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0) + (z1 - z0) * (z1 - z0)

    let muA = (d1343 * d4321 - d1321 * d4343) / (d2121 * d4343 - d4321 * d4321)
    let muB = (d1343 + muA * d4321) / d4343

    let pointOne = math.vec3()
    let pointTwo = math.vec3()
    math.subVec3(rayP, rayO, tempVec3a)
    math.mulVec3Scalar(tempVec3a, muA, tempVec3b)
    math.addVec3(rayO, tempVec3b, pointOne)

    math.subVec3(p2, p1, tempVec3a)
    math.mulVec3Scalar(tempVec3a, muB, tempVec3b)
    math.addVec3(p1, tempVec3b, pointTwo)

    math.subVec3(pointTwo, pointOne, tempVec3a)
    let pointsDist = Math.abs(math.lenVec3(tempVec3a))
    if (pointsDist >= -0.0001 && pointsDist <= 0.0001) {
      // Доделать поиск по расстояниям
      math.subVec3(p2, p1, tempVec3a)
      math.subVec3(pointOne, p1, tempVec3b)
      math.subVec3(p2, pointOne, tempVec3c)
      let abLength = Math.abs(math.lenVec3(tempVec3a))
      let acLength = Math.abs(math.lenVec3(tempVec3b))
      let bcLength = Math.abs(math.lenVec3(tempVec3c))
      if (geometry.utils.isCloseEqual(acLength + bcLength, abLength)) {
        // Если сумма расстояний между точками ребра и найденной точкой равна длине ребра, то точка лежит внутри него
        math.subVec3(pointOne, rayO, tempVec3a)
        math.normalizeVec3(tempVec3a)
        if (math.dotVec3(tempVec3a, rayV) >= 0) {
          // Дополнительно проверяем, лежит ли найденная точка на луче, а не позади него
          return true
        } 
        else return false
      } 
      else return false
    } 
    else return false
  },

  isAABBsIntersects(aabb1, aabb2) {
    let [xmin1, ymin1, zmin1, xmax1, ymax1, zmax1] = aabb1
    let [xmin2, ymin2, zmin2, xmax2, ymax2, zmax2] = aabb2

    let xOverlap = Math.max(0, Math.min(xmax1, xmax2) - Math.max(xmin1, xmin2))
    let yOverlap = Math.max(0, Math.min(ymax1, ymax2) - Math.max(ymin1, ymin2))
    let zOverlap = Math.max(0, Math.min(zmax1, zmax2) - Math.max(zmin1, zmin2))

    return xOverlap > 0 && yOverlap > 0 && zOverlap > 0
  },

  /** Получить грани элементов входящие или пересекающие AABB
   *
   * @param {Object} cfg Настройки поиска
   * @param {Array<Number>} [cfg.aabb] AABB внутри которого ведется поиск
   * @param {PerformanceMesh} [cfg.meshes] Массив PerformanceMesh на которых ведется поиск граней
   * @param {Boolean} [cfg.collisionDetect = false] Искать грани на коллизиях элементов
   *
   * @returns {Array<Array<Array<Number>>} Массив граней в формате [[[x, y, z], [x, y, z]]]
   */
  getEdgesOfMeshesInAABB(cfg) {
    let aabb = cfg.aabb
    let meshes = cfg.meshes
    let collisionDetect = cfg.collisionDetect || false

    let edgesOfMeshes = []
    if (meshes.length > 0) {
      edgesOfMeshes = geometry.extraction.getMeshesEdgeInAABB(meshes, aabb)
      if (!edgesOfMeshes) return []

      if (collisionDetect) {
        let intersect = this.getIntersectMeshesEdgeInAABB(meshes, aabb)
        edgesOfMeshes = [...edgesOfMeshes, ...intersect]
      }

      edgesOfMeshes = geometry.utils.removeDuplicateSegments(edgesOfMeshes, 0.001)
    }
    return edgesOfMeshes
  },

  checkIntersectionSegmentAABB(segment, aabb) {
    const [xmin, ymin, zmin, xmax, ymax, zmax] = aabb
    const [start, end] = segment

    const t1 = (xmin - start[0]) / (end[0] - start[0])
    const t2 = (xmax - start[0]) / (end[0] - start[0])
    const t3 = (ymin - start[1]) / (end[1] - start[1])
    const t4 = (ymax - start[1]) / (end[1] - start[1])
    const t5 = (zmin - start[2]) / (end[2] - start[2])
    const t6 = (zmax - start[2]) / (end[2] - start[2])

    const tMin = Math.max(Math.min(t1, t2), Math.min(t3, t4), Math.min(t5, t6))

    const tMax = Math.min(Math.max(t1, t2), Math.max(t3, t4), Math.max(t5, t6))

    return !(tMax < 0 || tMin > 1)
  },

  intersectionTriangleAndRectangleOnCanvas(t1, t2, t3, rectangleMin, rectangleMax) {
    // Функция для проверки, что точка находится внутри треугольника
    function isRectangleInTriangle(t1, t2, t3, rectangleMin, rectangleMax) {
      const [xMin, yMin] = rectangleMin
      const [xMax, yMax] = rectangleMax

      // Проверяем, лежит ли хотя бы один угол прямоугольника внутри треугольника
      if (
        isPointInTriangle(t1, t2, t3, [xMin, yMin]) ||
        isPointInTriangle(t1, t2, t3, [xMin, yMax]) ||
        isPointInTriangle(t1, t2, t3, [xMax, yMin]) ||
        isPointInTriangle(t1, t2, t3, [xMax, yMax])
      ) {
        return true
      }

      // Проверяем, пересекаются ли стороны прямоугольника с треугольником
      if (
        isLineIntersectingTriangle(t1, t2, [xMin, yMin], [xMax, yMin]) ||
        isLineIntersectingTriangle(t1, t2, [xMax, yMin], [xMax, yMax]) ||
        isLineIntersectingTriangle(t1, t2, [xMax, yMax], [xMin, yMax]) ||
        isLineIntersectingTriangle(t1, t2, [xMin, yMax], [xMin, yMin]) ||
        isLineIntersectingTriangle(t2, t3, [xMin, yMin], [xMax, yMin]) ||
        isLineIntersectingTriangle(t2, t3, [xMax, yMin], [xMax, yMax]) ||
        isLineIntersectingTriangle(t2, t3, [xMax, yMax], [xMin, yMax]) ||
        isLineIntersectingTriangle(t2, t3, [xMin, yMax], [xMin, yMin]) ||
        isLineIntersectingTriangle(t3, t1, [xMin, yMin], [xMax, yMin]) ||
        isLineIntersectingTriangle(t3, t1, [xMax, yMin], [xMax, yMax]) ||
        isLineIntersectingTriangle(t3, t1, [xMax, yMax], [xMin, yMax]) ||
        isLineIntersectingTriangle(t3, t1, [xMin, yMax], [xMin, yMin])
      ) {
        return true
      }

      return false
    }

    function isPointInTriangle(t1, t2, t3, point) {
      const [x1, y1] = t1
      const [x2, y2] = t2
      const [x3, y3] = t3
      const [x, y] = point

      const alpha = ((y2 - y3) * (x - x3) + (x3 - x2) * (y - y3)) / ((y2 - y3) * (x1 - x3) + (x3 - x2) * (y1 - y3))
      const beta = ((y3 - y1) * (x - x3) + (x1 - x3) * (y - y3)) / ((y2 - y3) * (x1 - x3) + (x3 - x2) * (y1 - y3))
      const gamma = 1 - alpha - beta

      // Проверяем, находится ли точка внутри треугольника
      return alpha >= 0 && beta >= 0 && gamma >= 0
    }

    function isLineIntersectingTriangle(p1, p2, t1, t2) {
      const [x1, y1] = p1
      const [x2, y2] = p2
      const [x3, y3] = t1
      const [x4, y4] = t2

      // Находим уравнения прямых, содержащих отрезок и стороны треугольника
      const a1 = y2 - y1
      const b1 = x1 - x2
      const c1 = a1 * x1 + b1 * y1

      const a2 = y4 - y3
      const b2 = x3 - x4
      const c2 = a2 * x3 + b2 * y3

      const d = a1 * b2 - a2 * b1

      if (d === 0) {
        // Отрезок и прямая параллельны, не пересекаются
        return false
      } 
      else {
        const x = (b2 * c1 - b1 * c2) / d
        const y = (a1 * c2 - a2 * c1) / d

        // Проверяем, находится ли точка пересечения на отрезке и внутри треугольника
        if (
          x >= Math.min(x1, x2) &&
          x <= Math.max(x1, x2) &&
          y >= Math.min(y1, y2) &&
          y <= Math.max(y1, y2) &&
          isPointInTriangle(t1, t2, p1, [x, y])
        ) {
          return true
        } 
        else {
          return false
        }
      }
    }

    function isTriangleInRectangle(t1, t2, t3, rectangleMin, rectangleMax) {
      // Проверяем, что все углы треугольника лежат внутри прямоугольника
      if (
        t1[0] >= rectangleMin[0] &&
        t1[0] <= rectangleMax[0] &&
        t1[1] >= rectangleMin[1] &&
        t1[1] <= rectangleMax[1] &&
        t2[0] >= rectangleMin[0] &&
        t2[0] <= rectangleMax[0] &&
        t2[1] >= rectangleMin[1] &&
        t2[1] <= rectangleMax[1] &&
        t3[0] >= rectangleMin[0] &&
        t3[0] <= rectangleMax[0] &&
        t3[1] >= rectangleMin[1] &&
        t3[1] <= rectangleMax[1]
      ) {
        return true
      } 
      else {
        return false
      }
    }

    // Функция проверки на пересечение
    function isTriangleIntersectRectangle(t1, t2, t3, rectangleMin, rectangleMax) {
      let sides = [
        [t1, t2],
        [t2, t3],
        [t3, t1],
      ]
      let rect = [rectangleMin, [rectangleMin[0], rectangleMax[1]], rectangleMax, [rectangleMax[0], rectangleMin[1]]]
      for (let i = 0; i < sides.length; i++) {
        let side = sides[i]
        for (let j = 0; j < rect.length; j++) {
          let intersection = getLineIntersection(side[0], side[1], rect[j], rect[(j + 1) % 4])
          if (intersection) return true
        }
      }
    }

    // Функция для нахождения точки пересечения линий
    function getLineIntersection(l1p1, l1p2, l2p1, l2p2) {
      let ua, ub
      let denominator = (l2p2[1] - l2p1[1]) * (l1p2[0] - l1p1[0]) - (l2p2[0] - l2p1[0]) * (l1p2[1] - l1p1[1])
      if (denominator == 0) return null

      ua = ((l2p2[0] - l2p1[0]) * (l1p1[1] - l2p1[1]) - (l2p2[1] - l2p1[1]) * (l1p1[0] - l2p1[0])) / denominator
      ub = ((l1p2[0] - l1p1[0]) * (l1p1[1] - l2p1[1]) - (l1p2[1] - l1p1[1]) * (l1p1[0] - l2p1[0])) / denominator

      if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
        return [l1p1[0] + ua * (l1p2[0] - l1p1[0]), l1p1[1] + ua * (l1p2[1] - l1p1[1])]
      } 
      else {
        return null
      }
    }

    // Проверка, что прямоугольник находится внутри треугольника
    let rectInsideTriangle = isRectangleInTriangle(t1, t2, t3, rectangleMin, rectangleMax)
    if (rectInsideTriangle) return true

    // Проверка, что треугольник находится внутри прямоугольника
    let triangleInsideRect = isTriangleInRectangle(t1, t2, t3, rectangleMin, rectangleMax)
    if (triangleInsideRect) return true

    // Проверка на пересечение
    let intersectTriangleAndRectangle = isTriangleIntersectRectangle(t1, t2, t3, rectangleMin, rectangleMax)
    if (intersectTriangleAndRectangle) return true

    return false
  },

  threePlanesIntersectionPoint(p1, p2, p3) {
    let n1 = [...p1.normal],
      n2 = [...p2.normal],
      n3 = [...p3.normal]
    let x1 = math.vec3()
    let x2 = math.vec3()
    let x3 = math.vec3()
    math.mulVec3Scalar(n1, p1.offset, x1)
    math.mulVec3Scalar(n2, p2.offset, x2)
    math.mulVec3Scalar(n3, p3.offset, x3)
    let crossProduct1 = math.vec3()
    let crossProduct2 = math.vec3()
    let crossProduct3 = math.vec3()
    math.cross3Vec3(n2, n3, crossProduct1)
    math.cross3Vec3(n3, n1, crossProduct2)
    math.cross3Vec3(n1, n2, crossProduct3)
    let f1 = math.vec3()
    let f2 = math.vec3()
    let f3 = math.vec3()
    math.mulVec3Scalar(crossProduct1, math.dotVec3(x1, n1), f1)
    math.mulVec3Scalar(crossProduct2, math.dotVec3(x2, n2), f2)
    math.mulVec3Scalar(crossProduct3, math.dotVec3(x3, n3), f3)
    let det = geometry.math.determinantMat3(math.mat3([n1[0], n1[1], n1[2], n2[0], n2[1], n2[2], n3[0], n3[1], n3[2]]))
    let vectorSum = math.addVec3(math.addVec3(f1, f2), f3)
    let planeIntersection = math.vec3([-vectorSum[0] / det, -vectorSum[1] / det, -vectorSum[2] / det])
    return planeIntersection
  },

  /** Пересечение плоскости и прямоугольного параллелепипеда
   * @returns {boolean}
   * @param plane - Объект, содержащий точку, нормаль и расстояние до центра системы отсчета
   * @param aabb - Массив из двух точек в формате WorldPos: [[x, y, z], [x, y, z]]
   */
  isPlaneIntersectAABB(plane, aabb) {
    // p - plane
    let c = math.getAABB3Center(aabb) // aabbCenter
    let e = [aabb[3] - c[0], aabb[4] - c[1], aabb[5] - c[2]] // aabbPositiveExtent
    let r = e[0] * Math.abs(plane.dir[0]) + e[1] * Math.abs(plane.dir[1]) + e[2] * Math.abs(plane.dir[2]) // projectionIntervalRadius
    let s =
      (plane.dir[0] * (c[0] - plane.pos[0]) + plane.dir[1] * (c[1] - plane.pos[1]) + plane.dir[2] * (c[2] - plane.pos[2])) / // distanceBetweenPlaneAndAabb
      Math.sqrt(plane.dir[0] * plane.dir[0] + plane.dir[1] * plane.dir[1] + plane.dir[2] * plane.dir[2])
    return Math.abs(s) <= r
  },

  /** Поиск точки пересечения плоскости и отрезка
   *
   * @param lineSegment - Две крайние точки отрезка
   * @param plane - Плоскость, содержащая точку, нормаль и расстояние до центра системы координат
   * @returns {{areIntersected: boolean, intersectionPoint}} - Пересекает ли отрезок плоскость; точка пересечения
   */
  findLineSegmentPlaneIntersectionPoint(lineSegment, plane) {
    let d = math.dotVec3(plane.dir, plane.pos)
    let segment = math.subVec3([lineSegment[0], lineSegment[1], lineSegment[2]], [lineSegment[3], lineSegment[4], lineSegment[5]])
    let segmentOrigin = [lineSegment[0], lineSegment[1], lineSegment[2]]
    let segmentEnd = [lineSegment[3], lineSegment[4], lineSegment[5]]
    let x = (d - math.dotVec3(plane.dir, segmentOrigin)) / math.dotVec3(plane.dir, segment)
    let intersectionPoint = math.addVec3(math.mulVec3Scalar(segment, x), segmentOrigin)
    return {
      areIntersected: math.dotVec3(math.subVec3(segmentOrigin, intersectionPoint), math.subVec3(segmentEnd, intersectionPoint)) <= 0,
      intersectionPoint: intersectionPoint,
    }
  },

  /** Пересечение прямой и плоскости
   *
   * @param line - Прямая, содержащая две точки
   * @param planeNormal - Нормаль плоскости
   * @returns {boolean}
   */
  isLineIntersectsPlane(line, planeNormal) {
    let ray = math.subVec3([line[0], line[1], line[2]], [line[3], line[4], line[5]])
    return math.dotVec3(planeNormal, ray) !== 0
  },

  /** Поиск вершин, находящихся с противоположной стороны плоскости (неотсеченных вершин)
   *
   * @param vertices - Массив вершин
   * @param plane - Плоскость, содержащий точку, нормаль и расстояние до центра системы координат
   * @returns {*[]} - Массив вершин, находящихся с противоположной стороны плоскости
   */
  findNonCutOffAabbVertices(vertices, plane) {
    let foundVertices = []
    for (let i = 0; i < vertices.length; i++) {
      let p = Object.assign({}, plane)
      p.dir = new Float64Array(plane.dir)
      p.pos = new Float64Array(plane.pos)
      let ray = {
        pos: vertices[i],
        dir: math.mulVec3Scalar(p.dir, -1),
      }
      if (this.isRayIntersectsPlane(ray, p)) {
        foundVertices.push(vertices[i])
      }
    }
    return foundVertices
  },

  /** Пересекает ли луч плоскость
   *
   * @param ray - Объект, содержащий точку в формате WorldPos и вектор нормали
   * @param plane - Объект, содержащий точку в формате WorldPos, вектор нормали и расстояние до центра системы отсчета
   * @returns {boolean}
   */
  isRayIntersectsPlane(ray, plane) {
    let d = math.dotVec3(plane.dir, ray.dir)
    if (Math.abs(d) > 0.0000001) {
      let t = math.dotVec3(math.subVec3(plane.pos, ray.pos), plane.dir) / d
      if (t >= 0) return true
    }
    return false
  },

  /** Массив координат треугольников WorldPos координат по Entity с персекаемыми Entity
   *
   * @param {Entity} entity
   *
   * @returns {Array} Массив ребер формата WorldPos [ [x, y, z], [x, y, z] ]
   */
  getIntersectMeshesEdgeInAABB(meshes, aabb) {
    let edges = []
    let entityTriangleList = []
    for (let mesh of meshes) {
      let trianglesCoords = geometry.extraction.getFacesVerticeByEntity(mesh)
      let triangles = []

      if (entityTriangleList.length > 0) {
        for (let i = 0; i < trianglesCoords.length; i += 3) {
          let t1 = trianglesCoords[i]
          let t2 = trianglesCoords[(i + 1) % trianglesCoords.length]
          let t3 = trianglesCoords[(i + 2) % trianglesCoords.length]

          if (this.isTriangleIntersectAABB([t1, t2, t3], aabb)) {
            triangles.push([t1, t2, t3])

            entityTriangleList.forEach((entityTriangles) => {
              for (let triangleIndex = 0; triangleIndex < entityTriangles.length; triangleIndex++) {
                let intersection = this.findIntersectionBetweenTriangles(
                  entityTriangles[triangleIndex][0],
                  entityTriangles[triangleIndex][1],
                  entityTriangles[triangleIndex][2],
                  t1,
                  t2,
                  t3
                )
                if (intersection) {
                  let findIndex = i + 3
                  while (findIndex < trianglesCoords.length) {
                    let tFind1 = trianglesCoords[findIndex]
                    let tFind2 = trianglesCoords[(findIndex + 1) % trianglesCoords.length]
                    let tFind3 = trianglesCoords[(findIndex + 2) % trianglesCoords.length]
                    let intersec = this.findIntersectionBetweenTriangles(
                      entityTriangles[triangleIndex][0],
                      entityTriangles[triangleIndex][1],
                      entityTriangles[triangleIndex][2],
                      tFind1,
                      tFind2,
                      tFind3
                    )

                    if (intersec) {
                      intersec.forEach((inter) => {
                        for (let interIndex = 0; interIndex < intersection.length; interIndex++) {
                          if (geometry.segments.isSegmentOnRay(inter, intersection[interIndex])) {
                            let merge = geometry.segments.mergeSegments(inter, intersection[interIndex])
                            if (merge) intersection[interIndex] = merge
                          }
                        }
                      })

                      findIndex += 3
                    } 
                    else {
                      findIndex = -1
                      break
                    }
                  }
                  findIndex = i - 3
                  while (findIndex >= 0) {
                    let tFind1 = trianglesCoords[findIndex]
                    let tFind2 = trianglesCoords[(findIndex + 1) % trianglesCoords.length]
                    let tFind3 = trianglesCoords[(findIndex + 2) % trianglesCoords.length]
                    let intersec = this.findIntersectionBetweenTriangles(
                      entityTriangles[triangleIndex][0],
                      entityTriangles[triangleIndex][1],
                      entityTriangles[triangleIndex][2],
                      tFind1,
                      tFind2,
                      tFind3
                    )

                    if (intersec) {
                      intersec.forEach((inter) => {
                        for (let interIndex = 0; interIndex < intersection.length; interIndex++) {
                          if (geometry.segments.isSegmentOnRay(inter, intersection[interIndex])) {
                            let merge = geometry.segments.mergeSegments(inter, intersection[interIndex])
                            if (merge) {
                              intersection[interIndex] = merge
                            }
                          }
                        }
                      })

                      findIndex -= 3
                    } 
                    else {
                      findIndex = -1
                      break
                    }
                  }

                  edges = [...edges, ...intersection]
                }
              }
            })
          }
        }
        if (triangles.length > 0) entityTriangleList.push(triangles)
      } 
      else {
        for (let i = 0; i < trianglesCoords.length; i += 3) {
          let t1 = trianglesCoords[i]
          let t2 = trianglesCoords[(i + 1) % trianglesCoords.length]
          let t3 = trianglesCoords[(i + 2) % trianglesCoords.length]

          if (this.isTriangleIntersectAABB([t1, t2, t3], aabb)) {
            triangles.push([t1, t2, t3])
          }
        }
        if (triangles.length > 0) entityTriangleList.push(triangles)
      }
    }

    if (edges.length > 1) {
      let edgeLength = 0
      while (edges.length != edgeLength) {
        edgeLength = edges.length
        edges = geometry.segments.mergeSegmentsOnRay(edges)
      }
    }

    return edges
  },

  isTriangleIntersectAABB(triangle, aabb) {
    const triangleMin = geometry.utils.getTriangleMin(triangle)
    const triangleMax = geometry.utils.getTriangleMax(triangle)

    for (let i = 0; i < 3; i++) {
      if (triangleMax[i] < aabb[i] || triangleMin[i] > aabb[i + 3]) {
        return false
      }
    }

    return true
  },

  findIntersectionBetweenTriangles(t1a, t1b, t1c, t2a, t2b, t2c) {
    const triangle1 = [t1a, t1b, t1c]
    const triangle2 = [t2a, t2b, t2c]
    if (geometry.utils.areTrianglesOnSamePlane(triangle1, triangle2)) {
      return null
    }

    if (this.checkTriangleTriangleIntersection(triangle1, triangle2) && this.checkTriangleTriangleIntersection(triangle2, triangle1)) {
      let intersections = []
      const intersection = this.findTriangleIntersection(triangle1, triangle2)
      const intersection2 = this.findTriangleIntersection(triangle2, triangle1)

      if (intersection) intersections.push(intersection)
      if (intersection2) intersections.push(intersection2)
      // const intersection2 = this.computeTriangleIntersection(triangle2, triangle1);
      // if (intersection2) intersections.push(intersection2)

      return intersections
    } 
    else {
      return null
    }
  },

  checkTriangleTriangleIntersection(triangle1, triangle2) {
    for (let i = 0; i < 3; i++) {
      const edge1Start = triangle1[i]
      const edge1End = triangle1[(i + 1) % 3]

      for (let j = 0; j < 3; j++) {
        const edge2Start = triangle2[j]
        const edge2End = triangle2[(j + 1) % 3]

        if (this.doSegmentsIntersect(edge1Start, edge1End, edge2Start, edge2End)) {
          return true
        }
      }
    }

    return false
  },

  doSegmentsIntersect(p1, q1, p2, q2) {
    const orientation1 = geometry.utils.getOrientation(p1, q1, p2)
    const orientation2 = geometry.utils.getOrientation(p1, q1, q2)
    const orientation3 = geometry.utils.getOrientation(p2, q2, p1)
    const orientation4 = geometry.utils.getOrientation(p2, q2, q1)

    if (orientation1 !== orientation2 && orientation3 !== orientation4) {
      return true
    }

    return (
      (orientation1 === 0 && geometry.segments.isPointOnSegment(p1, q1, p2)) ||
      (orientation2 === 0 && geometry.segments.isPointOnSegment(p1, q1, q2)) ||
      (orientation3 === 0 && geometry.segments.isPointOnSegment(p2, q2, p1)) ||
      (orientation4 === 0 && geometry.segments.isPointOnSegment(p2, q2, q1))
    )
  },

  findTriangleIntersection(triangle1, triangle2) {
    const intersectionPoints = []

    // Проверяем пересечение каждой стороны треугольника с другим треугольником
    for (let i = 0; i < 3; i++) {
      const edgeStart1 = triangle1[i]
      const edgeEnd1 = triangle1[(i + 1) % 3]

      // Проверяем пересечение стороны треугольника с другим треугольником
      const intersectionPoint = this.findIntersectionPoint(edgeStart1, edgeEnd1, triangle2)

      if (intersectionPoint) {
        intersectionPoints.push(intersectionPoint)
      }
    }

    // Если найдены точки пересечения, возвращаем их
    if (intersectionPoints.length === 2) {
      return intersectionPoints
    }

    // Если точки пересечения не найдены, возвращаем null
    return null
  },

  // Вспомогательная функция для проверки пересечения двух отрезков в 2D
  checkSegmentIntersection2D(a, b, c, d) {
    const denominator = (b[1] - a[1]) * (d[0] - c[0]) - (b[0] - a[0]) * (d[1] - c[1])

    if (denominator === 0) {
      return false
    }

    const numerator1 = (a[0] - c[0]) * (d[1] - c[1]) - (a[1] - c[1]) * (d[0] - c[0])
    const numerator2 = (a[0] - c[0]) * (b[1] - a[1]) - (a[1] - c[1]) * (b[0] - a[0])

    const t1 = numerator1 / denominator
    const t2 = numerator2 / denominator

    if (t1 >= 0 && t1 <= 1 && t2 >= 0 && t2 <= 1) {
      const intersectionX = a[0] + t1 * (b[0] - a[0])
      const intersectionY = a[1] + t1 * (b[1] - a[1])
      const intersectionZ = a[2] + t1 * (b[2] - a[2])

      return [intersectionX, intersectionY, intersectionZ]
    }

    return false
  },

  // Вспомогательная функция для проверки пересечения стороны треугольника с другим треугольником
  findIntersectionPoint(edgeStart, edgeEnd, triangle) {
    const [a, b, c] = triangle

    // Проверяем пересечение стороны треугольника с каждой стороной другого треугольника
    const intersection1 = this.checkSegmentIntersection2D(edgeStart, edgeEnd, a, b)
    const intersection2 = this.checkSegmentIntersection2D(edgeStart, edgeEnd, b, c)
    const intersection3 = this.checkSegmentIntersection2D(edgeStart, edgeEnd, c, a)

    // Возвращаем точку пересечения, если она найдена
    if (intersection1) {
      return intersection1
    }
    if (intersection2) {
      return intersection2
    }
    if (intersection3) {
      return intersection3
    }

    return null
  },
}
